import {SET_AVALAIBILITIES_LIST} from '../actions/types';

import {Availability} from '../../graphql/API';

export type availabilityState = {
  avalaibilitiesList: Availability[];
};

export const initialState: availabilityState = {
  avalaibilitiesList: [],
};

const availability = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_AVALAIBILITIES_LIST:
      return {
        ...state,
        avalaibilitiesList: action.payload,
      };

    default:
      return state;
  }
};

export default availability;
