import React, {useEffect} from 'react';
import 'antd/dist/antd.css';

//@ts-ignore
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import awsconfig from './amplifyconfiguration.json';
import {PersistGate} from 'redux-persist/es/integration/react';

import './App.css';
import {Layout} from 'antd';
import {persistor, store} from './redux/store';

import {Provider} from 'react-redux';
import {Routers} from './components/Routers';
const {Content} = Layout;

Amplify.configure(awsconfig);

function App() {
  useEffect(() => {}, []);

  return (
    <div style={{height: '100%'}}>
      <Router>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Layout>
              <Content>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home" />}
                  />
                  <Route
                    render={() => {
                      return <Routers />;
                    }}
                  />
                </Switch>
              </Content>
            </Layout>
          </PersistGate>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
