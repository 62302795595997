import {
  Availability,
  AvailabilityInsert,
  Slot,
  Status,
} from '../../graphql/API';
import {reducersState} from '../reducers';
import {AvailabilityRepository} from '../repositories/AvailabilityRepository';
import {isLoadingRequest} from './user';
import {SET_AVALAIBILITIES_LIST} from './types';
import moment from 'moment-timezone';

const availabilityRepository = new AvailabilityRepository();

export const createAvailabilities =
  (
    avalaibilitiesList: Availability[],
    instructorId: string,
    instructorTimeZone: string,
  ) =>
  async (dispatch: any, getState: any) => {
    console.log('ENTER_GET_USER');
    dispatch(isLoadingRequest(true));
    try {
      await Promise.all(
        avalaibilitiesList.map(async (instrAvaliab: Availability) => {
          const isInstructorAvaliabiltyCreated: boolean = instrAvaliab.createdAt
            ? true
            : false;

          if (!isInstructorAvaliabiltyCreated) {
            const instruAvalitilty: AvailabilityInsert = {
              instructorId: instructorId,
              startTime: instrAvaliab?.startTime,
              endTime: instrAvaliab.endTime,
              day: instrAvaliab.day,
            };

            return availabilityRepository.createAvailability(instruAvalitilty);
          }
        }),
      );

      dispatch(isLoadingRequest(false));
    } catch (error) {
      dispatch(isLoadingRequest(false, error));
      console.log('ERROR_CREATE_AVALI', error);
      // dispatch(doSignOut());
    }
  };

export const deleteAvailability =
  (avaliabilityId: string) => async (dispatch: any) => {
    console.log('ENTER_GET_USER');
    dispatch(isLoadingRequest(true));

    try {
      await availabilityRepository.deleteAvailability(avaliabilityId);

      dispatch(isLoadingRequest(false));
    } catch (error) {
      dispatch(isLoadingRequest(false, error));
      console.log('ERROR_CREATE_AVALI', error);
      // dispatch(doSignOut());
    }
  };

export const changeSlotStatus =
  (slotId: string, status: Status, updatedAt: string) =>
  async (dispatch: any) => {
    console.log('ENTER_GET_USER');
    dispatch(isLoadingRequest(true));

    try {
      await availabilityRepository.changeSlotStatus(slotId, status, updatedAt);
      window.location.reload();

      dispatch(isLoadingRequest(false));
    } catch (error) {
      dispatch(isLoadingRequest(false, error));
      console.log('ERROR_CREATE_AVALI', error);
      // dispatch(doSignOut());
    }
  };

export const setAvalaiabilitiesList = (availabilities: Array<Availability>) => {
  return {type: SET_AVALAIBILITIES_LIST, payload: availabilities};
};
