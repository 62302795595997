export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $id: ID!
    $updatedAt: String!
    $input: SessionUpdate!
  ) {
    updateSession(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
      }
    }
  }
`;

export const startSession = /* GraphQL */ `
  mutation StartSession($id: ID!) {
    startSession(id: $id) {
      id
      createdAt
      updatedAt
      name
      rtcUserToken
      rtcInstructorToken
      slot {
        id
        createdAt
        updatedAt
        name
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        promo {
          thumbnailUri
        }

        user {
          id
          timeZone
        }
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
      }
    }
  }
`;

export const endSession = /* GraphQL */ `
  mutation EndSession($id: ID!, $status: Status!) {
    endSession(id: $id, status: $status) {
      id
      createdAt
      updatedAt
      name
      group {
        id
        createdAt
        updatedAt
        name
        division
        instructor {
          id
          name
          promo {
            thumbnailUri
          }
          user {
            id
            timeZone
          }
        }
        slot {
          id
          day
          startTime
          endTime
        }
        isActive
        isGroupHasInstructor
        nextSession {
          id
          name
          createdAt
          updatedAt
          startDateTime
          group {
            id
            updatedAt
            isGroupHasInstructor
            instructor {
              id
            }
            isActive
          }
          instructor {
            id
            name
            promo {
              thumbnailUri
            }
            user {
              id
              timeZone
            }
          }
          slot {
            id
            day
            startTime
            endTime
          }
          duration
          jitsiUrl
          status
          startTime
          endTime
        }
        perviousSession {
          id
          createdAt
          updatedAt
          startDateTime
          status
          startTime
          endTime
          instructor {
            id
            name
            promo {
              thumbnailUri
            }
            user {
              id
              timeZone
            }
          }
          slot {
            id
            day
            startTime
            endTime
          }
        }
      }
      slot {
        id
        createdAt
        updatedAt
        name
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        user {
          id
          timeZone
        }
      }

      lesson {
        id
        createdAt
        updatedAt
        name
      }
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
      }
    }
  }
`;

export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($id: ID!, $updatedAt: String!, $input: GroupUpdate!) {
    updateGroup(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      startDate
      name
    }
  }
`;

export const rescheduleSession = /* GraphQL */ `
  mutation RescheduleSession($id: ID!, $startDateTime: String!) {
    rescheduleSession(id: $id, startDateTime: $startDateTime) {
      id
      createdAt
      updatedAt
      name
    }
  }
`;

export const levelUpGroup = /* GraphQL */ `
  mutation LevelUpGroup($groupId: String, $levelId: String) {
    levelUpGroup(groupId: $groupId, levelId: $levelId) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      isActive
    }
  }
`;
