import {useEffect, useState, useRef, createRef} from 'react';

import {useHistory, useLocation, useParams} from 'react-router-dom';

import {useDispatch} from 'react-redux';
import './PDF.css';
import {Document, Page} from 'react-pdf';

export const PDFView = () => {
  const history = useHistory();
  console.log('location.pathname', history.location.state);

  //@ts-ignore
  const [pdfUrl, setPdfUrl] = useState<any>();
  const iframeRef = useRef(null);

  useEffect(() => {
    //@ts-ignore
    if (history.location.state?.pdfUrl) {
      //@ts-ignore
      let url: string = history.location.state?.pdfUrl;
      const urlSplitter = url?.split('?x-amz-content');
      console.log('urlSplitter', urlSplitter);
      url = urlSplitter[0];
      url = url.replaceAll('%20', ' ');
      url = url.replaceAll('%26', '&');
      url = url.replaceAll('%3A', ':');
      console.log('dfdsffffff', url + '?x-amz-content' + urlSplitter[1]);
      setPdfUrl(url + '?x-amz-content' + urlSplitter[1]);
    }
    return () => {};
  }, []);
  return (
    <div style={{width: '100%', height: '100%'}}>
      {
        //@ts-ignore
        history.location.state?.videoUrl ? (
          <video
            //@ts-ignore
            src={history.location.state?.videoUrl}
            controls
            controlsList="nodownload"
            width={'100%'}
            style={{height: '87%'}}
          />
        ) : (
          pdfUrl && (
            <embed
              ref={iframeRef}
              src={pdfUrl}
              style={{width: '100%', height: '100%'}}
            />
          )
        )
      }
    </div>
  );
};

export default PDFView;
