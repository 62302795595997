import {
  FeedbackQuestionInsert,
  ReviewInsert,
  ReviewQuestion,
  ReviewType,
  Session,
} from '../../graphql/API';
import {ReviewRepository} from '../repositories/ReviewRepositoryy';
import {isLoadingRequest} from './user';
import {SET_PERVIOUS_SESSION, SET_REVIEW_QUESTIONS} from './types';

const reviewRepository = new ReviewRepository();

export const fetchReviewQuestions = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    try {
      let reviewQuestions: Array<ReviewQuestion> =
        await reviewRepository.getInstructorReviewQuestions();

      dispatch(setReviewQuestions(reviewQuestions));
      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      dispatch(isLoadingRequest(false, error));
    }
  };
};

export const rateStudent = (
  studentId: string,
  sessionId: string,
  comments: string = '',
  feedbackQuestions: FeedbackQuestionInsert[],
) => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    const reviewType: ReviewType = ReviewType.INSTRUCTOR_TO_STUDENT;
    const instructorId: string = getState().user.currentUser.instructor.id;

    const review: ReviewInsert = {
      studentId,
      instructorId,
      reviewType,
      comments,
      sessionId,
      feedbackquestions: feedbackQuestions,
    };
    console.log('review', review);
    try {
      await reviewRepository.createReview(review);

      dispatch(isLoadingRequest(false));
      //   dispatch(setIsPerviousSessionReviewed(true));
    } catch (error: any) {
      dispatch(isLoadingRequest(false, error));
    }
  };
};

export const fetchSession = (sessionId: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));

    console.log('review', sessionId);
    try {
      const session = await reviewRepository.getSessionById(sessionId);
      dispatch(setPerviousSession(session));
      dispatch(isLoadingRequest(false));
      //   dispatch(setIsPerviousSessionReviewed(true));
    } catch (error: any) {
      dispatch(isLoadingRequest(false, error));
    }
  };
};

export const setReviewQuestions = (reviewQ: Array<ReviewQuestion>) => {
  return {type: SET_REVIEW_QUESTIONS, payload: reviewQ};
};

export const setPerviousSession = (session: Session | null) => {
  return {type: SET_PERVIOUS_SESSION, payload: session};
};
