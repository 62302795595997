import {IIGroupService} from './IIGroupsService';
import {GroupsRepository} from './GroupsRepository';
import {
  Group,
  GroupInsert,
  GroupUpdate,
  Instructor,
  DivisionType,
  Status,
} from '../../graphql/API';
import {Session} from 'inspector';

export class GroupService implements IIGroupService {
  GroupRepository: GroupsRepository;
  constructor() {
    this.GroupRepository = new GroupsRepository();
  }
  async getLevels(): Promise<any> {
    return await this.GroupRepository.getLevels();
  }

  async levelUp(groupId: string, levelId: string): Promise<Group> {
    return await this.GroupRepository.levelUp(groupId, levelId);
  }

  async rescheduleSession(id: string, newStartTimeDate: string): Promise<any> {
    //@ts-ignore
    return await this.GroupRepository.rescheduleSession(id, newStartTimeDate);
  }

  async updateGroupHasInstructor(
    groupId: string,
    updatedAt: string,
    groupUpdate: GroupUpdate,
  ): Promise<any> {
    return await this.GroupRepository.updateGroupHasInstructor(
      groupId,
      updatedAt,
      groupUpdate,
    );
  }
  async startSession(groupId: string): Promise<any> {
    return await this.GroupRepository.startSession(groupId);
  }

  async endSession(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    return await this.GroupRepository.endSession(sessionId, updatedAt, status);
  }

  async getGroupById(id: string): Promise<Group> {
    return await this.GroupRepository.getGroupById(id);
  }

  async changeSessionStatus(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    return await this.GroupRepository.changeSessionStatus(
      sessionId,
      updatedAt,
      status,
    );
  }
}
