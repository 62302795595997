import React, {memo, useEffect} from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {Layout, Menu, Avatar, Badge} from 'antd';
import {useState} from 'react';
import 'antd/dist/antd.css';
import {
  NotificationOutlined,
  PicLeftOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import {Gender, SenderType, Userconversation} from '../graphql/API';
import Dot from '../assets/icons/dot.png';
import Text from 'antd/lib/typography/Text';
import {reducersState} from '../redux/reducers';
const {Content, Footer, Sider} = Layout;

type SiderChatProps = {
  onPressChat: (usrConversation: Userconversation) => void;
};

export const SiderChat = ({onPressChat}: SiderChatProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const userConversations = useSelector(
    (state: reducersState) => state.user.conversations,
  );
  console.log('userConversations', userConversations);
  return (
    <Sider
      theme={'dark'}
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}>
      <Menu>
        {userConversations?.map((usrConv: Userconversation, index) => (
          <MenuItemCustom
            key={usrConv.id}
            collapsed={collapsed}
            usrConv={usrConv}
            index={index}
            onClick={onPressChat}
          />
        ))}
      </Menu>
    </Sider>
  );
};

type MenuItem = {
  onClick: (usrConv: Userconversation) => void;
  usrConv: Userconversation;
  index: number;
  collapsed: boolean;
};

const MenuItemCustom = memo(
  ({onClick, usrConv, index, collapsed}: MenuItem) => {
    const conversations: Array<Userconversation> = useSelector(
      (state: reducersState) => state.user.conversations,
    );
    const [isMessageReplied, setIsMessageReplied] = useState<boolean>(true);
    useEffect(() => {
      try {
        console.log(
          'ENTERRRR_USEEFTTE',
          conversations[index]?.conversation?.messages[0],
        );
      } catch (e) {}
      if (conversations) {
        let isMessageRepl =
          conversations[index]?.conversation?.messages &&
          conversations[index]?.conversation?.messages[0]?.senderType ===
            SenderType.STUDENT
            ? false
            : !conversations[index]?.conversation?.messages
            ? false
            : true;

        setIsMessageReplied(isMessageRepl);
      }
    }, [conversations, index]);
    return (
      <Menu.Item
        key={usrConv.id}
        onClick={() => onClick(usrConv)}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          wordWrap: 'break-word',
          flexGrow: 1,
        }}>
        {!isMessageReplied ? (
          <>
            <Badge size="small" dot style={{right: 5}} />
            <UserOutlined
              style={{
                fontSize: 15,
              }}
            />
          </>
        ) : (
          <UserOutlined
            style={{
              fontSize: 15,
            }}
          />
        )}
        <Text
          style={{
            fontWeight: 'bold',
            color: collapsed ? '#fff' : '#000',
          }}>
          {usrConv?.conversation?.name}
        </Text>
      </Menu.Item>
    );
  },
);
