export const customMe = /* GraphQL */ `
  query Me {
    me {
      id
      createdAt
      updatedAt
      whatsappNumber
      cognitoUserId
      country
      state
      timeZone
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        availabilities {
          id
          createdAt
          updatedAt
          startTime
          endTime
          slots {
            id
            day
            updatedAt
            startTime
            endTime
            status
            group {
              id
              name
              isActive
              startDate
              isGroupHasInstructor
              sessions {
                id
                startDateTime
                status
              }
              level {
                id
                createdAt
                name
              }
            }
          }
          day
        }
      }
      userconversations {
        createdAt
        updatedAt
        id
        conversation {
          id
          name
          userconversations {
            id
            conversation {
              id
            }
            user {
              id
            }
          }
          studentconversations {
            id
            student {
              id
              name
              user {
                id
                endPointArn
              }
            }
          }
        }
      }
    }
  }
`;
