import {AuthRepository} from '../repositories/AuthRepository';
import {
  GET_USER,
  IS_LOADING_REQUEST,
  NO_USER_FOUND,
  SET_CONVERSATIONS,
  SET_SUBSCRIBED_ON_CONVERSATION,
  SIGN_IN_SUCCESS,
  USER_IS_LOADING,
} from './types';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {setAvalaiabilitiesList} from './availability';
import {
  Message,
  MessageInsert,
  SenderType,
  User,
  Userconversation,
} from '../../graphql/API';

import {subscribeToNewMessage} from '../../graphql/subscriptions';
import {createMessage, sendMessage} from '../../graphql/mutations';

import {messagesByConversationId} from './CustomQuery';
import {subscribeToNewUCs} from './CustomSubscription';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {fetchUserAttributes, SignInOutput, signOut} from 'aws-amplify/auth';
const client = generateClient();
export const isLoadingRequest = (isLoading: boolean, error: any = null) => {
  return {
    type: IS_LOADING_REQUEST,
    payload: {isLoading, error},
  };
};

const authRepo = new AuthRepository();

export const getUser = () => async (dispatch: any, getState: any) => {
  console.log('ENTER_GET_USER');

  try {
    let user: User = await authRepo.getCurrentUser();

    const conversations = getState()?.user?.conversations;

    if (user) {
      dispatch({type: GET_USER, payload: user});
      console.log('CURRENT_USER', user);
      dispatch(setAvalaiabilitiesList(user.instructor.availabilities));

      if (conversations?.length > 0) {
        const adminConversation = user?.userconversations?.map(
          (adminConv: Userconversation) => {
            const conversation = conversations.find(
              (_adminCon: Userconversation) => _adminCon.id === adminConv.id,
            );
            if (conversation) {
              return conversation;
            } else {
              return adminConv;
            }
          },
        );
        dispatch(setUserConversations([...adminConversation] ?? []));
      } else {
        dispatch(setUserConversations(user?.userconversations ?? []));
      }

      dispatch(subscribeToNewMessages());
    } else {
      console.log('ERROR_NO_USER_FOUND');
      dispatch(isLoadingRequest(false));
      dispatch({type: NO_USER_FOUND});
    }
  } catch (error) {
    dispatch(isLoadingRequest(false, error));
    dispatch({type: 'RESET_USER'});
    console.log('ERROR_GET_USER', error);
    // dispatch(doSignOut());
  }
};

export const logIn =
  (email: string, password: string) => async (dispatch, getState) => {
    dispatch(isLoadingRequest(true));

    try {
      let response: SignInOutput = await authRepo.signIn(email, password);
      // console.log('RESSS', response);
      const authData = fetchUserAttributes();
      await dispatch(getUser());
      dispatch({type: SIGN_IN_SUCCESS, payload: authData});

      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      console.log('SIGN_IN_ERROR', error.message);
      dispatch(isLoadingRequest(false, error));

      if (error?.name === 'UserAlreadyAuthenticatedException') {
        console.log('UserAlreadyAuthenticatedException', error);
        dispatch(isLoadingRequest(false));
        await signOut();
        dispatch(logIn(email, password));
      } else {
        console.log('SIGN_IN_ERROR', error);
        dispatch(isLoadingRequest(false, error));
      }
    }
  };

// export const set = (userConversations: Array<null>) => {
//   return {type: SET_CONVERSATIONS, payload: userConversations};
// };

export const createUserMessage = (
  content: string,
  conversationId: string,
  endPointArn: string,
  conversationName: string,
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const userId = getState().user.user.id;
      const instructorName = getState().user.currentUser.instructor.name;

      const message: MessageInsert = {
        conversationId,
        content: content,
        senderType: SenderType.USER_INSTRUCTOR,
        userId,
      };

      const sendNotification: any = {
        title: 'New message from: ' + instructorName,
        body: content,
        targetArn: endPointArn,
        conversationId,
      };

      await client.graphql({
        query: createMessage,
        variables: {
          input: message,
        },
      });

      if (endPointArn) {
        await client.graphql({
          query: sendMessage,
          variables: {
            ...sendNotification,
          },
        });
      }
    } catch (error: any) {
      console.log('ERROR_CHREAETING_MESSAGE_createUserMessage', error);
      // dispatch(isLoadingStudentState(false, error));
    }
  };
};

export const subscribeToNewConversation = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const subscribedNewUcs: GraphQLResult<any> = await client.graphql({
        query: subscribeToNewUCs,
        variables: {},
      });

      //@ts-ignore
      let unSubscribe = subscribedNewUcs.subscribe({
        next: ({data}: any) => {
          const newSubscribedUsrConv: Userconversation = data.subscribeToNewUCs;

          console.log('newSubscribedUsrConv', newSubscribedUsrConv);

          const currentUser: User = getState().user.user;

          if (currentUser.id === newSubscribedUsrConv?.user?.id) {
            // createLocalNotification(message);
            setTimeout(() => {
              dispatch(getUser());
            }, 1200);
          }
        },
        error: (error: any) => console.warn(error),
      });
    } catch (error: any) {
      console.log('ERROR_SUBSCR)BE_YS', error);
      // dispatch(isLoadingStudentState(false, error));
    }
  };
};

export const fetchMessagesByConversationId = (
  conversationId: string,
  isFetchMore: boolean | null = true,
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(isLoadingUser(true));
      console.log('_next_Token', isFetchMore);

      const conversations = getState().user.conversations;

      const selectedUsrConversationIndex = conversations.findIndex(
        (usrConv: Userconversation) =>
          usrConv.conversation.id === conversationId,
      );

      const conversation =
        conversations[selectedUsrConversationIndex].conversation;

      const _temp_next_token =
        conversations[selectedUsrConversationIndex]?.nextToken;

      let nextToken: string | null =
        _temp_next_token && isFetchMore ? _temp_next_token : null;

      const scannedCount: number = 20;

      const conversationMessResp: GraphQLResult<any> = await client.graphql({
        query: messagesByConversationId,
        variables: {
          conversationId,
          nextToken,
          scannedCount,
        },
      });

      const conversationMessages =
        conversationMessResp.data?.messagesByConversationId;

      const _tempStdConversations: Array<Userconversation> = [...conversations];
      const oldMessages =
        conversation?.messages && nextToken ? conversation?.messages : [];

      //@ts-ignore
      _tempStdConversations[selectedUsrConversationIndex].nextToken =
        conversationMessages.nextToken;

      _tempStdConversations[selectedUsrConversationIndex].conversation = {
        ...conversation,
        messages: [...oldMessages, ...conversationMessages.items],
      };

      dispatch(setUserConversations(_tempStdConversations));
      dispatch(isLoadingUser(false));
    } catch (error: any) {
      dispatch(isLoadingUser(false));
      console.log('SDerrorSDselectedConversation', error);
    }
  };
};

function subscribeToNewMessages(): any {
  return async (dispatch: any, getState: any) => {
    try {
      const usrConversations: Array<Userconversation> =
        getState().user.conversations;
      console.log('subscribeToNewMessages', usrConversations);
      usrConversations.map(async (usrConv: Userconversation) => {
        const subscribedConversationIds: Array<string> =
          getState().user.subscribedConversationIds;

        console.log('subscribedConversationIds', subscribedConversationIds);

        if (
          subscribedConversationIds &&
          !subscribedConversationIds.includes(usrConv.conversation.id)
        ) {
          console.log('NOT_SUBSCRIBEDD_YETTT', subscribedConversationIds);

          let newSubscribedConversationIds = [...subscribedConversationIds];
          newSubscribedConversationIds.push(usrConv.conversation.id);
          dispatch(
            setSubscribedConversationIds([...newSubscribedConversationIds]),
          );

          const subscribedConversation: GraphQLResult<any> =
            await client.graphql({
              query: subscribeToNewMessage,
              variables: {
                conversationId: usrConv?.conversation?.id,
              },
            });

          //@ts-ignore
          let unSubscribe = subscribedConversation.subscribe({
            next: ({data}: any) => {
              const message: Message = data.subscribeToNewMessage;
              console.log('SET_CONVERSATIONS', message);
              const usrConversations: Array<Userconversation> =
                getState().user.conversations;

              const conversationIndexRelatedToMessage: number =
                usrConversations.findIndex(
                  (usrConv: Userconversation) =>
                    usrConv?.conversation?.id === message?.conversationId,
                );

              let newUsrConversation = [...usrConversations];
              if (conversationIndexRelatedToMessage !== -1) {
                newUsrConversation[
                  conversationIndexRelatedToMessage
                ].conversation.messages?.unshift(message);

                console.log(
                  'newUsrConversation_MESSSSGGGG',
                  newUsrConversation,
                );

                dispatch(setUserConversations([...newUsrConversation]));
              } else {
                console.log('UnsubscribedConversation');

                //@ts-ignore
                unSubscribe.unsubscribe();
              }
            },
            error: (error: any) => console.warn(error),
          });
        } else {
          console.log('ALREADY_SUBSCRIBED', subscribedConversationIds);
        }
      });
    } catch (error: any) {
      console.log('SDerrorSDsubscription', error);
    }
  };
}

export const setUserConversations = (
  userConversations: Array<Userconversation | null>,
) => {
  return {type: SET_CONVERSATIONS, payload: userConversations};
};

export const setSubscribedConversationIds = (
  subscribedConversationIds: string[],
) => {
  return {
    type: SET_SUBSCRIBED_ON_CONVERSATION,
    payload: subscribedConversationIds,
  };
};

export const isLoadingUser = (isLoading: boolean) => {
  return {
    type: USER_IS_LOADING,
    payload: {isLoading},
  };
};
