/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onAddEnrollment = /* GraphQL */ `
  subscription OnAddEnrollment {
    onAddEnrollment {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      placementStatus
      paymentStatus
      startDate
      endDate
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      answer {
        id
        createdAt
        updatedAt
        choice
        audioUri
        text
        score
        analysis
        useEnglishAi
      }
      selfRating
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
    }
  }
`;
export const onUpdateEnrollment = /* GraphQL */ `
  subscription OnUpdateEnrollment {
    onUpdateEnrollment {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      placementStatus
      paymentStatus
      startDate
      endDate
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      answer {
        id
        createdAt
        updatedAt
        choice
        audioUri
        text
        score
        analysis
        useEnglishAi
      }
      selfRating
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
    }
  }
`;
export const subscribeToNewMessage = /* GraphQL */ `
  subscription SubscribeToNewMessage($conversationId: ID!) {
    subscribeToNewMessage(conversationId: $conversationId) {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      conversationId
    }
  }
`;
export const subscribeToNewUCs = /* GraphQL */ `
  subscription SubscribeToNewUCs {
    subscribeToNewUCs {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
    }
  }
`;
export const subscribeDeleteUCs = /* GraphQL */ `
  subscription SubscribeDeleteUCs($id: ID!) {
    subscribeDeleteUCs(id: $id) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
    }
  }
`;
export const subscribeStartSession = /* GraphQL */ `
  subscription SubscribeStartSession($id: ID!) {
    subscribeStartSession(id: $id) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const subscribeEndSession = /* GraphQL */ `
  subscription SubscribeEndSession($id: ID!) {
    subscribeEndSession(id: $id) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
