import {customMe} from '../customQueries/user';
import {generateClient, GraphQLResult} from 'aws-amplify/api';

import {
  signIn as signInAmplify,
  signOut as signOutAmplify,
} from 'aws-amplify/auth';

const client = generateClient();
export interface IAuthRepository {
  signIn(userName: string, password: string): Promise<any | any>;
  signOut(): Promise<any>;

  getCurrentUser(): Promise<any>;
}

export class AuthRepository implements IAuthRepository {
  async getCurrentUser(): Promise<any> {
    const res: GraphQLResult<any> = await client.graphql({
      query: customMe,
      variables: {},
    });

    return res.data.me;
  }

  async signIn(userName: string, password: string): Promise<any | any> {
    return await signInAmplify({
      username: userName,
      password: password,

      options: {
        clientMetadata: {
          typeLogin: 'Instructors',
        },
      },
    });
  }

  async signOut(): Promise<any> {
    return await signOutAmplify();
  }
}
