export const session = /* GraphQL */ `
  query Session($id: String!) {
    session(id: $id) {
      id
      createdAt
      updatedAt
      name
      status

      group {
        id
        createdAt
        updatedAt

        name
        division
        limit
        isGroupHasInstructor
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        student {
          id
          name
        }
      }
    }
  }
`;
