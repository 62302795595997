import {legacy_createStore, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './reducers'; // the value from combineReducers

const middleware: any = [];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['avaliablity', 'user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = legacy_createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware, thunk)),
);
const persistor = persistStore(store);

export {store, persistor};
