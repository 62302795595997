import {
  Availability,
  AvailabilityInsert,
  Slot,
  Status,
} from '../../graphql/API';
import {createAvailability} from '../../graphql/mutations';
import {updateSlot} from '../actions/CustomMutations';
import {deleteAvailability} from '../customMutations/availability';
import {generateClient, GraphQLResult} from 'aws-amplify/api';

const client = generateClient();
export interface IAvailabilityRepository {
  createAvailability(
    availability: AvailabilityInsert,
  ): Promise<Availability | any>;

  deleteAvailability(availabilityId: string): Promise<any>;

  changeSlotStatus(
    slotId: string,
    status: Status,
    updatedAt: string,
  ): Promise<any>;
}

export class AvailabilityRepository implements IAvailabilityRepository {
  async changeSlotStatus(
    slotId: string,
    status: Status,
    updatedAt: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: updateSlot,
      variables: {
        id: slotId,
        updatedAt,
        input: {status},
      },
    });

    console.log('response-', response);
    return response;
  }
  async deleteAvailability(availabilityId: string): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: deleteAvailability,
      variables: {
        id: availabilityId,
      },
    });

    console.log('response-', response);
    return response;
  }

  async createAvailability(
    availability: AvailabilityInsert,
  ): Promise<Availability | any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: createAvailability,
      variables: {
        input: availability,
      },
    });

    console.log('response-', response);
    return response;
  }
}
