export const getGroup = /* GraphQL */ `
  query Group($id: String!) {
    group(id: $id) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      isActive
      isGroupHasInstructor
      # limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive

        student {
          id
          name
        }
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        zoomUrl
        jitsiUrl
        moderatorToken
        rtcInstructorToken
        rtcShareScreenToken
        instructor {
          id
          name
        }
        lesson {
          id
          name
          summary {
            id
            type
            fileUri
            thumbnailUri
            title_i18n
            subTitle_i18n
          }
          lessonPDF {
            id
            type
            fileUri
            thumbnailUri
            title_i18n
            subTitle_i18n
          }
          instructionsPDF {
            id
            type
            fileUri
            thumbnailUri
            title_i18n
            subTitle_i18n
          }
        }
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        user {
          id
          timeZone
        }
      }
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        # sessionDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
    }
  }
`;
