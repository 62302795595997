import {Link, Switch, useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Alert, Button, Menu, Modal, Layout} from 'antd';

import {Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {UserState} from '../redux/reducers/user';

import {reducersState} from '../redux/reducers';
import SignIn from '../modules/SignIn';
import {SiderComponent} from './Sider';
import {Conversation} from './Conversation';

import {
  getUser,
  isLoadingRequest,
  subscribeToNewConversation,
} from '../redux/actions/user';
import {Avaliablities} from '../modules/Instructor';
import {MyAvaliablabilities} from '../modules/InstructorMyAvailability';
import GroupDetails from '../modules/Groups/GroupDetails';
import 'react-toastify/dist/ReactToastify.css';
import {ReviewStudent} from '../modules/ReviewStudent';
import {fetchReviewQuestions} from '../redux/actions/review';
import {SiderChat} from './SiderChat';
import {Userconversation} from '../graphql/API';
import {Content} from 'antd/lib/layout/layout';
import PDFView from '../modules/PDFView/PDFView';

export const Routers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedConversations, setSelectedConversations] = useState<
    Array<Userconversation>
  >([]);

  const isLoggedIn = useSelector(
    (state: reducersState) => state.user.isLoggedIn,
  );

  const currentUser = useSelector(
    (state: reducersState) => state.user.currentUser,
  );

  const error = useSelector((state: reducersState) => state.user.error);

  console.log('currentUser', currentUser);
  console.log('isLoggedIn', isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUser());
      // dispatch(subscribeToNewConversation());
      dispatch(fetchReviewQuestions());
    }
  }, [dispatch, isLoggedIn]);

  return (
    <div>
      <div style={{marginTop: 50, position: 'absolute'}}>
        {error && (
          <Alert
            description={error.message}
            type="error"
            style={{
              fontWeight: 'bold',
              zIndex: 5,
              width: '400px',
              // height: '200px',
              // display: 'block',
            }}
            message="Error Occurs"
            showIcon
            action={
              <Button
                size="small"
                danger
                onClick={() => {
                  dispatch(isLoadingRequest(false, null));
                }}>
                Dismiss
              </Button>
            }
          />
        )}
      </div>
      {!isLoggedIn ? (
        <SignIn />
      ) : (
        <Layout>
          <Content>
            <SiderComponent />

            <div
              style={{
                backgroundColor: 'transparent',
                textAlign: 'right',
                bottom: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                alignSelf: 'flex-start',
                marginInlineEnd: 210,
                zIndex: 100,
                position: 'fixed',
                overflowX: 'auto',
              }}>
              {selectedConversations.map(
                (usrConver: Userconversation, index: number) => (
                  <Conversation
                    index={index}
                    onClose={(convIndex) => {
                      selectedConversations.splice(convIndex, 1);
                      setSelectedConversations([...selectedConversations]);
                    }}
                    userConversation={usrConver}
                  />
                ),
              )}
            </div>
            <Switch>
              {/** Avalabiilty  routes*/}
              <Route exact path="/home" component={Avaliablities} />
              <Route
                exact
                path="/availability/new"
                component={MyAvaliablabilities}
              />
              <Route path="/group/:groupId" component={GroupDetails} />
              <Route path="/view" component={PDFView} />
              <Route
                path="/session-review-students/:sessionId"
                component={ReviewStudent}
              />
            </Switch>
          </Content>
          <SiderChat
            onPressChat={(usrConversation: Userconversation) => {
              const conversationExsit = selectedConversations.some(
                (usrConv) => usrConv.id === usrConversation.id,
              );
              if (!conversationExsit) {
                setSelectedConversations([
                  ...selectedConversations,
                  usrConversation,
                ]);
              }
            }}
          />
        </Layout>
      )}
    </div>
  );
};
