import {Link, useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Button, Menu, Typography} from 'antd';
import {useDispatch} from 'react-redux';
import {signOut} from 'aws-amplify/auth';

const {Text} = Typography;

export const SiderComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const RenderTabs = () => (
    <div style={{alignItems: 'flex-start', display: 'flex'}}>
      <Menu.Item key="home">
        <Link to={'/home'}>Home</Link>
      </Menu.Item>
    </div>
  );
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'start',
      }}>
      <Text
        style={{
          alignSelf: 'flex-start',
          color: 'white',
          fontWeight: 'bold',
          textAlign: 'left',
          marginInline: 20,
        }}>
        PikaDo Instructors
      </Text>
      <RenderTabs />
      <Menu.Item style={{}} key="signOut">
        <Button
          style={{width: 150, fontWeight: 'bold'}}
          onClick={async () => {
            await signOut();
            dispatch({type: 'RESET_USER'});
            history.go(0);
          }}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
};
