import {IIGroupsRepository} from './IIGroupsRepository';
import {getGroup} from './GroupQueries';
import {Group, GroupUpdate, Status} from '../../graphql/API';
import {
  updateSession,
  endSession,
  updateGroup,
  rescheduleSession,
  levelUpGroup,
  startSession,
} from './GroupMutations';
import {levels} from '../../graphql/queries';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class GroupsRepository implements IIGroupsRepository {
  async getLevels(): Promise<any> {
    let lvls: GraphQLResult<any> = await client.graphql({
      query: levels,
      variables: {},
    });

    console.log('response-', lvls);

    return lvls?.data.levels.items;
  }
  async levelUp(groupId: string, levelId: string): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: levelUpGroup,
      variables: {
        groupId,
        levelId,
      },
    });

    console.log('response-', response);

    return response;
  }

  async rescheduleSession(id: string, newStartTimeDate: string): Promise<any> {
    console.log('groupId', newStartTimeDate);

    let response: GraphQLResult<any> = await client.graphql({
      query: rescheduleSession,
      variables: {
        id,
        startDateTime: newStartTimeDate,
      },
    });

    console.log('response-', response);

    return response;
  }
  async updateGroupHasInstructor(
    groupId: string,
    updatedAt: string,
    groupUpdate: GroupUpdate,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: updateGroup,
      variables: {
        id: groupId,
        updatedAt,
        input: {
          ...groupUpdate,
        },
      },
    });

    console.log('response-', response);

    return response;
  }
  async startSession(groupId: string): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: startSession,
      variables: {
        id: groupId,
      },
    });

    console.log('response-', response);

    return response;
  }

  async endSession(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: endSession,
      variables: {
        id: sessionId,
        status,
      },
    });

    console.log('response-', response);

    return response;
  }

  async changeSessionStatus(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    console.log('UDAS', sessionId, updatedAt);

    let response: GraphQLResult<any> = await client.graphql({
      query: updateSession,
      variables: {
        id: sessionId,
        updatedAt,
        input: {status},
      },
    });

    console.log('response-', response);

    return response;
  }

  async getGroupById(id: string): Promise<Group> {
    let group: GraphQLResult<any> = await client.graphql({
      query: getGroup,
      variables: {
        id,
      },
    });

    console.log('response-', group);

    return group.data?.group;
  }
}
