import {ReviewQuestion, Session} from '../../graphql/API';
import {SET_REVIEW_QUESTIONS, SET_PERVIOUS_SESSION} from '../actions/types';

export type ReviewState = {
  reviewQuestionsList: Array<ReviewQuestion>;
  perviousSession: Session | null;
  isLoading: boolean;
};

export const initialState: ReviewState = {
  reviewQuestionsList: [],
  perviousSession: null,
  isLoading: false,
};

const review = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_REVIEW_QUESTIONS: {
      return {
        ...state,
        reviewQuestionsList: action.payload,
      };
    }

    case SET_PERVIOUS_SESSION: {
      return {
        ...state,
        perviousSession: action.payload,
      };
    }

    default:
      return state;
  }
};

export default review;
