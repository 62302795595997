/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Membership = {
  __typename: "Membership",
  id: string,
  createdAt: string,
  updatedAt: string,
  student: Student,
  package: Package,
  curriculum?: Curriculum | null,
  level: Level,
  paymentStatus?: Status | null,
  placementStatus?: Status | null,
  isActive: boolean,
  startDate?: string | null,
  endDate?: string | null,
  program: Program,
  sessions?:  Array<Session | null > | null,
  sessionPerWeek?: number | null,
  isFullFilled?: boolean | null,
  groups?:  Array<Group | null > | null,
  discountcode?: Discountcode | null,
  studentaisessions?:  Array<Studentaisession | null > | null,
};

export type Node = {
  __typename: "Node",
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type Studentaudioprofile = {
  __typename: "Studentaudioprofile",
  id: string,
  createdAt: string,
  updatedAt: string,
  introAIVSUri: string,
  sentenceIntro: string,
  correctAIVSUri: string,
  sentenceCorrect: string,
  failAIVSUri: string,
  sentenceFail: string,
  finalAIVSUri: string,
  sentenceFinal: string,
  studentName: string,
  student: Student,
};

export type Student = {
  __typename: "Student",
  id: string,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
  schoolName?: string | null,
  name: string,
  birthDate?: string | null,
  age: number,
  targetAge?: number | null,
  gender: Gender,
  language?: Language | null,
  notes?: string | null,
  facebookProfileUrl?: string | null,
  enrollments?:  Array<Enrollment | null > | null,
  memberships?:  Array<Membership | null > | null,
  statistic?: Statistic | null,
  studentaudioprofile?: Studentaudioprofile | null,
  status?: Student_Status | null,
  assignedTo?: string | null,
  sessions?:  Array<Session | null > | null,
  studentaisessions?:  Array<Studentaisession | null > | null,
  studentconversations?:  Array<Studentconversation | null > | null,
  level?: Level | null,
  school?: School | null,
  classschool?: Classschool | null,
  isSchoolVerfied?: boolean | null,
  placementStatus?: Status | null,
  email?: string | null,
  userName?: string | null,
  totalRatingConcentration?: number | null,
  totalRatingCamera?: number | null,
  totalRatingInternet?: number | null,
  totalRatingSessionQuality?: number | null,
  totalRating?: number | null,
  totalReviews?: number | null,
};

export type User = {
  __typename: "User",
  id: string,
  createdAt: string,
  updatedAt: string,
  cognitoUserId: string,
  country?: string | null,
  state?: string | null,
  whatsappNumber?: string | null,
  timeZone?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  parent?: Parent | null,
  admin?: Admin | null,
  instructor?: Instructor | null,
  students?:  Array<Student > | null,
  school?: School | null,
  teacher?: Teacher | null,
  userconversations?:  Array<Userconversation | null > | null,
  deviceToken?: string | null,
  devicePlatform?: string | null,
  endPointArn?: string | null,
  email?: string | null,
  contactIDActiveCampaign?: string | null,
  partnerAppName?: PikaDoPartners | null,
  preInstructor?: PreInstructor | null,
};

export type Parent = {
  __typename: "Parent",
  id: string,
  createdAt: string,
  updatedAt: string,
  user: User,
  name: string,
  gender: Gender,
};

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NONE = "NONE",
}


export type Admin = {
  __typename: "Admin",
  id: string,
  createdAt: string,
  updatedAt: string,
  user: User,
  lastSeenOnline?: string | null,
  firstName: string,
  lastName: string,
  address: string,
  SSN?: number | null,
  gender: Gender,
  isActive: boolean,
};

export type Instructor = {
  __typename: "Instructor",
  id: string,
  createdAt: string,
  updatedAt: string,
  user: User,
  name: string,
  gender: Gender,
  program: Program,
  promo?: Asset | null,
  sessionsCount?: number | null,
  speakingLanguages?: Array< string | null > | null,
  availabilities?:  Array<Availability | null > | null,
  slots?:  Array<Slot | null > | null,
  sessions?:  Array<Session | null > | null,
  isNative?: boolean | null,
  totalRating?: number | null,
  totalReviews?: number | null,
  paymentMethod?: string | null,
  rating?: number | null,
  isActive?: boolean | null,
  status?: InstructorStatus | null,
  birthDate?: string | null,
  totalRatingConcentration?: number | null,
  totalRatingCamera?: number | null,
  totalRatingInternet?: number | null,
  totalRatingSessionQuality?: number | null,
};

export type Program = {
  __typename: "Program",
  id: string,
  createdAt: string,
  updatedAt: string,
  name?: string | null,
  name_i18n?: string | null,
  packages:  Array<Package >,
  test?: Test | null,
  courses:  Array<Course >,
  activities?:  Array<Asset | null > | null,
  records?:  Array<Asset | null > | null,
  flag?: string | null,
  supportAi?: boolean | null,
  isActive?: boolean | null,
  instructors?:  Array<Instructor | null > | null,
};

export type Package = {
  __typename: "Package",
  id: string,
  createdAt: string,
  updatedAt: string,
  number?: number | null,
  onlyAI?: boolean | null,
  name?: string | null,
  name_i18n?: string | null,
  sessionDuration?: number | null,
  program: Program,
  price: Price,
  description?: string | null,
  benefits?: string | null,
  benefits_i18n?: string | null,
  promoAsset: Asset,
  coverAsset?: Asset | null,
  activities?:  Array<Asset | null > | null,
  records?:  Array<Asset | null > | null,
  details?: string | null,
  details_i18n?: string | null,
  isFree?: boolean | null,
  numberOfSessions: number,
  description_i18n?: string | null,
  isNative?: boolean | null,
  isActive?: boolean | null,
  isHidden?: boolean | null,
  enrollmentPackage?: Package | null,
  promocodes?:  Array<Promocode | null > | null,
  productIAP?: string | null,
  typePackage?: DivisionType | null,
  packageDuration?: number | null,
  discountDescription?: string | null,
  isRecommended?: boolean | null,
  sessionPerWeek?: Array< number | null > | null,
};

export type Price = {
  __typename: "Price",
  id: string,
  createdAt: string,
  updatedAt: string,
  package: Package,
  name: string,
  price: number,
  currency: string,
  country: string,
};

export type Asset = {
  __typename: "Asset",
  id: string,
  createdAt: string,
  updatedAt: string,
  type?: AssetType | null,
  thumbnailUri?: string | null,
  fileUri?: string | null,
  title_i18n?: string | null,
  subTitle_i18n?: string | null,
  position?: number | null,
};

export enum AssetType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  PHOTO = "PHOTO",
  PDF = "PDF",
}


export type Promocode = {
  __typename: "Promocode",
  id: string,
  createdAt: string,
  updatedAt: string,
  codeName: string,
  isApplied: boolean,
  package: Package,
  student?: Student | null,
  sessionPerWeek?: number | null,
  level?: Level | null,
  school?: School | null,
  curriculum?: Curriculum | null,
};

export type Level = {
  __typename: "Level",
  id: string,
  createdAt: string,
  updatedAt: string,
  name: string,
  number?: number | null,
  score?: number | null,
};

export type School = {
  __typename: "School",
  id: string,
  createdAt: string,
  updatedAt: string,
  nameArabic: string,
  name: string,
  students?:  Array<Student > | null,
  teachers?:  Array<Teacher > | null,
  user: User,
  isActive: boolean,
  classesschool?:  Array<Classschool | null > | null,
  promo?: Asset | null,
};

export type Teacher = {
  __typename: "Teacher",
  id: string,
  createdAt: string,
  updatedAt: string,
  name: string,
  userName?: string | null,
  email?: string | null,
  classesschool:  Array<Classschool >,
  school: School,
  user: User,
};

export type Classschool = {
  __typename: "Classschool",
  id: string,
  createdAt: string,
  updatedAt: string,
  name: string,
  schoolYear?: string | null,
  students:  Array<Student >,
  school: School,
  teacher?: Teacher | null,
  statistic?: Statistic | null,
  level?: Level | null,
};

export type Statistic = {
  __typename: "Statistic",
  id: string,
  createdAt: string,
  updatedAt: string,
  student?: Student | null,
  scores?: Array< number | null > | null,
  averageScore?: number | null,
  // Be calculated using the monthlyReports average
  // mhhr new
  fullReportLastGenerationDate?: string | null,
  monthlyReports?:  Array<MonthlyReport | null > | null,
  // SchoolReport
  classschool?: Classschool | null,
  phoneticsAsString?: string | null,
  syllablesAsString?: string | null,
};

export type MonthlyReport = {
  __typename: "MonthlyReport",
  id: string,
  createdAt: string,
  updatedAt: string,
  month?: string | null,
  average?: number | null,
  mistakes?: number | null,
  attempts?: number | null,
  statistic?: Statistic | null,
  d1?: number | null,
  d2?: number | null,
  d3?: number | null,
  d4?: number | null,
  d5?: number | null,
  d6?: number | null,
  d7?: number | null,
  d8?: number | null,
  d9?: number | null,
  d10?: number | null,
  d11?: number | null,
  d12?: number | null,
  d13?: number | null,
  d14?: number | null,
  d15?: number | null,
  d16?: number | null,
  d17?: number | null,
  d18?: number | null,
  d19?: number | null,
  d20?: number | null,
  d21?: number | null,
  d22?: number | null,
  d23?: number | null,
  d24?: number | null,
  d25?: number | null,
  d26?: number | null,
  d27?: number | null,
  d28?: number | null,
  d29?: number | null,
  d30?: number | null,
  d31?: number | null,
};

export type Curriculum = {
  __typename: "Curriculum",
  id: string,
  createdAt: string,
  updatedAt: string,
  name: string,
  courses:  Array<Course >,
  totalPlanPeriod: number,
};

export type Course = {
  __typename: "Course",
  id: string,
  createdAt: string,
  updatedAt: string,
  name?: string | null,
  program: Program,
  language: Language,
  level: Level,
  plan?: string | null,
  lessons?:  Array<Lesson | null > | null,
  videosessions?:  Array<Videosession | null > | null,
  curriculum?: Curriculum | null,
};

export type Language = {
  __typename: "Language",
  id: string,
  // should be <language>_<country>
  createdAt: string,
  updatedAt: string,
  language?: string | null,
  country?: string | null,
};

export type Lesson = {
  __typename: "Lesson",
  id: string,
  createdAt: string,
  updatedAt: string,
  name: string,
  summary?: Asset | null,
  lessonPDF?: Asset | null,
  instructionsPDF?: Asset | null,
  tests?:  Array<Test | null > | null,
  course?: Course | null,
};

export type Test = {
  __typename: "Test",
  id: string,
  createdAt: string,
  updatedAt: string,
  questions?:  Array<Question | null > | null,
  type: TestTypes,
  name?: string | null,
  name_i18n?: string | null,
  description?: string | null,
  description_i18n?: string | null,
  summary?: string | null,
  summary_i18n?: string | null,
  asset: Asset,
  lesson?: Lesson | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  createdAt: string,
  updatedAt: string,
  name?: string | null,
  name_i18n?: string | null,
  correctAsset?: Asset | null,
  asset?: Asset | null,
  videoAISessionUri?: string | null,
  choices: Array< string >,
  programType: ProgramType,
  keywords: Array< string >,
  summary?: string | null,
  summary_i18n?: string | null,
  description?: string | null,
  description_i18n?: string | null,
  text: string,
  textAI?: string | null,
  type?: QUESTION_TYPE | null,
};

export enum ProgramType {
  LANGUAGE = "LANGUAGE",
  PROGRAMMING = "PROGRAMMING",
}


export enum QUESTION_TYPE {
  MISSING_LETTERS = "MISSING_LETTERS",
  MISSING_WORD = "MISSING_WORD",
  CHOICES = "CHOICES",
  BUILD_SENTENCE = "BUILD_SENTENCE",
  READ_SENTENCE = "READ_SENTENCE",
}


export enum TestTypes {
  QUIZ = "QUIZ",
  PLACEMENT_TEST = "PLACEMENT_TEST",
  STORY = "STORY",
}


export type Videosession = {
  __typename: "Videosession",
  id: string,
  createdAt: string,
  updatedAt: string,
  level: Level,
  curriculum: Curriculum,
  course: Course,
  test: Test,
  studentaisessions:  Array<Studentaisession >,
  duration: number,
  name: string,
  isActive: boolean,
};

export type Studentaisession = {
  __typename: "Studentaisession",
  id: string,
  createdAt: string,
  updatedAt: string,
  level: Level,
  curriculum: Curriculum,
  videosession: Videosession,
  membership: Membership,
  student: Student,
  status: Status,
  currentQuestionIndex: number,
};

export enum Status {
  TODO = "TODO",
  DOING = "DOING",
  DONE = "DONE",
  SUSPENDED = "SUSPENDED",
}


export enum DivisionType {
  ONE_TO_ONE = "ONE_TO_ONE",
  GROUP = "GROUP",
  VIDEO_SESSION = "VIDEO_SESSION",
}


export type Availability = {
  __typename: "Availability",
  id: string,
  createdAt: string,
  updatedAt: string,
  instructor: Instructor,
  startTime: string,
  endTime: string,
  slots?:  Array<Slot | null > | null,
  day: number,
};

export type Slot = {
  __typename: "Slot",
  id: string,
  createdAt: string,
  updatedAt: string,
  name?: string | null,
  slotDuration: number,
  availability: Availability,
  program: Program,
  level: Level,
  startTime: string,
  endTime: string,
  status: Status,
  limit: number,
  current: number,
  day: number,
  group?: Group | null,
  instructor: Instructor,
};

export type Group = {
  __typename: "Group",
  id: string,
  createdAt: string,
  updatedAt: string,
  startDate?: string | null,
  name: string,
  division: DivisionType,
  limit: number,
  level: Level,
  memberships?:  Array<Membership | null > | null,
  sessions?:  Array<Session | null > | null,
  instructor: Instructor,
  isGroupHasInstructor?: boolean | null,
  program: Program,
  slot?: Slot | null,
  nextSession?: Session | null,
  perviousSession?: Session | null,
  currentSessionNumber?: number | null,
  conversation?: Conversation | null,
  isActive: boolean,
};

export type Session = {
  __typename: "Session",
  id: string,
  createdAt: string,
  updatedAt: string,
  name: string,
  slot: Slot,
  duration: number,
  startDateTime?: string | null,
  startTime: string,
  endTime: string,
  status: Status,
  isRescheduled?: boolean | null,
  instructor?: Instructor | null,
  lesson?: Lesson | null,
  moderatorToken?: string | null,
  jitsiUrl?: string | null,
  rtcUserToken?: string | null,
  rtcInstructorToken?: string | null,
  rtcShareScreenToken?: string | null,
  rtcAdminToken?: string | null,
  zoomUrl?: string | null,
  group?: Group | null,
  memberships?:  Array<Membership | null > | null,
  level?: Level | null,
};

export type Conversation = {
  __typename: "Conversation",
  // The Conversation's timestamp.
  createdAt: string,
  updatedAt: string,
  // A unique identifier for the Conversation.
  id: string,
  // The Conversation's name.
  name: string,
  // Indicator is chat active or not
  isActive?: boolean | null,
  // The Conversation's messages.
  messages?:  Array<Message | null > | null,
  group?: Group | null,
  userconversations?:  Array<Userconversation | null > | null,
  studentconversations?:  Array<Studentconversation | null > | null,
};

export type Message = {
  __typename: "Message",
  // The message content.
  content: string,
  // The message timestamp. This is also the table sort key.
  createdAt: string,
  updatedAt: string,
  // Generated id for a message -- read-only
  id: string,
  // Flag denoting if this message has been accepted by the server or not.
  isSent?: boolean | null,
  // type of the sender message (user|student)
  senderType: SenderType,
  // add student sender who send message
  student?: Student | null,
  // add user sender who send message
  user?: User | null,
  // The   Conversation this message belongs to. This is the table primary key.
  conversationId: string,
};

export enum SenderType {
  STUDENT = "STUDENT",
  USER = "USER",
  USER_INSTRUCTOR = "USER_INSTRUCTOR",
  SYSTEM = "SYSTEM",
}


export type Userconversation = {
  __typename: "Userconversation",
  createdAt: string,
  updatedAt: string,
  id: string,
  conversation: Conversation,
  user?: User | null,
};

export type Studentconversation = {
  __typename: "Studentconversation",
  createdAt: string,
  updatedAt: string,
  id: string,
  conversation: Conversation,
  student?: Student | null,
};

export enum InstructorStatus {
  NEW = "NEW",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  SUSPENDED = "SUSPENDED",
}


export enum PikaDoPartners {
  CLASSERA = "CLASSERA",
}


export type PreInstructor = {
  __typename: "PreInstructor",
  id: string,
  createdAt: string,
  updatedAt: string,
  user: User,
  name: string,
  gender: Gender,
  program: Program,
  promo?: Asset | null,
  rating?: number | null,
  sessionsCount?: number | null,
  speakingLanguages?: Array< string | null > | null,
  availabilities?:  Array<Availability | null > | null,
  slots?:  Array<Slot | null > | null,
  sessions?:  Array<Session | null > | null,
  isNative?: boolean | null,
  totalRating?: number | null,
  totalReviews?: number | null,
  isActive?: boolean | null,
  status?: InstructorStatus | null,
  birthDate?: string | null,
};

export type Enrollment = {
  __typename: "Enrollment",
  id: string,
  createdAt: string,
  updatedAt: string,
  student: Student,
  package: Package,
  level?: Level | null,
  test?: Test | null,
  placementStatus: Status,
  paymentStatus: Status,
  startDate?: string | null,
  endDate?: string | null,
  slots?:  Array<Slot | null > | null,
  answer?: Answer | null,
  selfRating?: number | null,
  curriculum?: Curriculum | null,
};

export type Answer = {
  __typename: "Answer",
  id: string,
  createdAt: string,
  updatedAt: string,
  student: Student,
  question: Question,
  enrollment?: Enrollment | null,
  monthlyReport?: MonthlyReport | null,
  choice?: number | null,
  audioUri?: string | null,
  text?: string | null,
  score?: number | null,
  analysis?: string | null,
  useEnglishAi?: boolean | null,
};

export enum Student_Status {
  NEW_CUSTOMER = "NEW_CUSTOMER",
  ADDED_TO_WHATSAPP = "ADDED_TO_WHATSAPP",
  CONTACTED_BY_WHATSAPP = "CONTACTED_BY_WHATSAPP",
  RECEIVED_CALL = "RECEIVED_CALL",
  INTERESTED = "INTERESTED",
  NOT_INTERESTED = "NOT_INTERESTED",
  FOLLOW_UP = "FOLLOW_UP",
  CALLED_WITHOUT_RESPONSE = "CALLED_WITHOUT_RESPONSE",
  JOINED = "JOINED",
}


export type Discountcode = {
  __typename: "Discountcode",
  id: string,
  createdAt: string,
  updatedAt: string,
  codeName: string,
  valuePercentage?: number | null,
  package: Package,
  isExpired: boolean,
  validFrom: string,
  expirationDate: string,
  usage: DiscountUsage,
  student?: Student | null,
};

export enum DiscountUsage {
  ONE = "ONE",
  MULTI = "MULTI",
}


export type Segment = {
  __typename: "Segment",
  id: string,
  createdAt: string,
  updatedAt: string,
  segmantName: string,
  createdPinpointSegmentId: string,
};

export type Topic = {
  __typename: "Topic",
  id: string,
  createdAt: string,
  updatedAt: string,
  topicName: string,
  topicArn: string,
};

export type Syllable = {
  __typename: "Syllable",
  id: string,
  createdAt: string,
  updatedAt: string,
  letters?: string | null,
  correctSyllable?: Asset | null,
};

export type ReviewQuestion = {
  __typename: "ReviewQuestion",
  id: string,
  createdAt: string,
  updatedAt: string,
  question_i18n: string,
  type: string,
  reviewType: ReviewType,
};

export enum ReviewType {
  STUDENT_TO_INSTRUCTOR = "STUDENT_TO_INSTRUCTOR",
  INSTRUCTOR_TO_STUDENT = "INSTRUCTOR_TO_STUDENT",
}


export type Review = {
  __typename: "Review",
  id: string,
  createdAt: string,
  updatedAt: string,
  reviewType: ReviewType,
  instructor: Instructor,
  student: Student,
  session?: Session | null,
  feedbackquestions?:  Array<FeedbackQuestion | null > | null,
  comments?: string | null,
  averageRating?: number | null,
};

export type FeedbackQuestion = {
  __typename: "FeedbackQuestion",
  id: string,
  createdAt: string,
  updatedAt: string,
  review: Review,
  reviewQuestion: ReviewQuestion,
  stars: number,
};

export type Plan = {
  __typename: "Plan",
  id: string,
  createdAt: string,
  updatedAt: string,
  course?: Course | null,
  d1?:  Array<Test | null > | null,
  d2?:  Array<Test | null > | null,
  d3?:  Array<Test | null > | null,
  d4?:  Array<Test | null > | null,
  d5?:  Array<Test | null > | null,
  d6?:  Array<Test | null > | null,
  d7?:  Array<Test | null > | null,
  d8?:  Array<Test | null > | null,
  d9?:  Array<Test | null > | null,
  d10?:  Array<Test | null > | null,
  d11?:  Array<Test | null > | null,
  d12?:  Array<Test | null > | null,
  d13?:  Array<Test | null > | null,
  d14?:  Array<Test | null > | null,
  d15?:  Array<Test | null > | null,
  d16?:  Array<Test | null > | null,
  d17?:  Array<Test | null > | null,
  d18?:  Array<Test | null > | null,
  d19?:  Array<Test | null > | null,
  d20?:  Array<Test | null > | null,
  d21?:  Array<Test | null > | null,
  d22?:  Array<Test | null > | null,
  d23?:  Array<Test | null > | null,
  d24?:  Array<Test | null > | null,
  d25?:  Array<Test | null > | null,
  d26?:  Array<Test | null > | null,
  d27?:  Array<Test | null > | null,
  d28?:  Array<Test | null > | null,
  d29?:  Array<Test | null > | null,
  d30?:  Array<Test | null > | null,
  d31?:  Array<Test | null > | null,
};

export type PotentialUser = {
  __typename: "PotentialUser",
  id: string,
  createdAt: string,
  updatedAt: string,
  name?: string | null,
  phone?: string | null,
  email?: string | null,
  country?: string | null,
};

export type StudentaisessionInsert = {
  levelId: string,
  videosessionId: string,
  curriculumId: string,
  status: string,
  membershipId: string,
  studentId: string,
  currentQuestionIndex: number,
};

export type StudentaisessionUpdate = {
  levelId?: string | null,
  videosessionId?: string | null,
  status?: string | null,
  curriculumId?: string | null,
  currentQuestionIndex?: number | null,
};

export type VideosessionInsert = {
  levelId: string,
  courseId: string,
  testId: string,
  curriculumId: string,
  duration: number,
  name: string,
  isActive: boolean,
};

export type VideosessionUpdate = {
  levelId?: string | null,
  courseId?: string | null,
  testId?: string | null,
  curriculumId?: string | null,
  duration?: number | null,
  name?: string | null,
  isActive?: boolean | null,
};

export type SegmentInsert = {
  startDateTime?: string | null,
  endDateTime?: string | null,
  typeLogic: string,
  segmentName: string,
};

export type SegmentUpdate = {
  segmentName?: string | null,
};

export type CampaignInsert = {
  startDateTime: string,
  endDateTime: string,
  segmentId: string,
  campaignName: string,
  campaignTitle: string,
  campaignMessage: string,
  timeZone: string,
  frequency: string,
};

export type CurriculumInsert = {
  name: string,
  totalPlanPeriod: number,
};

export type CurriculumUpdate = {
  name?: string | null,
  totalPlanPeriod: number,
};

export type TopicInsert = {
  topicName: string,
  topicArn: string,
};

export type TopicUpdate = {
  topicName?: string | null,
  topicArn?: string | null,
};

export type MonthlyReportInsert = {
  statisticId?: string | null,
  attempts?: number | null,
  mistakes?: number | null,
  average?: number | null,
  month?: string | null,
  d1?: number | null,
  d2?: number | null,
  d3?: number | null,
  d4?: number | null,
  d5?: number | null,
  d6?: number | null,
  d7?: number | null,
  d8?: number | null,
  d9?: number | null,
  d10?: number | null,
  d11?: number | null,
  d12?: number | null,
  d13?: number | null,
  d14?: number | null,
  d15?: number | null,
  d16?: number | null,
  d17?: number | null,
  d18?: number | null,
  d19?: number | null,
  d20?: number | null,
  d21?: number | null,
  d22?: number | null,
  d23?: number | null,
  d24?: number | null,
  d25?: number | null,
  d26?: number | null,
  d27?: number | null,
  d28?: number | null,
  d29?: number | null,
  d30?: number | null,
  d31?: number | null,
};

export type MonthlyReportUpdate = {
  attempts?: number | null,
  mistakes?: number | null,
  average?: number | null,
  d1?: number | null,
  d2?: number | null,
  d3?: number | null,
  d4?: number | null,
  d5?: number | null,
  d6?: number | null,
  d7?: number | null,
  d8?: number | null,
  d9?: number | null,
  d10?: number | null,
  d11?: number | null,
  d12?: number | null,
  d13?: number | null,
  d14?: number | null,
  d15?: number | null,
  d16?: number | null,
  d17?: number | null,
  d18?: number | null,
  d19?: number | null,
  d20?: number | null,
  d21?: number | null,
  d22?: number | null,
  d23?: number | null,
  d24?: number | null,
  d25?: number | null,
  d26?: number | null,
  d27?: number | null,
  d28?: number | null,
  d29?: number | null,
  d30?: number | null,
  d31?: number | null,
};

export type AudioFileInsert = {
  fileName?: string | null,
  typeUpload?: string | null,
  ACL?: string | null,
};

export type ReviewquestionInsert = {
  question_i18n: string,
  reviewType: ReviewType,
};

export type ReviewquestionUpdate = {
  question_i18n?: string | null,
  reviewType?: ReviewType | null,
};

export type ClasseraSchoolInsert = {
  email: string,
  password: string,
  schoolId: string,
  name: string,
  country: string,
  timeZone: string,
  teachers: Array< TeacherPartnerInsert >,
};

export type TeacherPartnerInsert = {
  id: string,
  name: string,
  userName: string,
  phoneNumber?: string | null,
  email?: string | null,
  nationalId?: string | null,
  classesschool: Array< ClassPartnerInsert >,
};

export type ClassPartnerInsert = {
  id: string,
  name: string,
  schoolYear?: string | null,
  levelId?: string | null,
  students: Array< StudentPartnerInsert >,
};

export type StudentPartnerInsert = {
  id: string,
  userName: string,
  email?: string | null,
  name: string,
  nationalId?: string | null,
  phoneNumber?: string | null,
  birthDate?: string | null,
  age?: number | null,
  gender: Gender,
  languageId?: string | null,
  notes?: string | null,
  facebookProfileUrl?: string | null,
  schoolName?: string | null,
  status?: Student_Status | null,
  assignedTo?: string | null,
  targetAge?: number | null,
  schoolId?: string | null,
  isSchoolVerfied: boolean,
  levelId?: string | null,
};

export type ResponseTemplate = {
  __typename: "ResponseTemplate",
  message: string,
  status: boolean,
  statusCode: number,
  error?: string | null,
};

export type PotentialUsersInsert = {
  users: Array< PotentialUserInsert | null >,
};

export type PotentialUserInsert = {
  email?: string | null,
  phone?: string | null,
  name?: string | null,
  country?: string | null,
};

export type ValidatePartnerInsert = {
  partnerAppName: string,
  partnerId: string,
};

export type AuthenticatorResponse = {
  __typename: "AuthenticatorResponse",
  token?: string | null,
  expirationDate?: string | null,
};

export type DiscountCodeInsert = {
  numOfCodes: number,
  valuePercentage: number,
  packageId: string,
  validFrom: string,
  expirationDate: string,
  usage: DiscountUsage,
};

export type TeacherInsert = {
  email?: string | null,
  nationalId?: string | null,
};

export type TeacherUpdate = {
  email?: string | null,
  nationalId?: string | null,
};

export type ClassschoolInsert = {
  name: string,
  schoolId: string,
  levelId?: string | null,
  schoolYear?: string | null,
  teacherId?: string | null,
};

export type ClassschoolUpdate = {
  name?: string | null,
  schoolYear?: string | null,
  teacherId?: string | null,
  levelId?: string | null,
};

export type AdminInsert = {
  userId: string,
  firstName: string,
  lastName: string,
  address: string,
  SSN?: number | null,
  gender: Gender,
  isActive: boolean,
};

export type AdminUpdate = {
  firstName?: string | null,
  lastName?: string | null,
  address?: string | null,
  SSN?: number | null,
  gender?: Gender | null,
  isActive?: boolean | null,
  lastSeenOnline?: string | null,
};

export type SyllablesConnection = {
  __typename: "SyllablesConnection",
  items:  Array<Syllable >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type Connection = {
  __typename: "Connection",
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type StudentaisessionsConnection = {
  __typename: "StudentaisessionsConnection",
  items:  Array<Studentaisession >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type VideosessionsConnection = {
  __typename: "VideosessionsConnection",
  items:  Array<Videosession >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SegmentConnection = {
  __typename: "SegmentConnection",
  items:  Array<Segment >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type CurriculumsConnection = {
  __typename: "CurriculumsConnection",
  items:  Array<Curriculum >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type TopicConnection = {
  __typename: "TopicConnection",
  items:  Array<Topic >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type TeachersConnection = {
  __typename: "TeachersConnection",
  items:  Array<Teacher >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ClassesConnection = {
  __typename: "ClassesConnection",
  items:  Array<Classschool >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ReviewquestionsConnection = {
  __typename: "ReviewquestionsConnection",
  items:  Array<ReviewQuestion >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type AvailableDaysSlotsConnection = {
  __typename: "AvailableDaysSlotsConnection",
  items:  Array<AvailableDaysSlots >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type AvailableDaysSlots = {
  __typename: "AvailableDaysSlots",
  dayIndex: number,
  slots:  Array<Slot | null >,
};

export type DiscountcodeConnection = {
  __typename: "DiscountcodeConnection",
  items:  Array<Discountcode >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PromocodeConnection = {
  __typename: "PromocodeConnection",
  items:  Array<Promocode >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type AdminConnection = {
  __typename: "AdminConnection",
  items:  Array<Admin >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type StudentconversationConnection = {
  __typename: "StudentconversationConnection",
  items:  Array<Studentconversation >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type UserconversationConnection = {
  __typename: "UserconversationConnection",
  items:  Array<Userconversation >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ConversationConnection = {
  __typename: "ConversationConnection",
  items:  Array<Conversation >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ReviewConnection = {
  __typename: "ReviewConnection",
  items:  Array<Review >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type AvailabilitiesConnection = {
  __typename: "AvailabilitiesConnection",
  items:  Array<Availability >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type CoursesConnection = {
  __typename: "CoursesConnection",
  items:  Array<Course >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type EnrollmentsConnection = {
  __typename: "EnrollmentsConnection",
  items:  Array<Enrollment >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type GroupsConnection = {
  __typename: "GroupsConnection",
  items:  Array<Group >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type InstructorsConnection = {
  __typename: "InstructorsConnection",
  items:  Array<Instructor >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PreInstructorsConnection = {
  __typename: "PreInstructorsConnection",
  items:  Array<PreInstructor >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type LanguagesConnection = {
  __typename: "LanguagesConnection",
  items:  Array<Language >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type LessonsConnection = {
  __typename: "LessonsConnection",
  items:  Array<Lesson >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type LevelsConnection = {
  __typename: "LevelsConnection",
  items:  Array<Level >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type MembershipsConnection = {
  __typename: "MembershipsConnection",
  items:  Array<Membership >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ParentsConnection = {
  __typename: "ParentsConnection",
  items:  Array<Parent >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PackagesConnection = {
  __typename: "PackagesConnection",
  items:  Array<Package >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PricesConnection = {
  __typename: "PricesConnection",
  items:  Array<Price >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ProgramsConnection = {
  __typename: "ProgramsConnection",
  items:  Array<Program >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type QuestionsConnection = {
  __typename: "QuestionsConnection",
  items:  Array<Question >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SchoolsConnection = {
  __typename: "SchoolsConnection",
  items:  Array<School >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SessionsConnection = {
  __typename: "SessionsConnection",
  items:  Array<Session >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SlotsConnection = {
  __typename: "SlotsConnection",
  items:  Array<Slot >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type StudentsConnection = {
  __typename: "StudentsConnection",
  items:  Array<Student >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type StatisticsConnection = {
  __typename: "StatisticsConnection",
  items:  Array<Statistic >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type MonthlyReportsConnection = {
  __typename: "MonthlyReportsConnection",
  items:  Array<MonthlyReport >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type TestsConnection = {
  __typename: "TestsConnection",
  items:  Array<Test >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type UsersConnection = {
  __typename: "UsersConnection",
  items:  Array<User >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type AssetsConnection = {
  __typename: "AssetsConnection",
  items:  Array<Asset >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PlansConnection = {
  __typename: "PlansConnection",
  items:  Array<Plan >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type FeedbackquestionConnections = {
  __typename: "FeedbackquestionConnections",
  items:  Array<FeedbackQuestion >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SyllableInsert = {
  letters?: string | null,
  correctSyllableId?: string | null,
};

export type SyllableUpdate = {
  letters?: string | null,
  correctSyllableId?: string | null,
};

export type StudentconversationInsert = {
  conversationId: string,
  studentId: string,
};

export type StudentconversationUpdate = {
  conversationId: string,
  studentId: string,
};

export type UserconversationInsert = {
  conversationId: string,
  userId: string,
};

export type UserconversationUpdate = {
  conversationId: string,
  userId?: string | null,
};

export type ConversationInsert = {
  name: string,
  isActive: boolean,
};

export type ConversationUpdate = {
  name?: string | null,
  isActive?: boolean | null,
};

export type MessageInsert = {
  conversationId: string,
  content: string,
  senderType: string,
  studentId?: string | null,
  userId?: string | null,
};

export type MessageUpdate = {
  content?: string | null,
};

export type ReviewInsert = {
  studentId: string,
  instructorId: string,
  reviewType: ReviewType,
  comments?: string | null,
  sessionId?: string | null,
  feedbackquestions: Array< FeedbackQuestionInsert | null >,
};

export type FeedbackQuestionInsert = {
  reviewquestionId: string,
  stars: number,
};

export type ReviewUpdate = {
  comments?: string | null,
};

export type AnswerInsert = {
  studentId: string,
  questionId: string,
  enrollmentId?: string | null,
  monthlyReportId?: string | null,
  choice?: number | null,
  audioUri?: string | null,
  text?: string | null,
  useEnglishAi?: boolean | null,
};

export type AnswerUpdate = {
  monthlyReportId?: string | null,
  choice?: number | null,
  audioUri?: string | null,
  text?: string | null,
  score?: number | null,
  analysis?: string | null,
  useEnglishAi?: boolean | null,
};

export type AvailabilityInsert = {
  instructorId: string,
  startTime: string,
  endTime: string,
  day: number,
};

export type AvailabilityUpdate = {
  startTime?: string | null,
  endTime?: string | null,
  day?: number | null,
};

export type CourseInsert = {
  name?: string | null,
  levelId?: string | null,
  languageId?: string | null,
  planId?: string | null,
  programId?: string | null,
  curriculumId?: string | null,
};

export type CourseUpdate = {
  name?: string | null,
  levelId?: string | null,
  planId?: string | null,
  curriculumId?: string | null,
};

export type EnrollmentInsert = {
  studentId: string,
  packageId: string,
  levelId?: string | null,
  testId: string,
  placementStatus: Status,
  paymentStatus: Status,
  startDate?: string | null,
  endDate?: string | null,
  selfRating?: number | null,
  curriculumId?: TableReferenceFilter | null,
};

export type TableReferenceFilter = {
  ne?: string | null,
  eq?: string | null,
};

export type EnrollmentUpdate = {
  packageId?: string | null,
  levelId?: string | null,
  testId?: string | null,
  placementStatus?: Status | null,
  paymentStatus?: Status | null,
  startDate?: string | null,
  endDate?: string | null,
  selfRating?: number | null,
  curriculumId?: TableReferenceFilter | null,
};

export type GroupInsert = {
  name?: string | null,
  limit?: number | null,
  levelId: string,
  division: DivisionType,
  instructorId: string,
  membershipIds: Array< string >,
  programId: string,
  slotId: string,
};

export type GroupUpdate = {
  name?: string | null,
  limit?: number | null,
  levelId?: string | null,
  division?: DivisionType | null,
  instructorId?: string | null,
  membershipIds?: Array< string | null > | null,
  slotId?: string | null,
  isGroupHasInstructor?: boolean | null,
};

export type InstructorInsert = {
  userId: string,
  name: string,
  programId: string,
  gender?: Gender | null,
  promoId?: string | null,
  rating?: number | null,
  sessionsCount?: number | null,
  speakingLanguages?: Array< string | null > | null,
  isNative?: boolean | null,
  isActive?: boolean | null,
  status?: InstructorStatus | null,
  birthDate?: string | null,
  paymentMethod?: string | null,
};

export type InstructorUpdate = {
  userId?: string | null,
  name?: string | null,
  gender?: Gender | null,
  programId?: string | null,
  paymentMethod?: string | null,
  promoId?: string | null,
  rating?: number | null,
  sessionsCount?: number | null,
  speakingLanguages?: Array< string | null > | null,
  isNative?: boolean | null,
  isActive?: boolean | null,
  status?: InstructorStatus | null,
  birthDate?: string | null,
};

export type PreInstructorInsert = {
  userId: string,
  name: string,
  programId: string,
  gender?: Gender | null,
  promoId?: string | null,
  rating?: number | null,
  sessionsCount?: number | null,
  speakingLanguages?: Array< string | null > | null,
  isNative?: boolean | null,
  isActive?: boolean | null,
  status?: InstructorStatus | null,
  birthDate?: string | null,
};

export type PreInstructorUpdate = {
  userId?: string | null,
  name?: string | null,
  gender?: Gender | null,
  programId?: string | null,
  promoId?: string | null,
  rating?: number | null,
  sessionsCount?: number | null,
  speakingLanguages?: Array< string | null > | null,
  isNative?: boolean | null,
  isActive?: boolean | null,
  status?: InstructorStatus | null,
  birthDate?: string | null,
};

export type LanguageInsert = {
  language: string,
  country: string,
};

export type LanguageUpdate = {
  language?: string | null,
  country?: string | null,
};

export type LessonInsert = {
  name: string,
  courseId?: string | null,
  summaryId?: string | null,
  lessonPDFId?: string | null,
  instructionsPDFId?: string | null,
};

export type LessonUpdate = {
  name?: string | null,
  summaryId?: string | null,
  lessonPDFId?: string | null,
  courseId?: string | null,
  instructionsPDFId?: string | null,
};

export type LevelInsert = {
  name: string,
  number?: number | null,
  score?: number | null,
};

export type LevelUpdate = {
  name?: string | null,
  number?: number | null,
  score?: number | null,
};

export type AssetInsert = {
  type?: AssetType | null,
  thumbnailUri?: string | null,
  fileUri?: string | null,
  title_i18n?: string | null,
  subTitle_i18n?: string | null,
  position?: number | null,
};

export type AssetUpdate = {
  type?: AssetType | null,
  thumbnailUri?: string | null,
  fileUri?: string | null,
  title_i18n?: string | null,
  subTitle_i18n?: string | null,
  position?: number | null,
};

export type MembershipInsert = {
  studentId: string,
  packageId: string,
  paymentStatus: Status,
  isActive: boolean,
  programId?: string | null,
  sessionPerWeek?: number | null,
  discountCodeId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type MembershipUpdate = {
  paymentStatus?: Status | null,
  packageId?: string | null,
  programId?: string | null,
  isActive?: boolean | null,
  sessionPerWeek?: number | null,
  isFullFilled?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type PackageInsert = {
  programId: string,
  name?: string | null,
  name_i18n?: string | null,
  sessionDuration?: number | null,
  description?: string | null,
  onlyAI: boolean,
  benefits?: string | null,
  benefits_i18n: string,
  promoAssetId: string,
  coverAssetId?: string | null,
  number?: number | null,
  details?: string | null,
  details_i18n?: string | null,
  activityIds?: Array< string | null > | null,
  recordIds?: Array< string | null > | null,
  isFree?: boolean | null,
  numberOfSessions: number,
  description_i18n?: string | null,
  isNative?: boolean | null,
  isActive?: boolean | null,
  isHidden?: boolean | null,
  enrollmentPackageId?: string | null,
  productIAP?: string | null,
  typePackage?: DivisionType | null,
  packageDuration: number,
  discountDescription?: string | null,
  isRecommended: boolean,
  sessionPerWeek: Array< number | null >,
};

export type PackageUpdate = {
  programId?: string | null,
  name?: string | null,
  name_i18n?: string | null,
  sessionDuration?: number | null,
  description?: string | null,
  onlyAI?: boolean | null,
  benefits?: string | null,
  benefits_i18n?: string | null,
  promoAssetId?: string | null,
  coverAssetId?: string | null,
  number?: number | null,
  details?: string | null,
  details_i18n?: string | null,
  activityIds?: Array< string | null > | null,
  recordIds?: Array< string | null > | null,
  isFree?: boolean | null,
  numberOfSessions?: number | null,
  description_i18n?: string | null,
  isNative?: boolean | null,
  isActive?: boolean | null,
  isHidden?: boolean | null,
  enrollmentPackageId?: string | null,
  productIAP?: string | null,
  typePackage?: DivisionType | null,
  packageDuration?: number | null,
  discountDescription?: string | null,
  isRecommended?: boolean | null,
  sessionPerWeek?: Array< number | null > | null,
};

export type PlanInsert = {
  curriculumId: string,
};

export type PlanUpdate = {
  newPlan: string,
};

export type ParentInsert = {
  userId: string,
  name: string,
  gender: Gender,
};

export type ParentUpdate = {
  userId?: string | null,
  name?: string | null,
  gender?: Gender | null,
};

export type PriceInsert = {
  packageId?: string | null,
  name: string,
  price: number,
  currency: string,
  country: string,
};

export type PriceUpdate = {
  name?: string | null,
  price?: number | null,
  currency?: string | null,
  country?: string | null,
};

export type ProgramInsert = {
  name?: string | null,
  testId?: string | null,
  name_i18n?: string | null,
  activityIds?: Array< string | null > | null,
  recordIds?: Array< string | null > | null,
  flag?: string | null,
  supportAi?: boolean | null,
  isActive?: boolean | null,
  instructorIds?: Array< string | null > | null,
};

export type ProgramUpdate = {
  name?: string | null,
  testId?: string | null,
  name_i18n?: string | null,
  activityIds?: Array< string | null > | null,
  recordIds?: Array< string | null > | null,
  flag?: string | null,
  supportAi?: boolean | null,
  isActive?: boolean | null,
  instructorIds?: Array< string | null > | null,
};

export type QuestionInsert = {
  name?: string | null,
  name_i18n?: string | null,
  summary?: string | null,
  summary_i18n?: string | null,
  description?: string | null,
  description_i18n?: string | null,
  text: string,
  correctAssetId?: string | null,
  assetId?: string | null,
  choices: Array< string >,
  programType: ProgramType,
  keywords: Array< string >,
  textAI?: string | null,
  type?: QUESTION_TYPE | null,
  videoAISessionUri?: string | null,
};

export type QuestionUpdate = {
  summary?: string | null,
  summary_i18n?: string | null,
  description?: string | null,
  description_i18n?: string | null,
  text?: string | null,
  correctAssetId?: string | null,
  assetId?: string | null,
  choices?: Array< string | null > | null,
  keywords?: Array< string | null > | null,
  textAI?: string | null,
  name?: string | null,
  type?: QUESTION_TYPE | null,
  videoAISessionUri?: string | null,
};

export type SchoolInsert = {
  userId: string,
  nameArabic: string,
  name: string,
  isActive: boolean,
  promoId?: string | null,
};

export type SchoolUpdate = {
  nameArabic?: string | null,
  name?: string | null,
  isActive?: boolean | null,
};

export type SessionInsert = {
  name: string,
  slotId: string,
  startDateTime: string,
  duration: number,
  endDateTime: string,
  status: Status,
  instructorId?: string | null,
  lessonId?: string | null,
  groupId?: string | null,
  membershipIds?: Array< string | null > | null,
};

export type SessionUpdate = {
  slotId?: string | null,
  status?: Status | null,
  instructorId?: string | null,
  membershipIds?: Array< string | null > | null,
};

export type SlotInsert = {
  name?: string | null,
  availabilityId: string,
  programId: string,
  levelId: string,
  startTime: string,
  endTime: string,
  status: Status,
  limit: number,
  current: number,
  day: number,
};

export type SlotUpdate = {
  status?: Status | null,
  limit?: number | null,
  current?: number | null,
  day?: number | null,
};

export type StudentInsert = {
  userId: string,
  name: string,
  birthDate?: string | null,
  age: number,
  gender: Gender,
  languageId?: string | null,
  notes?: string | null,
  facebookProfileUrl?: string | null,
  schoolName?: string | null,
  status?: Student_Status | null,
  assignedTo?: string | null,
  targetAge?: number | null,
  schoolId?: string | null,
  isSchoolVerfied?: boolean | null,
  classschoolId?: string | null,
};

export type StudentUpdate = {
  name?: string | null,
  age?: number | null,
  gender?: Gender | null,
  birthDate?: string | null,
  languageId?: string | null,
  notes?: string | null,
  facebookProfileUrl?: string | null,
  schoolName?: string | null,
  status?: Student_Status | null,
  assignedTo?: string | null,
  targetAge?: number | null,
  schoolId?: string | null,
  isSchoolVerfied?: boolean | null,
  classschoolId?: string | null,
};

export type TestInsert = {
  questionIds: Array< string >,
  type: TestTypes,
  name?: string | null,
  assetId?: string | null,
  description?: string | null,
  summary?: string | null,
  name_i18n?: string | null,
  description_i18n?: string | null,
  summary_i18n?: string | null,
  lessonId?: string | null,
};

export type TestUpdate = {
  questionIds?: Array< string | null > | null,
  type?: TestTypes | null,
  name?: string | null,
  assetId?: string | null,
  description?: string | null,
  summary?: string | null,
  name_i18n?: string | null,
  description_i18n?: string | null,
  summary_i18n?: string | null,
};

export type UserInsert = {
  cognitoUserId: string,
  country?: string | null,
  state?: string | null,
  timeZone?: string | null,
  whatsappNumber?: string | null,
  deviceToken?: string | null,
  devicePlatform?: string | null,
  firstName?: string | null,
  lastName?: string | null,
};

export type UserUpdate = {
  cognitoUserId?: string | null,
  country?: string | null,
  state?: string | null,
  timeZone?: string | null,
  whatsappNumber?: string | null,
  deviceToken?: string | null,
  devicePlatform?: string | null,
  firstName?: string | null,
  lastName?: string | null,
};

export type StudentaisessionsFilter = {
  levelId?: TableReferenceFilter | null,
  curriculumId?: TableReferenceFilter | null,
  videosessionId?: TableReferenceFilter | null,
  status?: TableStringFilter | null,
};

export type TableStringFilter = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type VideosessionsFilter = {
  levelId?: TableReferenceFilter | null,
  curriculumId?: TableReferenceFilter | null,
};

export type SegmentFilter = {
  segmentName?: TableStringFilter | null,
};

export type CurriculumsFilter = {
  name?: TableStringFilter | null,
};

export type TopicFilter = {
  topicName?: TableStringFilter | null,
};

export type SyllablesFilter = {
  letters?: TableStringFilter | null,
};

export type TeachersFilter = {
  name?: TableStringFilter | null,
};

export type ClassesFilter = {
  name?: TableStringFilter | null,
};

export type AdminListFilter = {
  id?: TableStringFilter | null,
};

export type AdminListUser = {
  __typename: "AdminListUser",
  Users?:  Array<AdminListUsersResponse | null > | null,
  PaginationToken?: string | null,
};

export type AdminListUsersResponse = {
  __typename: "AdminListUsersResponse",
  Username: string,
  Attributes?:  Array<AttributeType | null > | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
};

export type AttributeType = {
  __typename: "AttributeType",
  Name?: string | null,
  Value?: string | null,
};

export type AdminListUserGroups = {
  __typename: "AdminListUserGroups",
  Groups?:  Array<GroupType | null > | null,
  NextToken?: string | null,
};

export type GroupType = {
  __typename: "GroupType",
  GroupName?: string | null,
  Description?: string | null,
  UserPoolId?: string | null,
};

export type AdminGetUserResponse = {
  __typename: "AdminGetUserResponse",
  Username: string,
  UserAttributes?:  Array<AttributeType | null > | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
};

export type ReviewquestionFilter = {
  question_i18n?: TableStringFilter | null,
  reviewType?: ReviewType | null,
};

export type DiscountcodeFilter = {
  codeName?: TableStringFilter | null,
};

export type PromocodeFilter = {
  codeName?: TableStringFilter | null,
};

export type AdminFilter = {
  id?: TableStringFilter | null,
};

export type StudentconversationFilter = {
  conversationId?: TableStringFilter | null,
  studentId?: TableStringFilter | null,
};

export type UserconversationFilter = {
  conversationId?: TableStringFilter | null,
  userId?: TableStringFilter | null,
};

export type MessageConnection = {
  __typename: "MessageConnection",
  items?:  Array<Message | null > | null,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ConversationFilter = {
  name?: TableStringFilter | null,
};

export type MessageFilter = {
  content?: TableStringFilter | null,
};

export type ReviewFilter = {
  studentId?: TableReferenceFilter | null,
  instructorId?: TableStringFilter | null,
  reviewType?: ReviewType | null,
  sessionId?: TableStringFilter | null,
};

export type AvailabilitiesFilter = {
  instructorId?: TableReferenceFilter | null,
  startTime?: TableStringFilter | null,
  endTime?: TableStringFilter | null,
  day?: TableIntFilter | null,
};

export type TableIntFilter = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  contains?: number | null,
  notContains?: number | null,
  between?: Array< number | null > | null,
};

export type CoursesFilter = {
  name?: TableStringFilter | null,
  levelId?: TableReferenceFilter | null,
  languageId?: TableReferenceFilter | null,
  planId?: TableReferenceFilter | null,
  curriculumId?: TableReferenceFilter | null,
};

export type EnrollmentsFilter = {
  studentId?: TableReferenceFilter | null,
  packageId?: TableReferenceFilter | null,
  levelId?: TableReferenceFilter | null,
  curriculumId?: TableReferenceFilter | null,
};

export type GroupsFilter = {
  name?: TableStringFilter | null,
  division?: TableStringFilter | null,
  limit?: TableIntFilter | null,
  levelId?: TableReferenceFilter | null,
  programId?: TableStringFilter | null,
  status?: TableStringFilter | null,
  instructorId?: TableReferenceFilter | null,
  students?: TableIntFilter | null,
  Session?: TableReferenceFilter | null,
  isActive?: TableBooleanFilter | null,
  fromRangeDate?: TableStringFilter | null,
  ToRangeDate?: TableStringFilter | null,
};

export type TableBooleanFilter = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type InstructorsFilter = {
  name?: TableStringFilter | null,
  programId?: TableStringFilter | null,
  gender?: TableStringFilter | null,
  isActive?: TableBooleanFilter | null,
  status?: TableStringFilter | null,
  isNative?: TableBooleanFilter | null,
};

export type LanguagesFilter = {
  language?: TableStringFilter | null,
  country?: TableStringFilter | null,
};

export type LessonsFilter = {
  name?: TableStringFilter | null,
  number?: TableIntFilter | null,
};

export type LevelsFilter = {
  name?: TableStringFilter | null,
  number?: TableIntFilter | null,
};

export type MembershipsFilter = {
  studentId?: TableReferenceFilter | null,
  programId?: TableReferenceFilter | null,
};

export type ParentsFilter = {
  userId?: TableReferenceFilter | null,
  name?: TableStringFilter | null,
};

export type PackagesFilter = {
  programId?: TableReferenceFilter | null,
};

export type PricesFilter = {
  packageId?: TableReferenceFilter | null,
};

export type ProgramsFilter = {
  name?: TableStringFilter | null,
};

export type QuestionsFilter = {
  name?: TableStringFilter | null,
  programType?: TableStringFilter | null,
};

export type SchoolsFilter = {
  name?: TableStringFilter | null,
};

export type SessionsFilter = {
  slotId?: TableReferenceFilter | null,
  instructorId?: TableReferenceFilter | null,
  startDateTime?: TableStringFilter | null,
  endDateTime?: TableStringFilter | null,
  status?: TableStringFilter | null,
};

export type SlotsFilter = {
  name?: TableStringFilter | null,
  availabilityId?: TableReferenceFilter | null,
  startDateTime?: TableStringFilter | null,
  endDateTime?: TableStringFilter | null,
  status?: TableIntFilter | null,
  limit?: TableIntFilter | null,
  current?: TableIntFilter | null,
  day?: TableStringFilter | null,
};

export type StudentsFilter = {
  id?: TableStringFilter | null,
  name?: TableStringFilter | null,
  schoolId?: TableStringFilter | null,
  levelId?: TableStringFilter | null,
};

export type StatisticsFilter = {
  studentId?: TableReferenceFilter | null,
  averageScore?: TableIntFilter | null,
  classschoolId?: TableReferenceFilter | null,
};

export type MonthlyReportsFilter = {
  id?: TableStringFilter | null,
  name?: TableStringFilter | null,
  statisticId?: TableStringFilter | null,
};

export type TestsFilter = {
  userId?: TableReferenceFilter | null,
  name?: TableStringFilter | null,
  age?: TableIntFilter | null,
  language?: TableStringFilter | null,
};

export type UsersFilter = {
  cognitoUserId?: TableReferenceFilter | null,
  country?: TableStringFilter | null,
  state?: TableStringFilter | null,
  timeZone?: TableStringFilter | null,
};

export type ActivatePaidMembershipMutationVariables = {
  membershipId: string,
};

export type ActivatePaidMembershipMutation = {
  activatePaidMembership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    paymentStatus?: Status | null,
    placementStatus?: Status | null,
    isActive: boolean,
    startDate?: string | null,
    endDate?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    sessionPerWeek?: number | null,
    isFullFilled?: boolean | null,
    groups?:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null > | null,
    discountcode?:  {
      __typename: "Discountcode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      valuePercentage?: number | null,
      isExpired: boolean,
      validFrom: string,
      expirationDate: string,
      usage: DiscountUsage,
    } | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
  } | null,
};

export type CreateStudentaisessionMutationVariables = {
  input: StudentaisessionInsert,
};

export type CreateStudentaisessionMutation = {
  createStudentaisession?:  {
    __typename: "Studentaisession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    videosession:  {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    },
    membership:  {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    status: Status,
    currentQuestionIndex: number,
  } | null,
};

export type UpdateStudentaisessionMutationVariables = {
  id: string,
  updatedAt: string,
  input: StudentaisessionUpdate,
};

export type UpdateStudentaisessionMutation = {
  updateStudentaisession?:  {
    __typename: "Studentaisession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    videosession:  {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    },
    membership:  {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    status: Status,
    currentQuestionIndex: number,
  } | null,
};

export type DeleteStudentaisessionMutationVariables = {
  id: string,
};

export type DeleteStudentaisessionMutation = {
  deleteStudentaisession?:  {
    __typename: "Studentaisession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    videosession:  {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    },
    membership:  {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    status: Status,
    currentQuestionIndex: number,
  } | null,
};

export type CreateVideosessionMutationVariables = {
  input: VideosessionInsert,
};

export type CreateVideosessionMutation = {
  createVideosession?:  {
    __typename: "Videosession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    course:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    },
    test:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    },
    studentaisessions:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } >,
    duration: number,
    name: string,
    isActive: boolean,
  } | null,
};

export type UpdateVideosessionMutationVariables = {
  id: string,
  updatedAt: string,
  input: VideosessionUpdate,
};

export type UpdateVideosessionMutation = {
  updateVideosession?:  {
    __typename: "Videosession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    course:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    },
    test:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    },
    studentaisessions:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } >,
    duration: number,
    name: string,
    isActive: boolean,
  } | null,
};

export type DeleteVideosessionMutationVariables = {
  id: string,
};

export type DeleteVideosessionMutation = {
  deleteVideosession?:  {
    __typename: "Videosession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    course:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    },
    test:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    },
    studentaisessions:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } >,
    duration: number,
    name: string,
    isActive: boolean,
  } | null,
};

export type SendEmailsMutationVariables = {
  id?: string | null,
};

export type SendEmailsMutation = {
  sendEmails?: string | null,
};

export type CreateSegmentMutationVariables = {
  input: SegmentInsert,
};

export type CreateSegmentMutation = {
  createSegment?:  {
    __typename: "Segment",
    id: string,
    createdAt: string,
    updatedAt: string,
    segmantName: string,
    createdPinpointSegmentId: string,
  } | null,
};

export type UpdateSegmentMutationVariables = {
  id: string,
  updatedAt: string,
  input: SegmentUpdate,
};

export type UpdateSegmentMutation = {
  updateSegment?:  {
    __typename: "Segment",
    id: string,
    createdAt: string,
    updatedAt: string,
    segmantName: string,
    createdPinpointSegmentId: string,
  } | null,
};

export type DeleteSegmentMutationVariables = {
  id: string,
};

export type DeleteSegmentMutation = {
  deleteSegment?:  {
    __typename: "Segment",
    id: string,
    createdAt: string,
    updatedAt: string,
    segmantName: string,
    createdPinpointSegmentId: string,
  } | null,
};

export type CreateCampaignMutationVariables = {
  input: CampaignInsert,
};

export type CreateCampaignMutation = {
  createCampaign?: string | null,
};

export type GetRandomExerciseWeakSyllablesMutationVariables = {
  studentId?: string | null,
};

export type GetRandomExerciseWeakSyllablesMutation = {
  getRandomExerciseWeakSyllables?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    asset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    videoAISessionUri?: string | null,
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary?: string | null,
    summary_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    text: string,
    textAI?: string | null,
    type?: QUESTION_TYPE | null,
  } | null,
};

export type CreateCurriculumMutationVariables = {
  input: CurriculumInsert,
};

export type CreateCurriculumMutation = {
  // mhhr start
  createCurriculum?:  {
    __typename: "Curriculum",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    totalPlanPeriod: number,
  } | null,
};

export type UpdateCurriculumMutationVariables = {
  id: string,
  updatedAt: string,
  input: CurriculumUpdate,
};

export type UpdateCurriculumMutation = {
  updateCurriculum?:  {
    __typename: "Curriculum",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    totalPlanPeriod: number,
  } | null,
};

export type DeleteCurriculumMutationVariables = {
  id: string,
};

export type DeleteCurriculumMutation = {
  deleteCurriculum?:  {
    __typename: "Curriculum",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    totalPlanPeriod: number,
  } | null,
};

export type CreateTopicMutationVariables = {
  input: TopicInsert,
};

export type CreateTopicMutation = {
  createTopic?:  {
    __typename: "Topic",
    id: string,
    createdAt: string,
    updatedAt: string,
    topicName: string,
    topicArn: string,
  } | null,
};

export type UpdateTopicMutationVariables = {
  id: string,
  updatedAt: string,
  input: TopicUpdate,
};

export type UpdateTopicMutation = {
  updateTopic?:  {
    __typename: "Topic",
    id: string,
    createdAt: string,
    updatedAt: string,
    topicName: string,
    topicArn: string,
  } | null,
};

export type DeleteTopicMutationVariables = {
  id: string,
};

export type DeleteTopicMutation = {
  deleteTopic?:  {
    __typename: "Topic",
    id: string,
    createdAt: string,
    updatedAt: string,
    topicName: string,
    topicArn: string,
  } | null,
};

export type GenerateFullReportAsStringMutationVariables = {
  studentId?: string | null,
  statisticId?: string | null,
};

export type GenerateFullReportAsStringMutation = {
  generateFullReportAsString?: string | null,
};

export type GenerateClassFullReportMutationVariables = {
  classschoolId?: string | null,
};

export type GenerateClassFullReportMutation = {
  generateClassFullReport?: string | null,
};

export type FeedStatisticStagingDataMutationVariables = {
  deleteOldData?: boolean | null,
};

export type FeedStatisticStagingDataMutation = {
  feedStatisticStagingData?: string | null,
};

export type CreateMonthlyReportMutationVariables = {
  input: MonthlyReportInsert,
};

export type CreateMonthlyReportMutation = {
  createMonthlyReport?:  {
    __typename: "MonthlyReport",
    id: string,
    createdAt: string,
    updatedAt: string,
    month?: string | null,
    average?: number | null,
    mistakes?: number | null,
    attempts?: number | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    d1?: number | null,
    d2?: number | null,
    d3?: number | null,
    d4?: number | null,
    d5?: number | null,
    d6?: number | null,
    d7?: number | null,
    d8?: number | null,
    d9?: number | null,
    d10?: number | null,
    d11?: number | null,
    d12?: number | null,
    d13?: number | null,
    d14?: number | null,
    d15?: number | null,
    d16?: number | null,
    d17?: number | null,
    d18?: number | null,
    d19?: number | null,
    d20?: number | null,
    d21?: number | null,
    d22?: number | null,
    d23?: number | null,
    d24?: number | null,
    d25?: number | null,
    d26?: number | null,
    d27?: number | null,
    d28?: number | null,
    d29?: number | null,
    d30?: number | null,
    d31?: number | null,
  } | null,
};

export type UpdateMonthlyReportMutationVariables = {
  id: string,
  updatedAt: string,
  input: MonthlyReportUpdate,
};

export type UpdateMonthlyReportMutation = {
  updateMonthlyReport?:  {
    __typename: "MonthlyReport",
    id: string,
    createdAt: string,
    updatedAt: string,
    month?: string | null,
    average?: number | null,
    mistakes?: number | null,
    attempts?: number | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    d1?: number | null,
    d2?: number | null,
    d3?: number | null,
    d4?: number | null,
    d5?: number | null,
    d6?: number | null,
    d7?: number | null,
    d8?: number | null,
    d9?: number | null,
    d10?: number | null,
    d11?: number | null,
    d12?: number | null,
    d13?: number | null,
    d14?: number | null,
    d15?: number | null,
    d16?: number | null,
    d17?: number | null,
    d18?: number | null,
    d19?: number | null,
    d20?: number | null,
    d21?: number | null,
    d22?: number | null,
    d23?: number | null,
    d24?: number | null,
    d25?: number | null,
    d26?: number | null,
    d27?: number | null,
    d28?: number | null,
    d29?: number | null,
    d30?: number | null,
    d31?: number | null,
  } | null,
};

export type DeleteMonthlyReportMutationVariables = {
  id: string,
};

export type DeleteMonthlyReportMutation = {
  deleteMonthlyReport?:  {
    __typename: "MonthlyReport",
    id: string,
    createdAt: string,
    updatedAt: string,
    month?: string | null,
    average?: number | null,
    mistakes?: number | null,
    attempts?: number | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    d1?: number | null,
    d2?: number | null,
    d3?: number | null,
    d4?: number | null,
    d5?: number | null,
    d6?: number | null,
    d7?: number | null,
    d8?: number | null,
    d9?: number | null,
    d10?: number | null,
    d11?: number | null,
    d12?: number | null,
    d13?: number | null,
    d14?: number | null,
    d15?: number | null,
    d16?: number | null,
    d17?: number | null,
    d18?: number | null,
    d19?: number | null,
    d20?: number | null,
    d21?: number | null,
    d22?: number | null,
    d23?: number | null,
    d24?: number | null,
    d25?: number | null,
    d26?: number | null,
    d27?: number | null,
    d28?: number | null,
    d29?: number | null,
    d30?: number | null,
    d31?: number | null,
  } | null,
};

export type GenerateSignedUrlUploadAudioFileMutationVariables = {
  input: AudioFileInsert,
};

export type GenerateSignedUrlUploadAudioFileMutation = {
  // mhhr end
  generateSignedUrlUploadAudioFile?: string | null,
};

export type AddUserToGroupMutationVariables = {
  userName: string,
  groupName: string,
};

export type AddUserToGroupMutation = {
  addUserToGroup?: string | null,
};

export type RemoveUserFromGroupMutationVariables = {
  userName: string,
  groupName: string,
};

export type RemoveUserFromGroupMutation = {
  removeUserFromGroup?: string | null,
};

export type AddUserToPreInstructorsGroupMutationVariables = {
  userName: string,
};

export type AddUserToPreInstructorsGroupMutation = {
  addUserToPreInstructorsGroup?: string | null,
};

export type LevelUpGroupMutationVariables = {
  groupId?: string | null,
  levelId?: string | null,
};

export type LevelUpGroupMutation = {
  levelUpGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: DivisionType,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    isGroupHasInstructor?: boolean | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    slot?:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null,
    nextSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    perviousSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    currentSessionNumber?: number | null,
    conversation?:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    } | null,
    isActive: boolean,
  } | null,
};

export type CreateReviewquestionMutationVariables = {
  input: ReviewquestionInsert,
};

export type CreateReviewquestionMutation = {
  createReviewquestion?:  {
    __typename: "ReviewQuestion",
    id: string,
    createdAt: string,
    updatedAt: string,
    question_i18n: string,
    type: string,
    reviewType: ReviewType,
  } | null,
};

export type UpdateReviewquestionMutationVariables = {
  id: string,
  input: ReviewquestionUpdate,
};

export type UpdateReviewquestionMutation = {
  updateReviewquestion?:  {
    __typename: "ReviewQuestion",
    id: string,
    createdAt: string,
    updatedAt: string,
    question_i18n: string,
    type: string,
    reviewType: ReviewType,
  } | null,
};

export type DeleteReviewquestionMutationVariables = {
  id: string,
};

export type DeleteReviewquestionMutation = {
  deleteReviewquestion?:  {
    __typename: "ReviewQuestion",
    id: string,
    createdAt: string,
    updatedAt: string,
    question_i18n: string,
    type: string,
    reviewType: ReviewType,
  } | null,
};

export type CreatePromoCodesMutationVariables = {
  numOfCodes: number,
  packageId: string,
  sessionPerWeek: number,
  schoolId?: string | null,
  levelId?: string | null,
  curriculumId?: string | null,
};

export type CreatePromoCodesMutation = {
  createPromoCodes?: string | null,
};

export type ApplyPromoCodeMutationVariables = {
  codeName: string,
  studentId?: string | null,
};

export type ApplyPromoCodeMutation = {
  applyPromoCode?:  {
    __typename: "Promocode",
    id: string,
    createdAt: string,
    updatedAt: string,
    codeName: string,
    isApplied: boolean,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
    sessionPerWeek?: number | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type SyncClasseraStudentsMutationVariables = {
  input: ClasseraSchoolInsert,
};

export type SyncClasseraStudentsMutation = {
  syncClasseraStudents?:  {
    __typename: "ResponseTemplate",
    message: string,
    status: boolean,
    statusCode: number,
    error?: string | null,
  } | null,
};

export type SyncBitrixUsersMutationVariables = {
  input: PotentialUsersInsert,
};

export type SyncBitrixUsersMutation = {
  syncBitrixUsers?: string | null,
};

export type ValidatePartnerAuthUserMutationVariables = {
  input: ValidatePartnerInsert,
};

export type ValidatePartnerAuthUserMutation = {
  validatePartnerAuthUser?:  {
    __typename: "AuthenticatorResponse",
    token?: string | null,
    expirationDate?: string | null,
  } | null,
};

export type CreateDiscountCodesMutationVariables = {
  input: DiscountCodeInsert,
};

export type CreateDiscountCodesMutation = {
  createDiscountCodes?: string | null,
};

export type ApplyDiscountCodeMutationVariables = {
  codeName: string,
  studentId: string,
  packageId: string,
};

export type ApplyDiscountCodeMutation = {
  applyDiscountCode?: string | null,
};

export type CheckDiscountCodeValidationMutationVariables = {
  codeName: string,
  packageId: string,
};

export type CheckDiscountCodeValidationMutation = {
  checkDiscountCodeValidation?:  {
    __typename: "Discountcode",
    id: string,
    createdAt: string,
    updatedAt: string,
    codeName: string,
    valuePercentage?: number | null,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    isExpired: boolean,
    validFrom: string,
    expirationDate: string,
    usage: DiscountUsage,
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
  } | null,
};

export type CreateTeacherMutationVariables = {
  input: TeacherInsert,
};

export type CreateTeacherMutation = {
  createTeacher?:  {
    __typename: "Teacher",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    userName?: string | null,
    email?: string | null,
    classesschool:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
  } | null,
};

export type UpdateTeacherMutationVariables = {
  id: string,
  updatedAt: string,
  input: TeacherUpdate,
};

export type UpdateTeacherMutation = {
  updateTeacher?:  {
    __typename: "Teacher",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    userName?: string | null,
    email?: string | null,
    classesschool:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
  } | null,
};

export type DeleteTeacherMutationVariables = {
  id: string,
};

export type DeleteTeacherMutation = {
  deleteTeacher?:  {
    __typename: "Teacher",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    userName?: string | null,
    email?: string | null,
    classesschool:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
  } | null,
};

export type CreateClassschoolMutationVariables = {
  input: ClassschoolInsert,
};

export type CreateClassschoolMutation = {
  createClassschool?:  {
    __typename: "Classschool",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    schoolYear?: string | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type UpdateClassschoolMutationVariables = {
  id: string,
  updatedAt: string,
  input: ClassschoolUpdate,
};

export type UpdateClassschoolMutation = {
  updateClassschool?:  {
    __typename: "Classschool",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    schoolYear?: string | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type DeleteClassschoolMutationVariables = {
  id: string,
};

export type DeleteClassschoolMutation = {
  deleteClassschool?:  {
    __typename: "Classschool",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    schoolYear?: string | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type CreateAdminMutationVariables = {
  input: AdminInsert,
};

export type CreateAdminMutation = {
  createAdmin?:  {
    __typename: "Admin",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    lastSeenOnline?: string | null,
    firstName: string,
    lastName: string,
    address: string,
    SSN?: number | null,
    gender: Gender,
    isActive: boolean,
  } | null,
};

export type UpdateAdminMutationVariables = {
  id: string,
  updatedAt: string,
  input: AdminUpdate,
};

export type UpdateAdminMutation = {
  updateAdmin?:  {
    __typename: "Admin",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    lastSeenOnline?: string | null,
    firstName: string,
    lastName: string,
    address: string,
    SSN?: number | null,
    gender: Gender,
    isActive: boolean,
  } | null,
};

export type CreateSNSEndPointMutationVariables = {
  deviceToken?: string | null,
  userId?: string | null,
};

export type CreateSNSEndPointMutation = {
  createSNSEndPoint?: string | null,
};

export type GetSyllablesByLettersMutationVariables = {
  lettersList?: Array< string | null > | null,
};

export type GetSyllablesByLettersMutation = {
  getSyllablesByLetters?:  {
    __typename: "SyllablesConnection",
    items:  Array< {
      __typename: "Syllable",
      id: string,
      createdAt: string,
      updatedAt: string,
      letters?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type CreateSyllableMutationVariables = {
  input: SyllableInsert,
};

export type CreateSyllableMutation = {
  createSyllable?:  {
    __typename: "Syllable",
    id: string,
    createdAt: string,
    updatedAt: string,
    letters?: string | null,
    correctSyllable?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type UpdateSyllableMutationVariables = {
  id: string,
  updatedAt: string,
  input: SyllableUpdate,
};

export type UpdateSyllableMutation = {
  updateSyllable?:  {
    __typename: "Syllable",
    id: string,
    createdAt: string,
    updatedAt: string,
    letters?: string | null,
    correctSyllable?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type DeleteSyllableMutationVariables = {
  id: string,
};

export type DeleteSyllableMutation = {
  deleteSyllable?:  {
    __typename: "Syllable",
    id: string,
    createdAt: string,
    updatedAt: string,
    letters?: string | null,
    correctSyllable?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type CreateSNSEndPointInstructorMutationVariables = {
  deviceToken?: string | null,
  userId?: string | null,
};

export type CreateSNSEndPointInstructorMutation = {
  createSNSEndPointInstructor?: string | null,
};

export type UpdateDeviceTokenMutationVariables = {
  userId?: string | null,
  newDeviceToken?: string | null,
};

export type UpdateDeviceTokenMutation = {
  updateDeviceToken?: string | null,
};

export type PublishSNSMessageInTopicMutationVariables = {
  title: string,
  body: string,
  topicArn: string,
};

export type PublishSNSMessageInTopicMutation = {
  publishSNSMessageInTopic?: string | null,
};

export type SendMessageMutationVariables = {
  conversationId?: string | null,
  title?: string | null,
  body?: string | null,
  targetArn?: string | null,
};

export type SendMessageMutation = {
  sendMessage?: string | null,
};

export type PublishSMSMessageMutationVariables = {
  message?: string | null,
  phoneNumber?: string | null,
};

export type PublishSMSMessageMutation = {
  publishSMSMessage?: string | null,
};

export type StartSessionMutationVariables = {
  id: string,
};

export type StartSessionMutation = {
  startSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type EndSessionMutationVariables = {
  id: string,
  status: Status,
};

export type EndSessionMutation = {
  endSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type RescheduleSessionMutationVariables = {
  id: string,
  startDateTime: string,
};

export type RescheduleSessionMutation = {
  rescheduleSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type CreateStudentconversationMutationVariables = {
  input: StudentconversationInsert,
};

export type CreateStudentconversationMutation = {
  createStudentconversation?:  {
    __typename: "Studentconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
  } | null,
};

export type UpdateStudentconversationMutationVariables = {
  id: string,
  input: StudentconversationUpdate,
};

export type UpdateStudentconversationMutation = {
  updateStudentconversation?:  {
    __typename: "Studentconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
  } | null,
};

export type DeleteStudentconversationMutationVariables = {
  id: string,
};

export type DeleteStudentconversationMutation = {
  deleteStudentconversation?:  {
    __typename: "Studentconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
  } | null,
};

export type CreateUserconversationMutationVariables = {
  input: UserconversationInsert,
};

export type CreateUserconversationMutation = {
  createUserconversation?:  {
    __typename: "Userconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
  } | null,
};

export type UpdateUserconversationMutationVariables = {
  id: string,
  input: UserconversationUpdate,
};

export type UpdateUserconversationMutation = {
  updateUserconversation?:  {
    __typename: "Userconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
  } | null,
};

export type DeleteUserconversationMutationVariables = {
  id: string,
};

export type DeleteUserconversationMutation = {
  deleteUserconversation?:  {
    __typename: "Userconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
  } | null,
};

export type CreateConversationMutationVariables = {
  input: ConversationInsert,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversation",
    // The Conversation's timestamp.
    createdAt: string,
    updatedAt: string,
    // A unique identifier for the Conversation.
    id: string,
    // The Conversation's name.
    name: string,
    // Indicator is chat active or not
    isActive?: boolean | null,
    // The Conversation's messages.
    messages?:  Array< {
      __typename: "Message",
      // The message content.
      content: string,
      // The message timestamp. This is also the table sort key.
      createdAt: string,
      updatedAt: string,
      // Generated id for a message -- read-only
      id: string,
      // Flag denoting if this message has been accepted by the server or not.
      isSent?: boolean | null,
      // type of the sender message (user|student)
      senderType: SenderType,
      // The   Conversation this message belongs to. This is the table primary key.
      conversationId: string,
    } | null > | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
  } | null,
};

export type UpdateConversationMutationVariables = {
  id: string,
  input: ConversationUpdate,
};

export type UpdateConversationMutation = {
  updateConversation?:  {
    __typename: "Conversation",
    // The Conversation's timestamp.
    createdAt: string,
    updatedAt: string,
    // A unique identifier for the Conversation.
    id: string,
    // The Conversation's name.
    name: string,
    // Indicator is chat active or not
    isActive?: boolean | null,
    // The Conversation's messages.
    messages?:  Array< {
      __typename: "Message",
      // The message content.
      content: string,
      // The message timestamp. This is also the table sort key.
      createdAt: string,
      updatedAt: string,
      // Generated id for a message -- read-only
      id: string,
      // Flag denoting if this message has been accepted by the server or not.
      isSent?: boolean | null,
      // type of the sender message (user|student)
      senderType: SenderType,
      // The   Conversation this message belongs to. This is the table primary key.
      conversationId: string,
    } | null > | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
  } | null,
};

export type DeleteConversationMutationVariables = {
  id: string,
};

export type DeleteConversationMutation = {
  deleteConversation?:  {
    __typename: "Conversation",
    // The Conversation's timestamp.
    createdAt: string,
    updatedAt: string,
    // A unique identifier for the Conversation.
    id: string,
    // The Conversation's name.
    name: string,
    // Indicator is chat active or not
    isActive?: boolean | null,
    // The Conversation's messages.
    messages?:  Array< {
      __typename: "Message",
      // The message content.
      content: string,
      // The message timestamp. This is also the table sort key.
      createdAt: string,
      updatedAt: string,
      // Generated id for a message -- read-only
      id: string,
      // Flag denoting if this message has been accepted by the server or not.
      isSent?: boolean | null,
      // type of the sender message (user|student)
      senderType: SenderType,
      // The   Conversation this message belongs to. This is the table primary key.
      conversationId: string,
    } | null > | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: MessageInsert,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    // The message content.
    content: string,
    // The message timestamp. This is also the table sort key.
    createdAt: string,
    updatedAt: string,
    // Generated id for a message -- read-only
    id: string,
    // Flag denoting if this message has been accepted by the server or not.
    isSent?: boolean | null,
    // type of the sender message (user|student)
    senderType: SenderType,
    // add student sender who send message
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
    // add user sender who send message
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    // The   Conversation this message belongs to. This is the table primary key.
    conversationId: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  id: string,
  input: MessageUpdate,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    // The message content.
    content: string,
    // The message timestamp. This is also the table sort key.
    createdAt: string,
    updatedAt: string,
    // Generated id for a message -- read-only
    id: string,
    // Flag denoting if this message has been accepted by the server or not.
    isSent?: boolean | null,
    // type of the sender message (user|student)
    senderType: SenderType,
    // add student sender who send message
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
    // add user sender who send message
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    // The   Conversation this message belongs to. This is the table primary key.
    conversationId: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  id: string,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    // The message content.
    content: string,
    // The message timestamp. This is also the table sort key.
    createdAt: string,
    updatedAt: string,
    // Generated id for a message -- read-only
    id: string,
    // Flag denoting if this message has been accepted by the server or not.
    isSent?: boolean | null,
    // type of the sender message (user|student)
    senderType: SenderType,
    // add student sender who send message
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
    // add user sender who send message
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    // The   Conversation this message belongs to. This is the table primary key.
    conversationId: string,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: ReviewInsert,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewType: ReviewType,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    feedbackquestions?:  Array< {
      __typename: "FeedbackQuestion",
      id: string,
      createdAt: string,
      updatedAt: string,
      stars: number,
    } | null > | null,
    comments?: string | null,
    averageRating?: number | null,
  } | null,
};

export type UpdateReviewMutationVariables = {
  id: string,
  updatedAt: string,
  input: ReviewUpdate,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewType: ReviewType,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    feedbackquestions?:  Array< {
      __typename: "FeedbackQuestion",
      id: string,
      createdAt: string,
      updatedAt: string,
      stars: number,
    } | null > | null,
    comments?: string | null,
    averageRating?: number | null,
  } | null,
};

export type DeleteReviewMutationVariables = {
  id: string,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewType: ReviewType,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    feedbackquestions?:  Array< {
      __typename: "FeedbackQuestion",
      id: string,
      createdAt: string,
      updatedAt: string,
      stars: number,
    } | null > | null,
    comments?: string | null,
    averageRating?: number | null,
  } | null,
};

export type CreateAnswerVideoAISessionMutationVariables = {
  id: string,
  questionIndex: number,
  input: AnswerInsert,
};

export type CreateAnswerVideoAISessionMutation = {
  createAnswerVideoAISession?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null,
    monthlyReport?:  {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
    useEnglishAi?: boolean | null,
  } | null,
};

export type CreateAnswerMutationVariables = {
  input: AnswerInsert,
};

export type CreateAnswerMutation = {
  createAnswer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null,
    monthlyReport?:  {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
    useEnglishAi?: boolean | null,
  } | null,
};

export type UpdateAnswerMutationVariables = {
  id: string,
  updatedAt: string,
  input: AnswerUpdate,
};

export type UpdateAnswerMutation = {
  updateAnswer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null,
    monthlyReport?:  {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
    useEnglishAi?: boolean | null,
  } | null,
};

export type DeleteAnswerMutationVariables = {
  id: string,
};

export type DeleteAnswerMutation = {
  deleteAnswer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null,
    monthlyReport?:  {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
    useEnglishAi?: boolean | null,
  } | null,
};

export type CreateAvailabilityMutationVariables = {
  input: AvailabilityInsert,
};

export type CreateAvailabilityMutation = {
  createAvailability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    startTime: string,
    endTime: string,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    day: number,
  } | null,
};

export type UpdateAvailabilityMutationVariables = {
  id: string,
  updatedAt: string,
  input: AvailabilityUpdate,
};

export type UpdateAvailabilityMutation = {
  updateAvailability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    startTime: string,
    endTime: string,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    day: number,
  } | null,
};

export type DeleteAvailabilityMutationVariables = {
  id: string,
};

export type DeleteAvailabilityMutation = {
  deleteAvailability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    startTime: string,
    endTime: string,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    day: number,
  } | null,
};

export type CreateCourseMutationVariables = {
  input: CourseInsert,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    plan?: string | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
    videosessions?:  Array< {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    } | null > | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type UpdateCourseMutationVariables = {
  id: string,
  updatedAt: string,
  input: CourseUpdate,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    plan?: string | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
    videosessions?:  Array< {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    } | null > | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type DeleteCourseMutationVariables = {
  id: string,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    plan?: string | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
    videosessions?:  Array< {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    } | null > | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type CreateEnrollmentMutationVariables = {
  input: EnrollmentInsert,
};

export type CreateEnrollmentMutation = {
  createEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    endDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
      useEnglishAi?: boolean | null,
    } | null,
    selfRating?: number | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type UpdateEnrollmentMutationVariables = {
  id: string,
  updatedAt: string,
  input: EnrollmentUpdate,
};

export type UpdateEnrollmentMutation = {
  updateEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    endDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
      useEnglishAi?: boolean | null,
    } | null,
    selfRating?: number | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type DeleteEnrollmentMutationVariables = {
  id: string,
};

export type DeleteEnrollmentMutation = {
  deleteEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    endDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
      useEnglishAi?: boolean | null,
    } | null,
    selfRating?: number | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type CreateGroupMutationVariables = {
  input: GroupInsert,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: DivisionType,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    isGroupHasInstructor?: boolean | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    slot?:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null,
    nextSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    perviousSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    currentSessionNumber?: number | null,
    conversation?:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    } | null,
    isActive: boolean,
  } | null,
};

export type UpdateGroupMutationVariables = {
  id: string,
  updatedAt: string,
  input: GroupUpdate,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: DivisionType,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    isGroupHasInstructor?: boolean | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    slot?:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null,
    nextSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    perviousSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    currentSessionNumber?: number | null,
    conversation?:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    } | null,
    isActive: boolean,
  } | null,
};

export type DeleteGroupMutationVariables = {
  id: string,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: DivisionType,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    isGroupHasInstructor?: boolean | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    slot?:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null,
    nextSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    perviousSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    currentSessionNumber?: number | null,
    conversation?:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    } | null,
    isActive: boolean,
  } | null,
};

export type StartGroupMutationVariables = {
  groupId: string,
};

export type StartGroupMutation = {
  startGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: DivisionType,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    isGroupHasInstructor?: boolean | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    slot?:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null,
    nextSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    perviousSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    currentSessionNumber?: number | null,
    conversation?:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    } | null,
    isActive: boolean,
  } | null,
};

export type CreateInstructorMutationVariables = {
  input: InstructorInsert,
};

export type CreateInstructorMutation = {
  createInstructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    paymentMethod?: string | null,
    rating?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
  } | null,
};

export type UpdateInstructorMutationVariables = {
  id: string,
  updatedAt: string,
  input: InstructorUpdate,
};

export type UpdateInstructorMutation = {
  updateInstructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    paymentMethod?: string | null,
    rating?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
  } | null,
};

export type DeleteInstructorMutationVariables = {
  id: string,
};

export type DeleteInstructorMutation = {
  deleteInstructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    paymentMethod?: string | null,
    rating?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
  } | null,
};

export type CreatePreInstructorMutationVariables = {
  input: PreInstructorInsert,
};

export type CreatePreInstructorMutation = {
  createPreInstructor?:  {
    __typename: "PreInstructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    rating?: number | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
  } | null,
};

export type UpdatePreInstructorMutationVariables = {
  id: string,
  updatedAt: string,
  input: PreInstructorUpdate,
};

export type UpdatePreInstructorMutation = {
  updatePreInstructor?:  {
    __typename: "PreInstructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    rating?: number | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
  } | null,
};

export type DeletePreInstructorMutationVariables = {
  id: string,
};

export type DeletePreInstructorMutation = {
  deletePreInstructor?:  {
    __typename: "PreInstructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    rating?: number | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
  } | null,
};

export type CreateLanguageMutationVariables = {
  input: LanguageInsert,
};

export type CreateLanguageMutation = {
  createLanguage?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type UpdateLanguageMutationVariables = {
  id: string,
  updatedAt: string,
  input: LanguageUpdate,
};

export type UpdateLanguageMutation = {
  updateLanguage?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type DeleteLanguageMutationVariables = {
  id: string,
};

export type DeleteLanguageMutation = {
  deleteLanguage?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type CreateLessonMutationVariables = {
  input: LessonInsert,
};

export type CreateLessonMutation = {
  createLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    lessonPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    instructionsPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    tests?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } | null,
  } | null,
};

export type UpdateLessonMutationVariables = {
  id: string,
  updatedAt: string,
  input: LessonUpdate,
};

export type UpdateLessonMutation = {
  updateLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    lessonPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    instructionsPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    tests?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } | null,
  } | null,
};

export type DeleteLessonMutationVariables = {
  id: string,
};

export type DeleteLessonMutation = {
  deleteLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    lessonPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    instructionsPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    tests?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } | null,
  } | null,
};

export type CreateLevelMutationVariables = {
  input: LevelInsert,
};

export type CreateLevelMutation = {
  createLevel?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number?: number | null,
    score?: number | null,
  } | null,
};

export type UpdateLevelMutationVariables = {
  id: string,
  updatedAt: string,
  input: LevelUpdate,
};

export type UpdateLevelMutation = {
  updateLevel?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number?: number | null,
    score?: number | null,
  } | null,
};

export type DeleteLevelMutationVariables = {
  id: string,
};

export type DeleteLevelMutation = {
  deleteLevel?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number?: number | null,
    score?: number | null,
  } | null,
};

export type CreateAssetMutationVariables = {
  input: AssetInsert,
};

export type CreateAssetMutation = {
  createAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
    title_i18n?: string | null,
    subTitle_i18n?: string | null,
    position?: number | null,
  } | null,
};

export type UpdateAssetMutationVariables = {
  id: string,
  updatedAt: string,
  input: AssetUpdate,
};

export type UpdateAssetMutation = {
  updateAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
    title_i18n?: string | null,
    subTitle_i18n?: string | null,
    position?: number | null,
  } | null,
};

export type DeleteAssetMutationVariables = {
  id: string,
};

export type DeleteAssetMutation = {
  deleteAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
    title_i18n?: string | null,
    subTitle_i18n?: string | null,
    position?: number | null,
  } | null,
};

export type CreateMembershipMutationVariables = {
  input: MembershipInsert,
};

export type CreateMembershipMutation = {
  createMembership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    paymentStatus?: Status | null,
    placementStatus?: Status | null,
    isActive: boolean,
    startDate?: string | null,
    endDate?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    sessionPerWeek?: number | null,
    isFullFilled?: boolean | null,
    groups?:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null > | null,
    discountcode?:  {
      __typename: "Discountcode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      valuePercentage?: number | null,
      isExpired: boolean,
      validFrom: string,
      expirationDate: string,
      usage: DiscountUsage,
    } | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
  } | null,
};

export type UpdateMembershipMutationVariables = {
  id: string,
  updatedAt: string,
  input: MembershipUpdate,
};

export type UpdateMembershipMutation = {
  updateMembership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    paymentStatus?: Status | null,
    placementStatus?: Status | null,
    isActive: boolean,
    startDate?: string | null,
    endDate?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    sessionPerWeek?: number | null,
    isFullFilled?: boolean | null,
    groups?:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null > | null,
    discountcode?:  {
      __typename: "Discountcode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      valuePercentage?: number | null,
      isExpired: boolean,
      validFrom: string,
      expirationDate: string,
      usage: DiscountUsage,
    } | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
  } | null,
};

export type DeleteMembershipMutationVariables = {
  id: string,
};

export type DeleteMembershipMutation = {
  deleteMembership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    paymentStatus?: Status | null,
    placementStatus?: Status | null,
    isActive: boolean,
    startDate?: string | null,
    endDate?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    sessionPerWeek?: number | null,
    isFullFilled?: boolean | null,
    groups?:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null > | null,
    discountcode?:  {
      __typename: "Discountcode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      valuePercentage?: number | null,
      isExpired: boolean,
      validFrom: string,
      expirationDate: string,
      usage: DiscountUsage,
    } | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
  } | null,
};

export type CreatePackageMutationVariables = {
  input: PackageInsert,
};

export type CreatePackageMutation = {
  // createMembershipFromSlot(input: MembershipUpsertFromSlot!): Group
  // @aws_auth
  createPackage?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI?: boolean | null,
    name?: string | null,
    name_i18n?: string | null,
    sessionDuration?: number | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description?: string | null,
    benefits?: string | null,
    benefits_i18n?: string | null,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    coverAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    details?: string | null,
    details_i18n?: string | null,
    isFree?: boolean | null,
    numberOfSessions: number,
    description_i18n?: string | null,
    isNative?: boolean | null,
    isActive?: boolean | null,
    isHidden?: boolean | null,
    enrollmentPackage?:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } | null,
    promocodes?:  Array< {
      __typename: "Promocode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      isApplied: boolean,
      sessionPerWeek?: number | null,
    } | null > | null,
    productIAP?: string | null,
    typePackage?: DivisionType | null,
    packageDuration?: number | null,
    discountDescription?: string | null,
    isRecommended?: boolean | null,
    sessionPerWeek?: Array< number | null > | null,
  } | null,
};

export type UpdatePackageMutationVariables = {
  id: string,
  updatedAt: string,
  input: PackageUpdate,
};

export type UpdatePackageMutation = {
  updatePackage?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI?: boolean | null,
    name?: string | null,
    name_i18n?: string | null,
    sessionDuration?: number | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description?: string | null,
    benefits?: string | null,
    benefits_i18n?: string | null,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    coverAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    details?: string | null,
    details_i18n?: string | null,
    isFree?: boolean | null,
    numberOfSessions: number,
    description_i18n?: string | null,
    isNative?: boolean | null,
    isActive?: boolean | null,
    isHidden?: boolean | null,
    enrollmentPackage?:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } | null,
    promocodes?:  Array< {
      __typename: "Promocode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      isApplied: boolean,
      sessionPerWeek?: number | null,
    } | null > | null,
    productIAP?: string | null,
    typePackage?: DivisionType | null,
    packageDuration?: number | null,
    discountDescription?: string | null,
    isRecommended?: boolean | null,
    sessionPerWeek?: Array< number | null > | null,
  } | null,
};

export type DeletePackageMutationVariables = {
  id: string,
};

export type DeletePackageMutation = {
  deletePackage?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI?: boolean | null,
    name?: string | null,
    name_i18n?: string | null,
    sessionDuration?: number | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description?: string | null,
    benefits?: string | null,
    benefits_i18n?: string | null,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    coverAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    details?: string | null,
    details_i18n?: string | null,
    isFree?: boolean | null,
    numberOfSessions: number,
    description_i18n?: string | null,
    isNative?: boolean | null,
    isActive?: boolean | null,
    isHidden?: boolean | null,
    enrollmentPackage?:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } | null,
    promocodes?:  Array< {
      __typename: "Promocode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      isApplied: boolean,
      sessionPerWeek?: number | null,
    } | null > | null,
    productIAP?: string | null,
    typePackage?: DivisionType | null,
    packageDuration?: number | null,
    discountDescription?: string | null,
    isRecommended?: boolean | null,
    sessionPerWeek?: Array< number | null > | null,
  } | null,
};

export type CreatePlanMutationVariables = {
  input: PlanInsert,
};

export type CreatePlanMutation = {
  createPlan?: string | null,
};

export type UpdatePlanMutationVariables = {
  id: string,
  updatedAt: string,
  input: PlanUpdate,
};

export type UpdatePlanMutation = {
  updatePlan?: string | null,
};

export type DeletePlanMutationVariables = {
  id: string,
};

export type DeletePlanMutation = {
  deletePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt: string,
    updatedAt: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } | null,
    d1?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d2?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d3?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d4?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d5?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d6?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d7?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d8?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d9?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d10?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d11?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d12?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d13?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d14?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d15?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d16?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d17?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d18?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d19?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d20?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d21?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d22?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d23?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d24?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d25?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d26?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d27?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d28?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d29?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d30?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    d31?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
  } | null,
};

export type CreateParentMutationVariables = {
  input: ParentInsert,
};

export type CreateParentMutation = {
  createParent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type UpdateParentMutationVariables = {
  id: string,
  updatedAt: string,
  input: ParentUpdate,
};

export type UpdateParentMutation = {
  updateParent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type DeleteParentMutationVariables = {
  id: string,
};

export type DeleteParentMutation = {
  deleteParent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type CreatePriceMutationVariables = {
  input: PriceInsert,
};

export type CreatePriceMutation = {
  createPrice?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type UpdatePriceMutationVariables = {
  id: string,
  updatedAt: string,
  input: PriceUpdate,
};

export type UpdatePriceMutation = {
  updatePrice?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type DeletePriceMutationVariables = {
  id: string,
};

export type DeletePriceMutation = {
  deletePrice?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type CreateProgramMutationVariables = {
  input: ProgramInsert,
};

export type CreateProgramMutation = {
  createProgram?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    flag?: string | null,
    supportAi?: boolean | null,
    isActive?: boolean | null,
    instructors?:  Array< {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null > | null,
  } | null,
};

export type UpdateProgramMutationVariables = {
  id: string,
  updatedAt: string,
  input: ProgramUpdate,
};

export type UpdateProgramMutation = {
  updateProgram?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    flag?: string | null,
    supportAi?: boolean | null,
    isActive?: boolean | null,
    instructors?:  Array< {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null > | null,
  } | null,
};

export type DeleteProgramMutationVariables = {
  id: string,
};

export type DeleteProgramMutation = {
  deleteProgram?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    flag?: string | null,
    supportAi?: boolean | null,
    isActive?: boolean | null,
    instructors?:  Array< {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null > | null,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: QuestionInsert,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    asset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    videoAISessionUri?: string | null,
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary?: string | null,
    summary_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    text: string,
    textAI?: string | null,
    type?: QUESTION_TYPE | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  id: string,
  updatedAt: string,
  input: QuestionUpdate,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    asset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    videoAISessionUri?: string | null,
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary?: string | null,
    summary_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    text: string,
    textAI?: string | null,
    type?: QUESTION_TYPE | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  id: string,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    asset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    videoAISessionUri?: string | null,
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary?: string | null,
    summary_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    text: string,
    textAI?: string | null,
    type?: QUESTION_TYPE | null,
  } | null,
};

export type CreateSchoolMutationVariables = {
  input: SchoolInsert,
};

export type CreateSchoolMutation = {
  createSchool?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    name: string,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    teachers?:  Array< {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } > | null,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    isActive: boolean,
    classesschool?:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null > | null,
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type UpdateSchoolMutationVariables = {
  id: string,
  updatedAt: string,
  input: SchoolUpdate,
};

export type UpdateSchoolMutation = {
  updateSchool?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    name: string,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    teachers?:  Array< {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } > | null,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    isActive: boolean,
    classesschool?:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null > | null,
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type DeleteSchoolMutationVariables = {
  id: string,
};

export type DeleteSchoolMutation = {
  deleteSchool?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    name: string,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    teachers?:  Array< {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } > | null,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    isActive: boolean,
    classesschool?:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null > | null,
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type CreateSessionMutationVariables = {
  input: SessionInsert,
};

export type CreateSessionMutation = {
  createSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type UpdateSessionMutationVariables = {
  id: string,
  updatedAt: string,
  input: SessionUpdate,
};

export type UpdateSessionMutation = {
  updateSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type ResetSessionStatusMutationVariables = {
  id: string,
  updatedAt: string,
  status: Status,
};

export type ResetSessionStatusMutation = {
  resetSessionStatus?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type DeleteSessionMutationVariables = {
  id: string,
};

export type DeleteSessionMutation = {
  deleteSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type CreateSlotMutationVariables = {
  input: SlotInsert,
};

export type CreateSlotMutation = {
  createSlot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    slotDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    startTime: string,
    endTime: string,
    status: Status,
    limit: number,
    current: number,
    day: number,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
  } | null,
};

export type UpdateSlotMutationVariables = {
  id: string,
  updatedAt: string,
  input: SlotUpdate,
};

export type UpdateSlotMutation = {
  updateSlot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    slotDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    startTime: string,
    endTime: string,
    status: Status,
    limit: number,
    current: number,
    day: number,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
  } | null,
};

export type DeleteSlotMutationVariables = {
  id: string,
};

export type DeleteSlotMutation = {
  deleteSlot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    slotDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    startTime: string,
    endTime: string,
    status: Status,
    limit: number,
    current: number,
    day: number,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
  } | null,
};

export type CreateStudentMutationVariables = {
  input: StudentInsert,
};

export type CreateStudentMutation = {
  createStudent?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    schoolName?: string | null,
    name: string,
    birthDate?: string | null,
    age: number,
    targetAge?: number | null,
    gender: Gender,
    language?:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    } | null,
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    studentaudioprofile?:  {
      __typename: "Studentaudioprofile",
      id: string,
      createdAt: string,
      updatedAt: string,
      introAIVSUri: string,
      sentenceIntro: string,
      correctAIVSUri: string,
      sentenceCorrect: string,
      failAIVSUri: string,
      sentenceFail: string,
      finalAIVSUri: string,
      sentenceFinal: string,
      studentName: string,
    } | null,
    status?: Student_Status | null,
    assignedTo?: string | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    classschool?:  {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null,
    isSchoolVerfied?: boolean | null,
    placementStatus?: Status | null,
    email?: string | null,
    userName?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
    totalRating?: number | null,
    totalReviews?: number | null,
  } | null,
};

export type UpdateStudentMutationVariables = {
  id: string,
  updatedAt: string,
  input: StudentUpdate,
};

export type UpdateStudentMutation = {
  updateStudent?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    schoolName?: string | null,
    name: string,
    birthDate?: string | null,
    age: number,
    targetAge?: number | null,
    gender: Gender,
    language?:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    } | null,
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    studentaudioprofile?:  {
      __typename: "Studentaudioprofile",
      id: string,
      createdAt: string,
      updatedAt: string,
      introAIVSUri: string,
      sentenceIntro: string,
      correctAIVSUri: string,
      sentenceCorrect: string,
      failAIVSUri: string,
      sentenceFail: string,
      finalAIVSUri: string,
      sentenceFinal: string,
      studentName: string,
    } | null,
    status?: Student_Status | null,
    assignedTo?: string | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    classschool?:  {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null,
    isSchoolVerfied?: boolean | null,
    placementStatus?: Status | null,
    email?: string | null,
    userName?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
    totalRating?: number | null,
    totalReviews?: number | null,
  } | null,
};

export type DeleteStudentMutationVariables = {
  id: string,
};

export type DeleteStudentMutation = {
  deleteStudent?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    schoolName?: string | null,
    name: string,
    birthDate?: string | null,
    age: number,
    targetAge?: number | null,
    gender: Gender,
    language?:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    } | null,
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    studentaudioprofile?:  {
      __typename: "Studentaudioprofile",
      id: string,
      createdAt: string,
      updatedAt: string,
      introAIVSUri: string,
      sentenceIntro: string,
      correctAIVSUri: string,
      sentenceCorrect: string,
      failAIVSUri: string,
      sentenceFail: string,
      finalAIVSUri: string,
      sentenceFinal: string,
      studentName: string,
    } | null,
    status?: Student_Status | null,
    assignedTo?: string | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    classschool?:  {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null,
    isSchoolVerfied?: boolean | null,
    placementStatus?: Status | null,
    email?: string | null,
    userName?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
    totalRating?: number | null,
    totalReviews?: number | null,
  } | null,
};

export type CreateTestMutationVariables = {
  input: TestInsert,
};

export type CreateTestMutation = {
  createTest?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    } | null > | null,
    type: TestTypes,
    name?: string | null,
    name_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    summary?: string | null,
    summary_i18n?: string | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
  } | null,
};

export type UpdateTestMutationVariables = {
  id: string,
  updatedAt: string,
  input: TestUpdate,
};

export type UpdateTestMutation = {
  updateTest?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    } | null > | null,
    type: TestTypes,
    name?: string | null,
    name_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    summary?: string | null,
    summary_i18n?: string | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
  } | null,
};

export type DeleteTestMutationVariables = {
  id: string,
};

export type DeleteTestMutation = {
  deleteTest?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    } | null > | null,
    type: TestTypes,
    name?: string | null,
    name_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    summary?: string | null,
    summary_i18n?: string | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: UserInsert,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      createdAt: string,
      updatedAt: string,
      lastSeenOnline?: string | null,
      firstName: string,
      lastName: string,
      address: string,
      SSN?: number | null,
      gender: Gender,
      isActive: boolean,
    } | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    deviceToken?: string | null,
    devicePlatform?: string | null,
    endPointArn?: string | null,
    email?: string | null,
    contactIDActiveCampaign?: string | null,
    partnerAppName?: PikaDoPartners | null,
    preInstructor?:  {
      __typename: "PreInstructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      rating?: number | null,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  id: string,
  updatedAt: string,
  input: UserUpdate,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      createdAt: string,
      updatedAt: string,
      lastSeenOnline?: string | null,
      firstName: string,
      lastName: string,
      address: string,
      SSN?: number | null,
      gender: Gender,
      isActive: boolean,
    } | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    deviceToken?: string | null,
    devicePlatform?: string | null,
    endPointArn?: string | null,
    email?: string | null,
    contactIDActiveCampaign?: string | null,
    partnerAppName?: PikaDoPartners | null,
    preInstructor?:  {
      __typename: "PreInstructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      rating?: number | null,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  id: string,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      createdAt: string,
      updatedAt: string,
      lastSeenOnline?: string | null,
      firstName: string,
      lastName: string,
      address: string,
      SSN?: number | null,
      gender: Gender,
      isActive: boolean,
    } | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    deviceToken?: string | null,
    devicePlatform?: string | null,
    endPointArn?: string | null,
    email?: string | null,
    contactIDActiveCampaign?: string | null,
    partnerAppName?: PikaDoPartners | null,
    preInstructor?:  {
      __typename: "PreInstructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      rating?: number | null,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
    } | null,
  } | null,
};

export type StudentaudioprofileQueryVariables = {
  id: string,
};

export type StudentaudioprofileQuery = {
  studentaudioprofile?:  {
    __typename: "Studentaudioprofile",
    id: string,
    createdAt: string,
    updatedAt: string,
    introAIVSUri: string,
    sentenceIntro: string,
    correctAIVSUri: string,
    sentenceCorrect: string,
    failAIVSUri: string,
    sentenceFail: string,
    finalAIVSUri: string,
    sentenceFinal: string,
    studentName: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
  } | null,
};

export type StudentaiVideoSessionsByMembershipIdQueryVariables = {
  membershipId: string,
};

export type StudentaiVideoSessionsByMembershipIdQuery = {
  studentaiVideoSessionsByMembershipId?:  {
    __typename: "StudentaisessionsConnection",
    items:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type StudentaisessionQueryVariables = {
  id: string,
};

export type StudentaisessionQuery = {
  studentaisession?:  {
    __typename: "Studentaisession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    videosession:  {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    },
    membership:  {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    status: Status,
    currentQuestionIndex: number,
  } | null,
};

export type StudentaisessionsQueryVariables = {
  filter?: StudentaisessionsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type StudentaisessionsQuery = {
  studentaisessions?:  {
    __typename: "StudentaisessionsConnection",
    items:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type VideosessionQueryVariables = {
  id: string,
};

export type VideosessionQuery = {
  videosession?:  {
    __typename: "Videosession",
    id: string,
    createdAt: string,
    updatedAt: string,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    curriculum:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    },
    course:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    },
    test:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    },
    studentaisessions:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } >,
    duration: number,
    name: string,
    isActive: boolean,
  } | null,
};

export type VideosessionsQueryVariables = {
  filter?: VideosessionsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type VideosessionsQuery = {
  videosessions?:  {
    __typename: "VideosessionsConnection",
    items:  Array< {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SegmentQueryVariables = {
  id: string,
};

export type SegmentQuery = {
  segment?:  {
    __typename: "Segment",
    id: string,
    createdAt: string,
    updatedAt: string,
    segmantName: string,
    createdPinpointSegmentId: string,
  } | null,
};

export type SegmentsQueryVariables = {
  filter?: SegmentFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type SegmentsQuery = {
  segments?:  {
    __typename: "SegmentConnection",
    items:  Array< {
      __typename: "Segment",
      id: string,
      createdAt: string,
      updatedAt: string,
      segmantName: string,
      createdPinpointSegmentId: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type CurriculumQueryVariables = {
  id: string,
};

export type CurriculumQuery = {
  curriculum?:  {
    __typename: "Curriculum",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    totalPlanPeriod: number,
  } | null,
};

export type CurriculumsQueryVariables = {
  filter?: CurriculumsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type CurriculumsQuery = {
  curriculums?:  {
    __typename: "CurriculumsConnection",
    items:  Array< {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type TopicQueryVariables = {
  id: string,
};

export type TopicQuery = {
  topic?:  {
    __typename: "Topic",
    id: string,
    createdAt: string,
    updatedAt: string,
    topicName: string,
    topicArn: string,
  } | null,
};

export type TopicsQueryVariables = {
  filter?: TopicFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type TopicsQuery = {
  topics?:  {
    __typename: "TopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      createdAt: string,
      updatedAt: string,
      topicName: string,
      topicArn: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SyllableQueryVariables = {
  id: string,
};

export type SyllableQuery = {
  syllable?:  {
    __typename: "Syllable",
    id: string,
    createdAt: string,
    updatedAt: string,
    letters?: string | null,
    correctSyllable?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type SyllablesQueryVariables = {
  filter?: SyllablesFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type SyllablesQuery = {
  syllables?:  {
    __typename: "SyllablesConnection",
    items:  Array< {
      __typename: "Syllable",
      id: string,
      createdAt: string,
      updatedAt: string,
      letters?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type TeacherQueryVariables = {
  id: string,
};

export type TeacherQuery = {
  teacher?:  {
    __typename: "Teacher",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    userName?: string | null,
    email?: string | null,
    classesschool:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
  } | null,
};

export type TeachersQueryVariables = {
  filter?: TeachersFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type TeachersQuery = {
  teachers?:  {
    __typename: "TeachersConnection",
    items:  Array< {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ClassschoolQueryVariables = {
  id: string,
};

export type ClassschoolQuery = {
  classschool?:  {
    __typename: "Classschool",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    schoolYear?: string | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } >,
    school:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    },
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type ClassesschoolQueryVariables = {
  filter?: ClassesFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type ClassesschoolQuery = {
  classesschool?:  {
    __typename: "ClassesConnection",
    items:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ListCognitoUsersQueryVariables = {
  filter?: AdminListFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type ListCognitoUsersQuery = {
  listCognitoUsers?:  {
    __typename: "AdminListUser",
    Users?:  Array< {
      __typename: "AdminListUsersResponse",
      Username: string,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
    } | null > | null,
    PaginationToken?: string | null,
  } | null,
};

export type ListGroupsForUserQueryVariables = {
  userName: string,
};

export type ListGroupsForUserQuery = {
  listGroupsForUser?:  {
    __typename: "AdminListUserGroups",
    Groups?:  Array< {
      __typename: "GroupType",
      GroupName?: string | null,
      Description?: string | null,
      UserPoolId?: string | null,
    } | null > | null,
    NextToken?: string | null,
  } | null,
};

export type AdminGetUserQueryVariables = {
  userName: string,
};

export type AdminGetUserQuery = {
  adminGetUser?:  {
    __typename: "AdminGetUserResponse",
    Username: string,
    UserAttributes?:  Array< {
      __typename: "AttributeType",
      Name?: string | null,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: string | null,
  } | null,
};

export type ReviewquestionQueryVariables = {
  id: string,
};

export type ReviewquestionQuery = {
  reviewquestion?:  {
    __typename: "ReviewQuestion",
    id: string,
    createdAt: string,
    updatedAt: string,
    question_i18n: string,
    type: string,
    reviewType: ReviewType,
  } | null,
};

export type ReviewquestionsQueryVariables = {
  filter?: ReviewquestionFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type ReviewquestionsQuery = {
  reviewquestions?:  {
    __typename: "ReviewquestionsConnection",
    items:  Array< {
      __typename: "ReviewQuestion",
      id: string,
      createdAt: string,
      updatedAt: string,
      question_i18n: string,
      type: string,
      reviewType: ReviewType,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type GetInstructorAvailableSlotsQueryVariables = {
  userTimeZone?: string | null,
  instructorId?: string | null,
};

export type GetInstructorAvailableSlotsQuery = {
  getInstructorAvailableSlots?:  {
    __typename: "AvailableDaysSlotsConnection",
    items:  Array< {
      __typename: "AvailableDaysSlots",
      dayIndex: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type DiscountcodesQueryVariables = {
  filter?: DiscountcodeFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type DiscountcodesQuery = {
  discountcodes?:  {
    __typename: "DiscountcodeConnection",
    items:  Array< {
      __typename: "Discountcode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      valuePercentage?: number | null,
      isExpired: boolean,
      validFrom: string,
      expirationDate: string,
      usage: DiscountUsage,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type PromocodesQueryVariables = {
  filter?: PromocodeFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type PromocodesQuery = {
  promocodes?:  {
    __typename: "PromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      isApplied: boolean,
      sessionPerWeek?: number | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type AdminQueryVariables = {
  id: string,
};

export type AdminQuery = {
  admin?:  {
    __typename: "Admin",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    lastSeenOnline?: string | null,
    firstName: string,
    lastName: string,
    address: string,
    SSN?: number | null,
    gender: Gender,
    isActive: boolean,
  } | null,
};

export type AdminsQueryVariables = {
  filter?: AdminFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type AdminsQuery = {
  admins?:  {
    __typename: "AdminConnection",
    items:  Array< {
      __typename: "Admin",
      id: string,
      createdAt: string,
      updatedAt: string,
      lastSeenOnline?: string | null,
      firstName: string,
      lastName: string,
      address: string,
      SSN?: number | null,
      gender: Gender,
      isActive: boolean,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type StudentconversationQueryVariables = {
  id: string,
};

export type StudentconversationQuery = {
  studentconversation?:  {
    __typename: "Studentconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
  } | null,
};

export type StudentconversationsQueryVariables = {
  filter?: StudentconversationFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type StudentconversationsQuery = {
  studentconversations?:  {
    __typename: "StudentconversationConnection",
    items:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type UserconversationQueryVariables = {
  id: string,
};

export type UserconversationQuery = {
  userconversation?:  {
    __typename: "Userconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
  } | null,
};

export type UserconversationsQueryVariables = {
  filter?: UserconversationFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type UserconversationsQuery = {
  userconversations?:  {
    __typename: "UserconversationConnection",
    items:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type MessagesByConversationIdQueryVariables = {
  conversationId: string,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type MessagesByConversationIdQuery = {
  messagesByConversationId?:  {
    __typename: "MessageConnection",
    items?:  Array< {
      __typename: "Message",
      // The message content.
      content: string,
      // The message timestamp. This is also the table sort key.
      createdAt: string,
      updatedAt: string,
      // Generated id for a message -- read-only
      id: string,
      // Flag denoting if this message has been accepted by the server or not.
      isSent?: boolean | null,
      // type of the sender message (user|student)
      senderType: SenderType,
      // The   Conversation this message belongs to. This is the table primary key.
      conversationId: string,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ConversationQueryVariables = {
  id: string,
  scannedCount?: number | null,
};

export type ConversationQuery = {
  conversation?:  {
    __typename: "Conversation",
    // The Conversation's timestamp.
    createdAt: string,
    updatedAt: string,
    // A unique identifier for the Conversation.
    id: string,
    // The Conversation's name.
    name: string,
    // Indicator is chat active or not
    isActive?: boolean | null,
    // The Conversation's messages.
    messages?:  Array< {
      __typename: "Message",
      // The message content.
      content: string,
      // The message timestamp. This is also the table sort key.
      createdAt: string,
      updatedAt: string,
      // Generated id for a message -- read-only
      id: string,
      // Flag denoting if this message has been accepted by the server or not.
      isSent?: boolean | null,
      // type of the sender message (user|student)
      senderType: SenderType,
      // The   Conversation this message belongs to. This is the table primary key.
      conversationId: string,
    } | null > | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
  } | null,
};

export type ConversationsQueryVariables = {
  filter?: ConversationFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type ConversationsQuery = {
  conversations?:  {
    __typename: "ConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type MessageQueryVariables = {
  id: string,
};

export type MessageQuery = {
  message?:  {
    __typename: "Message",
    // The message content.
    content: string,
    // The message timestamp. This is also the table sort key.
    createdAt: string,
    updatedAt: string,
    // Generated id for a message -- read-only
    id: string,
    // Flag denoting if this message has been accepted by the server or not.
    isSent?: boolean | null,
    // type of the sender message (user|student)
    senderType: SenderType,
    // add student sender who send message
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
    // add user sender who send message
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    // The   Conversation this message belongs to. This is the table primary key.
    conversationId: string,
  } | null,
};

export type MessagesQueryVariables = {
  filter?: MessageFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type MessagesQuery = {
  messages?:  {
    __typename: "MessageConnection",
    items?:  Array< {
      __typename: "Message",
      // The message content.
      content: string,
      // The message timestamp. This is also the table sort key.
      createdAt: string,
      updatedAt: string,
      // Generated id for a message -- read-only
      id: string,
      // Flag denoting if this message has been accepted by the server or not.
      isSent?: boolean | null,
      // type of the sender message (user|student)
      senderType: SenderType,
      // The   Conversation this message belongs to. This is the table primary key.
      conversationId: string,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ReviewQueryVariables = {
  id: string,
};

export type ReviewQuery = {
  review?:  {
    __typename: "Review",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewType: ReviewType,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    feedbackquestions?:  Array< {
      __typename: "FeedbackQuestion",
      id: string,
      createdAt: string,
      updatedAt: string,
      stars: number,
    } | null > | null,
    comments?: string | null,
    averageRating?: number | null,
  } | null,
};

export type ReviewsQueryVariables = {
  filter?: ReviewFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type ReviewsQuery = {
  reviews?:  {
    __typename: "ReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      createdAt: string,
      updatedAt: string,
      reviewType: ReviewType,
      comments?: string | null,
      averageRating?: number | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type AnswerQueryVariables = {
  id: string,
};

export type AnswerQuery = {
  answer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null,
    monthlyReport?:  {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
    useEnglishAi?: boolean | null,
  } | null,
};

export type AnswerByStudentIdQueryVariables = {
  studentId: string,
};

export type AnswerByStudentIdQuery = {
  answerByStudentId?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null,
    monthlyReport?:  {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
    useEnglishAi?: boolean | null,
  } | null,
};

export type AvailabilityQueryVariables = {
  id: string,
};

export type AvailabilityQuery = {
  availability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    startTime: string,
    endTime: string,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    day: number,
  } | null,
};

export type AvailabilitiesQueryVariables = {
  filter?: AvailabilitiesFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type AvailabilitiesQuery = {
  availabilities?:  {
    __typename: "AvailabilitiesConnection",
    items:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type CourseQueryVariables = {
  id: string,
  plan?: string | null,
};

export type CourseQuery = {
  course?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    plan?: string | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
    videosessions?:  Array< {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    } | null > | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type CourseByEnrollmentIdQueryVariables = {
  enrollmentId: string,
  plan?: string | null,
};

export type CourseByEnrollmentIdQuery = {
  courseByEnrollmentId?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    plan?: string | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
    videosessions?:  Array< {
      __typename: "Videosession",
      id: string,
      createdAt: string,
      updatedAt: string,
      duration: number,
      name: string,
      isActive: boolean,
    } | null > | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type CoursesQueryVariables = {
  filter?: CoursesFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type CoursesQuery = {
  courses?:  {
    __typename: "CoursesConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type EnrollmentQueryVariables = {
  id: string,
};

export type EnrollmentQuery = {
  enrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    endDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
      useEnglishAi?: boolean | null,
    } | null,
    selfRating?: number | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type EnrollmentsQueryVariables = {
  filter?: EnrollmentsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type EnrollmentsQuery = {
  enrollments?:  {
    __typename: "EnrollmentsConnection",
    items:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type GroupQueryVariables = {
  id: string,
};

export type GroupQuery = {
  group?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: DivisionType,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
    isGroupHasInstructor?: boolean | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    slot?:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null,
    nextSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    perviousSession?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null,
    currentSessionNumber?: number | null,
    conversation?:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    } | null,
    isActive: boolean,
  } | null,
};

export type FilteredMembershipsQueryVariables = {
  programId?: string | null,
  levelId?: string | null,
  typeGroup?: DivisionType | null,
};

export type FilteredMembershipsQuery = {
  filteredMemberships?:  Array< {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    paymentStatus?: Status | null,
    placementStatus?: Status | null,
    isActive: boolean,
    startDate?: string | null,
    endDate?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    sessionPerWeek?: number | null,
    isFullFilled?: boolean | null,
    groups?:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null > | null,
    discountcode?:  {
      __typename: "Discountcode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      valuePercentage?: number | null,
      isExpired: boolean,
      validFrom: string,
      expirationDate: string,
      usage: DiscountUsage,
    } | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
  } | null > | null,
};

export type GroupsQueryVariables = {
  filter?: GroupsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type GroupsQuery = {
  groups?:  {
    __typename: "GroupsConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type InstructorQueryVariables = {
  id: string,
};

export type InstructorQuery = {
  instructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    paymentMethod?: string | null,
    rating?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
  } | null,
};

export type InstructorsQueryVariables = {
  filter?: InstructorsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type InstructorsQuery = {
  instructors?:  {
    __typename: "InstructorsConnection",
    items:  Array< {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type PreInstructorQueryVariables = {
  id: string,
};

export type PreInstructorQuery = {
  preInstructor?:  {
    __typename: "PreInstructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    rating?: number | null,
    sessionsCount?: number | null,
    speakingLanguages?: Array< string | null > | null,
    availabilities?:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    } | null > | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    isNative?: boolean | null,
    totalRating?: number | null,
    totalReviews?: number | null,
    isActive?: boolean | null,
    status?: InstructorStatus | null,
    birthDate?: string | null,
  } | null,
};

export type PreInstructorsQueryVariables = {
  filter?: InstructorsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type PreInstructorsQuery = {
  preInstructors?:  {
    __typename: "PreInstructorsConnection",
    items:  Array< {
      __typename: "PreInstructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      rating?: number | null,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type LanguageQueryVariables = {
  id: string,
};

export type LanguageQuery = {
  language?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type LanguagesQueryVariables = {
  filter?: LanguagesFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type LanguagesQuery = {
  languages?:  {
    __typename: "LanguagesConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type LessonQueryVariables = {
  id: string,
};

export type LessonQuery = {
  lesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    lessonPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    instructionsPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    tests?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } | null,
  } | null,
};

export type LessonsByProgramQueryVariables = {
  programId?: string | null,
};

export type LessonsByProgramQuery = {
  lessonsByProgram?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    lessonPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    instructionsPDF?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    tests?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null > | null,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } | null,
  } | null,
};

export type LessonsQueryVariables = {
  filter?: LessonsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type LessonsQuery = {
  lessons?:  {
    __typename: "LessonsConnection",
    items:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type LevelQueryVariables = {
  id: string,
};

export type LevelQuery = {
  level?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number?: number | null,
    score?: number | null,
  } | null,
};

export type LevelsQueryVariables = {
  filter?: LevelsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type LevelsQuery = {
  levels?:  {
    __typename: "LevelsConnection",
    items:  Array< {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type AssetQueryVariables = {
  id: string,
};

export type AssetQuery = {
  asset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
    title_i18n?: string | null,
    subTitle_i18n?: string | null,
    position?: number | null,
  } | null,
};

export type MembershipQueryVariables = {
  id: string,
};

export type MembershipQuery = {
  membership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    paymentStatus?: Status | null,
    placementStatus?: Status | null,
    isActive: boolean,
    startDate?: string | null,
    endDate?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    sessionPerWeek?: number | null,
    isFullFilled?: boolean | null,
    groups?:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null > | null,
    discountcode?:  {
      __typename: "Discountcode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      valuePercentage?: number | null,
      isExpired: boolean,
      validFrom: string,
      expirationDate: string,
      usage: DiscountUsage,
    } | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
  } | null,
};

export type MembershipsQueryVariables = {
  filter?: MembershipsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type MembershipsQuery = {
  memberships?:  {
    __typename: "MembershipsConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ParentQueryVariables = {
  id: string,
};

export type ParentQuery = {
  parent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type ParentsQueryVariables = {
  filter?: ParentsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type ParentsQuery = {
  parents?:  {
    __typename: "ParentsConnection",
    items:  Array< {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type PackageQueryVariables = {
  id: string,
};

export type PackageQuery = {
  package?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI?: boolean | null,
    name?: string | null,
    name_i18n?: string | null,
    sessionDuration?: number | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description?: string | null,
    benefits?: string | null,
    benefits_i18n?: string | null,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    coverAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    details?: string | null,
    details_i18n?: string | null,
    isFree?: boolean | null,
    numberOfSessions: number,
    description_i18n?: string | null,
    isNative?: boolean | null,
    isActive?: boolean | null,
    isHidden?: boolean | null,
    enrollmentPackage?:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } | null,
    promocodes?:  Array< {
      __typename: "Promocode",
      id: string,
      createdAt: string,
      updatedAt: string,
      codeName: string,
      isApplied: boolean,
      sessionPerWeek?: number | null,
    } | null > | null,
    productIAP?: string | null,
    typePackage?: DivisionType | null,
    packageDuration?: number | null,
    discountDescription?: string | null,
    isRecommended?: boolean | null,
    sessionPerWeek?: Array< number | null > | null,
  } | null,
};

export type PackagesQueryVariables = {
  filter?: PackagesFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type PackagesQuery = {
  packages?:  {
    __typename: "PackagesConnection",
    items:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type GetPlanQueryVariables = {
  id: string,
};

export type GetPlanQuery = {
  getPlan?: string | null,
};

export type PriceQueryVariables = {
  id: string,
};

export type PriceQuery = {
  price?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type PricesQueryVariables = {
  filter?: PricesFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type PricesQuery = {
  prices?:  {
    __typename: "PricesConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ProgramQueryVariables = {
  id: string,
};

export type ProgramQuery = {
  program?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      plan?: string | null,
    } >,
    activities?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    records?:  Array< {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null > | null,
    flag?: string | null,
    supportAi?: boolean | null,
    isActive?: boolean | null,
    instructors?:  Array< {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null > | null,
  } | null,
};

export type ProgramsQueryVariables = {
  filter?: ProgramsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProgramsQuery = {
  programs?:  {
    __typename: "ProgramsConnection",
    items:  Array< {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type QuestionQueryVariables = {
  id: string,
};

export type QuestionQuery = {
  question?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    name_i18n?: string | null,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    asset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
    videoAISessionUri?: string | null,
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary?: string | null,
    summary_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    text: string,
    textAI?: string | null,
    type?: QUESTION_TYPE | null,
  } | null,
};

export type QuestionsQueryVariables = {
  filter?: QuestionsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type QuestionsQuery = {
  questions?:  {
    __typename: "QuestionsConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SchoolQueryVariables = {
  id: string,
};

export type SchoolQuery = {
  school?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    name: string,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    teachers?:  Array< {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } > | null,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    },
    isActive: boolean,
    classesschool?:  Array< {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null > | null,
    promo?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    } | null,
  } | null,
};

export type SchoolsQueryVariables = {
  filter?: SchoolsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type SchoolsQuery = {
  schools?:  {
    __typename: "SchoolsConnection",
    items:  Array< {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SessionQueryVariables = {
  id: string,
};

export type SessionQuery = {
  session?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type SessionsQueryVariables = {
  filter?: SessionsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type SessionsQuery = {
  sessions?:  {
    __typename: "SessionsConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SlotQueryVariables = {
  id: string,
};

export type SlotQuery = {
  slot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    slotDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startTime: string,
      endTime: string,
      day: number,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      flag?: string | null,
      supportAi?: boolean | null,
      isActive?: boolean | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    },
    startTime: string,
    endTime: string,
    status: Status,
    limit: number,
    current: number,
    day: number,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    },
  } | null,
};

export type SlotsQueryVariables = {
  filter?: SlotsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type SlotsQuery = {
  slots?:  {
    __typename: "SlotsConnection",
    items:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type StudentQueryVariables = {
  id: string,
};

export type StudentQuery = {
  student?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    schoolName?: string | null,
    name: string,
    birthDate?: string | null,
    age: number,
    targetAge?: number | null,
    gender: Gender,
    language?:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    } | null,
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
      endDate?: string | null,
      selfRating?: number | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    studentaudioprofile?:  {
      __typename: "Studentaudioprofile",
      id: string,
      createdAt: string,
      updatedAt: string,
      introAIVSUri: string,
      sentenceIntro: string,
      correctAIVSUri: string,
      sentenceCorrect: string,
      failAIVSUri: string,
      sentenceFail: string,
      finalAIVSUri: string,
      sentenceFinal: string,
      studentName: string,
    } | null,
    status?: Student_Status | null,
    assignedTo?: string | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime?: string | null,
      startTime: string,
      endTime: string,
      status: Status,
      isRescheduled?: boolean | null,
      moderatorToken?: string | null,
      jitsiUrl?: string | null,
      rtcUserToken?: string | null,
      rtcInstructorToken?: string | null,
      rtcShareScreenToken?: string | null,
      rtcAdminToken?: string | null,
      zoomUrl?: string | null,
    } | null > | null,
    studentaisessions?:  Array< {
      __typename: "Studentaisession",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: Status,
      currentQuestionIndex: number,
    } | null > | null,
    studentconversations?:  Array< {
      __typename: "Studentconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    classschool?:  {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null,
    isSchoolVerfied?: boolean | null,
    placementStatus?: Status | null,
    email?: string | null,
    userName?: string | null,
    totalRatingConcentration?: number | null,
    totalRatingCamera?: number | null,
    totalRatingInternet?: number | null,
    totalRatingSessionQuality?: number | null,
    totalRating?: number | null,
    totalReviews?: number | null,
  } | null,
};

export type StudentsQueryVariables = {
  filter?: StudentsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type StudentsQuery = {
  students?:  {
    __typename: "StudentsConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type StatisticQueryVariables = {
  id: string,
};

export type StatisticQuery = {
  statistic?:  {
    __typename: "Statistic",
    id: string,
    createdAt: string,
    updatedAt: string,
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
    scores?: Array< number | null > | null,
    averageScore?: number | null,
    // Be calculated using the monthlyReports average
    // mhhr new
    fullReportLastGenerationDate?: string | null,
    monthlyReports?:  Array< {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } | null > | null,
    // SchoolReport
    classschool?:  {
      __typename: "Classschool",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      schoolYear?: string | null,
    } | null,
    phoneticsAsString?: string | null,
    syllablesAsString?: string | null,
  } | null,
};

export type StatisticsQueryVariables = {
  filter?: StatisticsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type StatisticsQuery = {
  statistics?:  {
    __typename: "StatisticsConnection",
    items:  Array< {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type MonthlyReportQueryVariables = {
  id: string,
};

export type MonthlyReportQuery = {
  // mhhr start
  monthlyReport?:  {
    __typename: "MonthlyReport",
    id: string,
    createdAt: string,
    updatedAt: string,
    month?: string | null,
    average?: number | null,
    mistakes?: number | null,
    attempts?: number | null,
    statistic?:  {
      __typename: "Statistic",
      id: string,
      createdAt: string,
      updatedAt: string,
      scores?: Array< number | null > | null,
      averageScore?: number | null,
      // Be calculated using the monthlyReports average
      // mhhr new
      fullReportLastGenerationDate?: string | null,
      phoneticsAsString?: string | null,
      syllablesAsString?: string | null,
    } | null,
    d1?: number | null,
    d2?: number | null,
    d3?: number | null,
    d4?: number | null,
    d5?: number | null,
    d6?: number | null,
    d7?: number | null,
    d8?: number | null,
    d9?: number | null,
    d10?: number | null,
    d11?: number | null,
    d12?: number | null,
    d13?: number | null,
    d14?: number | null,
    d15?: number | null,
    d16?: number | null,
    d17?: number | null,
    d18?: number | null,
    d19?: number | null,
    d20?: number | null,
    d21?: number | null,
    d22?: number | null,
    d23?: number | null,
    d24?: number | null,
    d25?: number | null,
    d26?: number | null,
    d27?: number | null,
    d28?: number | null,
    d29?: number | null,
    d30?: number | null,
    d31?: number | null,
  } | null,
};

export type MonthlyReportsQueryVariables = {
  filter?: MonthlyReportsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type MonthlyReportsQuery = {
  monthlyReports?:  {
    __typename: "MonthlyReportsConnection",
    items:  Array< {
      __typename: "MonthlyReport",
      id: string,
      createdAt: string,
      updatedAt: string,
      month?: string | null,
      average?: number | null,
      mistakes?: number | null,
      attempts?: number | null,
      d1?: number | null,
      d2?: number | null,
      d3?: number | null,
      d4?: number | null,
      d5?: number | null,
      d6?: number | null,
      d7?: number | null,
      d8?: number | null,
      d9?: number | null,
      d10?: number | null,
      d11?: number | null,
      d12?: number | null,
      d13?: number | null,
      d14?: number | null,
      d15?: number | null,
      d16?: number | null,
      d17?: number | null,
      d18?: number | null,
      d19?: number | null,
      d20?: number | null,
      d21?: number | null,
      d22?: number | null,
      d23?: number | null,
      d24?: number | null,
      d25?: number | null,
      d26?: number | null,
      d27?: number | null,
      d28?: number | null,
      d29?: number | null,
      d30?: number | null,
      d31?: number | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type TestQueryVariables = {
  id: string,
};

export type TestQuery = {
  // mhhr end
  test?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      name_i18n?: string | null,
      videoAISessionUri?: string | null,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary?: string | null,
      summary_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      text: string,
      textAI?: string | null,
      type?: QUESTION_TYPE | null,
    } | null > | null,
    type: TestTypes,
    name?: string | null,
    name_i18n?: string | null,
    description?: string | null,
    description_i18n?: string | null,
    summary?: string | null,
    summary_i18n?: string | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
      title_i18n?: string | null,
      subTitle_i18n?: string | null,
      position?: number | null,
    },
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
  } | null,
};

export type TestsQueryVariables = {
  filter?: TestsFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type TestsQuery = {
  tests?:  {
    __typename: "TestsConnection",
    items:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type GetUserByCognitoIdQueryVariables = {
  id: string,
};

export type GetUserByCognitoIdQuery = {
  getUserByCognitoId?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      createdAt: string,
      updatedAt: string,
      lastSeenOnline?: string | null,
      firstName: string,
      lastName: string,
      address: string,
      SSN?: number | null,
      gender: Gender,
      isActive: boolean,
    } | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    deviceToken?: string | null,
    devicePlatform?: string | null,
    endPointArn?: string | null,
    email?: string | null,
    contactIDActiveCampaign?: string | null,
    partnerAppName?: PikaDoPartners | null,
    preInstructor?:  {
      __typename: "PreInstructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      rating?: number | null,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
    } | null,
  } | null,
};

export type UserQueryVariables = {
  id: string,
};

export type UserQuery = {
  user?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      createdAt: string,
      updatedAt: string,
      lastSeenOnline?: string | null,
      firstName: string,
      lastName: string,
      address: string,
      SSN?: number | null,
      gender: Gender,
      isActive: boolean,
    } | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    deviceToken?: string | null,
    devicePlatform?: string | null,
    endPointArn?: string | null,
    email?: string | null,
    contactIDActiveCampaign?: string | null,
    partnerAppName?: PikaDoPartners | null,
    preInstructor?:  {
      __typename: "PreInstructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      rating?: number | null,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
    } | null,
  } | null,
};

export type UsersQueryVariables = {
  filter?: UsersFilter | null,
  scannedCount?: number | null,
  nextToken?: string | null,
};

export type UsersQuery = {
  users?:  {
    __typename: "UsersConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type MeQueryVariables = {
  cognitoUserId?: string | null,
};

export type MeQuery = {
  me?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    admin?:  {
      __typename: "Admin",
      id: string,
      createdAt: string,
      updatedAt: string,
      lastSeenOnline?: string | null,
      firstName: string,
      lastName: string,
      address: string,
      SSN?: number | null,
      gender: Gender,
      isActive: boolean,
    } | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    students?:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } > | null,
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      name: string,
      isActive: boolean,
    } | null,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      userName?: string | null,
      email?: string | null,
    } | null,
    userconversations?:  Array< {
      __typename: "Userconversation",
      createdAt: string,
      updatedAt: string,
      id: string,
    } | null > | null,
    deviceToken?: string | null,
    devicePlatform?: string | null,
    endPointArn?: string | null,
    email?: string | null,
    contactIDActiveCampaign?: string | null,
    partnerAppName?: PikaDoPartners | null,
    preInstructor?:  {
      __typename: "PreInstructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      rating?: number | null,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
    } | null,
  } | null,
};

export type OnAddEnrollmentSubscription = {
  onAddEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    endDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
      useEnglishAi?: boolean | null,
    } | null,
    selfRating?: number | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type OnUpdateEnrollmentSubscription = {
  onUpdateEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI?: boolean | null,
      name?: string | null,
      name_i18n?: string | null,
      sessionDuration?: number | null,
      description?: string | null,
      benefits?: string | null,
      benefits_i18n?: string | null,
      details?: string | null,
      details_i18n?: string | null,
      isFree?: boolean | null,
      numberOfSessions: number,
      description_i18n?: string | null,
      isNative?: boolean | null,
      isActive?: boolean | null,
      isHidden?: boolean | null,
      productIAP?: string | null,
      typePackage?: DivisionType | null,
      packageDuration?: number | null,
      discountDescription?: string | null,
      isRecommended?: boolean | null,
      sessionPerWeek?: Array< number | null > | null,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name?: string | null,
      name_i18n?: string | null,
      description?: string | null,
      description_i18n?: string | null,
      summary?: string | null,
      summary_i18n?: string | null,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    endDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
      useEnglishAi?: boolean | null,
    } | null,
    selfRating?: number | null,
    curriculum?:  {
      __typename: "Curriculum",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      totalPlanPeriod: number,
    } | null,
  } | null,
};

export type SubscribeToNewMessageSubscriptionVariables = {
  conversationId: string,
};

export type SubscribeToNewMessageSubscription = {
  subscribeToNewMessage?:  {
    __typename: "Message",
    // The message content.
    content: string,
    // The message timestamp. This is also the table sort key.
    createdAt: string,
    updatedAt: string,
    // Generated id for a message -- read-only
    id: string,
    // Flag denoting if this message has been accepted by the server or not.
    isSent?: boolean | null,
    // type of the sender message (user|student)
    senderType: SenderType,
    // add student sender who send message
    student?:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolName?: string | null,
      name: string,
      birthDate?: string | null,
      age: number,
      targetAge?: number | null,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
      status?: Student_Status | null,
      assignedTo?: string | null,
      isSchoolVerfied?: boolean | null,
      placementStatus?: Status | null,
      email?: string | null,
      userName?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
      totalRating?: number | null,
      totalReviews?: number | null,
    } | null,
    // add user sender who send message
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
    // The   Conversation this message belongs to. This is the table primary key.
    conversationId: string,
  } | null,
};

export type SubscribeToNewUCsSubscription = {
  subscribeToNewUCs?:  {
    __typename: "Userconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
  } | null,
};

export type SubscribeDeleteUCsSubscriptionVariables = {
  id: string,
};

export type SubscribeDeleteUCsSubscription = {
  subscribeDeleteUCs?:  {
    __typename: "Userconversation",
    createdAt: string,
    updatedAt: string,
    id: string,
    conversation:  {
      __typename: "Conversation",
      // The Conversation's timestamp.
      createdAt: string,
      updatedAt: string,
      // A unique identifier for the Conversation.
      id: string,
      // The Conversation's name.
      name: string,
      // Indicator is chat active or not
      isActive?: boolean | null,
    },
    user?:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      deviceToken?: string | null,
      devicePlatform?: string | null,
      endPointArn?: string | null,
      email?: string | null,
      contactIDActiveCampaign?: string | null,
      partnerAppName?: PikaDoPartners | null,
    } | null,
  } | null,
};

export type SubscribeStartSessionSubscriptionVariables = {
  id: string,
};

export type SubscribeStartSessionSubscription = {
  subscribeStartSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};

export type SubscribeEndSessionSubscriptionVariables = {
  id: string,
};

export type SubscribeEndSessionSubscription = {
  subscribeEndSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      slotDuration: number,
      startTime: string,
      endTime: string,
      status: Status,
      limit: number,
      current: number,
      day: number,
    },
    duration: number,
    startDateTime?: string | null,
    startTime: string,
    endTime: string,
    status: Status,
    isRescheduled?: boolean | null,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
      sessionsCount?: number | null,
      speakingLanguages?: Array< string | null > | null,
      isNative?: boolean | null,
      totalRating?: number | null,
      totalReviews?: number | null,
      paymentMethod?: string | null,
      rating?: number | null,
      isActive?: boolean | null,
      status?: InstructorStatus | null,
      birthDate?: string | null,
      totalRatingConcentration?: number | null,
      totalRatingCamera?: number | null,
      totalRatingInternet?: number | null,
      totalRatingSessionQuality?: number | null,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    moderatorToken?: string | null,
    jitsiUrl?: string | null,
    rtcUserToken?: string | null,
    rtcInstructorToken?: string | null,
    rtcShareScreenToken?: string | null,
    rtcAdminToken?: string | null,
    zoomUrl?: string | null,
    group?:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: DivisionType,
      limit: number,
      isGroupHasInstructor?: boolean | null,
      currentSessionNumber?: number | null,
      isActive: boolean,
    } | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
      paymentStatus?: Status | null,
      placementStatus?: Status | null,
      isActive: boolean,
      startDate?: string | null,
      endDate?: string | null,
      sessionPerWeek?: number | null,
      isFullFilled?: boolean | null,
    } | null > | null,
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number?: number | null,
      score?: number | null,
    } | null,
  } | null,
};
