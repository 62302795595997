import {Review, ReviewInsert, ReviewQuestion, Session} from '../../graphql/API';
import {reviewquestions} from '../../graphql/queries';
import {createReview} from '../customMutations/review';
import {session} from '../customQueries/review';
import {generateClient, GraphQLResult} from 'aws-amplify/api';

const client = generateClient();

export interface IReviewRepository {
  createReview(review: ReviewInsert): Promise<Review | any>;
  getInstructorReviewQuestions(): Promise<ReviewQuestion[]>;
  getSessionById(sessionId: string): Promise<Session>;
}

export class ReviewRepository implements IReviewRepository {
  async getSessionById(sessionId: string): Promise<Session> {
    let sess: GraphQLResult<any> = await client.graphql({
      query: session,
      variables: {
        id: sessionId,
      },
    });

    console.log('response-', sess);

    return sess.data?.session;
  }

  async createReview(review: ReviewInsert): Promise<Review> {
    const res: GraphQLResult<any> = await client.graphql({
      query: createReview,
      variables: {
        input: {...review},
      },
    });

    console.log('response-', res);

    return res.data.createReview;
  }

  async getInstructorReviewQuestions(): Promise<ReviewQuestion[]> {
    let reviewQ: GraphQLResult<any> = await client.graphql({
      query: reviewquestions,
      variables: {},
    });

    console.log('response-', reviewQ);

    return reviewQ.data?.reviewquestions.items;
  }
}
