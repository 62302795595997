export const me = /* GraphQL */ `
  query Me {
    me {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
      }
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        placementStatus
      }
      userconversations {
        createdAt
        updatedAt
        id
        conversation {
          id
          name
          userconversations {
            id
            conversation {
              id
            }
            user {
              id
            }
          }
          studentconversations {
            id
            student {
              id
              name
              user {
                id
                endPointArn
              }
            }
          }
        }
      }
    }
  }
`;

export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $scannedCount: Int
    $nextToken: String
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
        student {
          id
          name
        }
        user {
          id
          admin {
            id
            createdAt
            updatedAt
            firstName
            lastName
            address
            SSN
            gender
            isActive
          }
        }
      }
      nextToken
      scannedCount
    }
  }
`;
