import {Button, Row, Typography} from 'antd';
import Title from 'antd/es/typography/Title';
//const dotenv = require('dotenv');
import moment from 'moment-timezone';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Availability, Session, Slot, Status} from '../../graphql/API';
import {reducersState} from '../../redux/reducers';

const {Text} = Typography;
export const Avaliablities = () => {
  const currentUser = useSelector(
    (state: reducersState) => state.user.currentUser,
  );
  const history = useHistory();
  console.log('currentUser', currentUser);
  return (
    <Row style={{backgroundColor: 'white', display: 'flex', flex: 1}}>
      <div
        style={{display: 'flex', flexDirection: 'row', flex: 1, marginTop: 66}}>
        <div
          style={{
            display: 'flex',
            flex: 0.7,
            // alignItems: 'center',
            // justifyContent: 'center',
            marginInline: 20,
            flexDirection: 'column',
          }}>
          <Title>Welcome {currentUser?.instructor?.name}</Title>

          <div>
            {currentUser?.instructor?.availabilities.map(
              (aval: Availability) => (
                <div style={{marginTop: 40}} key={aval.id}>
                  <Text style={{color: '#A6A6A6'}}>Your Availability on </Text>
                  <Text style={{color: '#F2406A', fontWeight: 'bold'}}>
                    {' '}
                    {moment
                      .tz(
                        new Date().toDateString() + ' ' + aval?.startTime,
                        '',
                        'UTC',
                      )
                      .day(aval.day ?? -1)
                      .utc(true)
                      .clone()
                      .tz(currentUser?.timeZone ?? 'Africa/Cairo')
                      .format('dddd')}
                  </Text>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 10,
                    }}>
                    {aval.slots.map(
                      (slot: Slot) =>
                        slot?.status === Status.DONE &&
                        slot.group?.isGroupHasInstructor && (
                          <div
                            key={slot?.id}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginTop: '20px',
                            }}>
                            <Text
                              style={{color: '#F2406A', fontWeight: 'bold'}}>
                              {moment
                                .tz(slot.startTime, 'HH:mm:ss', 'UTC')
                                .day(aval.day ?? -1)
                                .utc(true)
                                .clone()
                                .tz(currentUser?.timeZone ?? 'Africa/Cairo')
                                .format('hh:ss A')}
                            </Text>
                            <a>
                              <div
                                onClick={() => {
                                  history.push('/group/' + slot.group.id);
                                }}
                                style={{
                                  marginInline: 20,
                                  minWidth: '250px',
                                  height: 40,
                                  backgroundColor: '#F2406A',
                                  alignItems: 'center',
                                  display: 'flex',
                                  paddingInline: 20,
                                  borderRadius: 8,
                                }}>
                                <Text
                                  style={{color: 'white', fontWeight: 'bold'}}>
                                  {slot.group.name}
                                </Text>
                              </div>
                            </a>
                            <Text
                              style={{color: '#F2406A', fontWeight: 'bold'}}>
                              {moment
                                .tz(
                                  slot.group?.sessions?.find(
                                    (sess: Session) =>
                                      sess?.status === Status.TODO,
                                  )?.startDateTime,
                                  'YYYY-MM-DD HH:mm:ss',
                                  'UTC',
                                )
                                .clone()
                                .tz(currentUser.timeZone ?? 'Africa/Cairo')
                                .format('DD-MM-YYYY')}
                            </Text>
                          </div>
                        ),
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.3,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Button
          onClick={async () => {
            history.push('/availability/new');
          }}
          style={{
            width: 250,
            height: 52,
            borderRadius: 10,
            backgroundColor: '#40B2F2',
          }}>
          <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold'}}>
            My Availability
          </Text>
        </Button>
      </div>
    </Row>
  );
};
