/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const studentaudioprofile = /* GraphQL */ `
  query Studentaudioprofile($id: ID!) {
    studentaudioprofile(id: $id) {
      id
      createdAt
      updatedAt
      introAIVSUri
      sentenceIntro
      correctAIVSUri
      sentenceCorrect
      failAIVSUri
      sentenceFail
      finalAIVSUri
      sentenceFinal
      studentName
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
    }
  }
`;
export const studentaiVideoSessionsByMembershipId = /* GraphQL */ `
  query StudentaiVideoSessionsByMembershipId($membershipId: String!) {
    studentaiVideoSessionsByMembershipId(membershipId: $membershipId) {
      items {
        id
        createdAt
        updatedAt
        status
        currentQuestionIndex
      }
      nextToken
      scannedCount
    }
  }
`;
export const studentaisession = /* GraphQL */ `
  query Studentaisession($id: ID!) {
    studentaisession(id: $id) {
      id
      createdAt
      updatedAt
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
      videosession {
        id
        createdAt
        updatedAt
        duration
        name
        isActive
      }
      membership {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      status
      currentQuestionIndex
    }
  }
`;
export const studentaisessions = /* GraphQL */ `
  query Studentaisessions(
    $filter: StudentaisessionsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    studentaisessions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        status
        currentQuestionIndex
      }
      nextToken
      scannedCount
    }
  }
`;
export const videosession = /* GraphQL */ `
  query Videosession($id: ID!) {
    videosession(id: $id) {
      id
      createdAt
      updatedAt
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
      course {
        id
        createdAt
        updatedAt
        name
        plan
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      studentaisessions {
        id
        createdAt
        updatedAt
        status
        currentQuestionIndex
      }
      duration
      name
      isActive
    }
  }
`;
export const videosessions = /* GraphQL */ `
  query Videosessions(
    $filter: VideosessionsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    videosessions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        duration
        name
        isActive
      }
      nextToken
      scannedCount
    }
  }
`;
export const segment = /* GraphQL */ `
  query Segment($id: ID!) {
    segment(id: $id) {
      id
      createdAt
      updatedAt
      segmantName
      createdPinpointSegmentId
    }
  }
`;
export const segments = /* GraphQL */ `
  query Segments(
    $filter: SegmentFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    segments(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        segmantName
        createdPinpointSegmentId
      }
      nextToken
      scannedCount
    }
  }
`;
export const curriculum = /* GraphQL */ `
  query Curriculum($id: ID!) {
    curriculum(id: $id) {
      id
      createdAt
      updatedAt
      name
      courses {
        id
        createdAt
        updatedAt
        name
        plan
      }
      totalPlanPeriod
    }
  }
`;
export const curriculums = /* GraphQL */ `
  query Curriculums(
    $filter: CurriculumsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    curriculums(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
      nextToken
      scannedCount
    }
  }
`;
export const topic = /* GraphQL */ `
  query Topic($id: ID!) {
    topic(id: $id) {
      id
      createdAt
      updatedAt
      topicName
      topicArn
    }
  }
`;
export const topics = /* GraphQL */ `
  query Topics($filter: TopicFilter, $scannedCount: Int, $nextToken: String) {
    topics(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        topicName
        topicArn
      }
      nextToken
      scannedCount
    }
  }
`;
export const syllable = /* GraphQL */ `
  query Syllable($id: ID!) {
    syllable(id: $id) {
      id
      createdAt
      updatedAt
      letters
      correctSyllable {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
    }
  }
`;
export const syllables = /* GraphQL */ `
  query Syllables(
    $filter: SyllablesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    syllables(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        letters
      }
      nextToken
      scannedCount
    }
  }
`;
export const teacher = /* GraphQL */ `
  query Teacher($id: ID!) {
    teacher(id: $id) {
      id
      createdAt
      updatedAt
      name
      userName
      email
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
    }
  }
`;
export const teachers = /* GraphQL */ `
  query Teachers(
    $filter: TeachersFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    teachers(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      nextToken
      scannedCount
    }
  }
`;
export const classschool = /* GraphQL */ `
  query Classschool($id: String!) {
    classschool(id: $id) {
      id
      createdAt
      updatedAt
      name
      schoolYear
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      teacher {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
        fullReportLastGenerationDate
        phoneticsAsString
        syllablesAsString
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const classesschool = /* GraphQL */ `
  query Classesschool(
    $filter: ClassesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    classesschool(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      nextToken
      scannedCount
    }
  }
`;
export const listCognitoUsers = /* GraphQL */ `
  query ListCognitoUsers(
    $filter: AdminListFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    listCognitoUsers(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
      }
      PaginationToken
    }
  }
`;
export const listGroupsForUser = /* GraphQL */ `
  query ListGroupsForUser($userName: String!) {
    listGroupsForUser(userName: $userName) {
      Groups {
        GroupName
        Description
        UserPoolId
      }
      NextToken
    }
  }
`;
export const adminGetUser = /* GraphQL */ `
  query AdminGetUser($userName: String!) {
    adminGetUser(userName: $userName) {
      Username
      UserAttributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
  }
`;
export const reviewquestion = /* GraphQL */ `
  query Reviewquestion($id: ID!) {
    reviewquestion(id: $id) {
      id
      createdAt
      updatedAt
      question_i18n
      type
      reviewType
    }
  }
`;
export const reviewquestions = /* GraphQL */ `
  query Reviewquestions(
    $filter: ReviewquestionFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    reviewquestions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        question_i18n
        type
        reviewType
      }
      nextToken
      scannedCount
    }
  }
`;
export const getInstructorAvailableSlots = /* GraphQL */ `
  query GetInstructorAvailableSlots(
    $userTimeZone: String
    $instructorId: String
  ) {
    getInstructorAvailableSlots(
      userTimeZone: $userTimeZone
      instructorId: $instructorId
    ) {
      items {
        dayIndex
      }
      nextToken
      scannedCount
    }
  }
`;
export const discountcodes = /* GraphQL */ `
  query Discountcodes(
    $filter: DiscountcodeFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    discountcodes(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
      }
      nextToken
      scannedCount
    }
  }
`;
export const promocodes = /* GraphQL */ `
  query Promocodes(
    $filter: PromocodeFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    promocodes(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        codeName
        isApplied
        sessionPerWeek
      }
      nextToken
      scannedCount
    }
  }
`;
export const admin = /* GraphQL */ `
  query Admin($id: ID!) {
    admin(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
    }
  }
`;
export const admins = /* GraphQL */ `
  query Admins($filter: AdminFilter, $scannedCount: Int, $nextToken: String) {
    admins(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        lastSeenOnline
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      nextToken
      scannedCount
    }
  }
`;
export const studentconversation = /* GraphQL */ `
  query Studentconversation($id: ID!) {
    studentconversation(id: $id) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
    }
  }
`;
export const studentconversations = /* GraphQL */ `
  query Studentconversations(
    $filter: StudentconversationFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    studentconversations(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        id
      }
      nextToken
      scannedCount
    }
  }
`;
export const userconversation = /* GraphQL */ `
  query Userconversation($id: ID!) {
    userconversation(id: $id) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
    }
  }
`;
export const userconversations = /* GraphQL */ `
  query Userconversations(
    $filter: UserconversationFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    userconversations(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        id
      }
      nextToken
      scannedCount
    }
  }
`;
export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $scannedCount: Int
    $nextToken: String
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
      }
      nextToken
      scannedCount
    }
  }
`;
export const conversation = /* GraphQL */ `
  query Conversation($id: ID!, $scannedCount: Int) {
    conversation(id: $id, scannedCount: $scannedCount) {
      createdAt
      updatedAt
      id
      name
      isActive
      messages {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
      }
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
    }
  }
`;
export const conversations = /* GraphQL */ `
  query Conversations(
    $filter: ConversationFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    conversations(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      nextToken
      scannedCount
    }
  }
`;
export const message = /* GraphQL */ `
  query Message($id: ID!) {
    message(id: $id) {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      conversationId
    }
  }
`;
export const messages = /* GraphQL */ `
  query Messages(
    $filter: MessageFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    messages(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
      }
      nextToken
      scannedCount
    }
  }
`;
export const review = /* GraphQL */ `
  query Review($id: String!) {
    review(id: $id) {
      id
      createdAt
      updatedAt
      reviewType
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      session {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      feedbackquestions {
        id
        createdAt
        updatedAt
        stars
      }
      comments
      averageRating
    }
  }
`;
export const reviews = /* GraphQL */ `
  query Reviews($filter: ReviewFilter, $scannedCount: Int, $nextToken: String) {
    reviews(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        reviewType
        comments
        averageRating
      }
      nextToken
      scannedCount
    }
  }
`;
export const answer = /* GraphQL */ `
  query Answer($id: String!) {
    answer(id: $id) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      question {
        id
        createdAt
        updatedAt
        name
        name_i18n
        videoAISessionUri
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
        type
      }
      enrollment {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        endDate
        selfRating
      }
      monthlyReport {
        id
        createdAt
        updatedAt
        month
        average
        mistakes
        attempts
        d1
        d2
        d3
        d4
        d5
        d6
        d7
        d8
        d9
        d10
        d11
        d12
        d13
        d14
        d15
        d16
        d17
        d18
        d19
        d20
        d21
        d22
        d23
        d24
        d25
        d26
        d27
        d28
        d29
        d30
        d31
      }
      choice
      audioUri
      text
      score
      analysis
      useEnglishAi
    }
  }
`;
export const answerByStudentId = /* GraphQL */ `
  query AnswerByStudentId($studentId: String!) {
    answerByStudentId(studentId: $studentId) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      question {
        id
        createdAt
        updatedAt
        name
        name_i18n
        videoAISessionUri
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
        type
      }
      enrollment {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        endDate
        selfRating
      }
      monthlyReport {
        id
        createdAt
        updatedAt
        month
        average
        mistakes
        attempts
        d1
        d2
        d3
        d4
        d5
        d6
        d7
        d8
        d9
        d10
        d11
        d12
        d13
        d14
        d15
        d16
        d17
        d18
        d19
        d20
        d21
        d22
        d23
        d24
        d25
        d26
        d27
        d28
        d29
        d30
        d31
      }
      choice
      audioUri
      text
      score
      analysis
      useEnglishAi
    }
  }
`;
export const availability = /* GraphQL */ `
  query Availability($id: String!) {
    availability(id: $id) {
      id
      createdAt
      updatedAt
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      startTime
      endTime
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      day
    }
  }
`;
export const availabilities = /* GraphQL */ `
  query Availabilities(
    $filter: AvailabilitiesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    availabilities(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      nextToken
      scannedCount
    }
  }
`;
export const course = /* GraphQL */ `
  query Course($id: String!, $plan: String) {
    course(id: $id, plan: $plan) {
      id
      createdAt
      updatedAt
      name
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      plan
      lessons {
        id
        createdAt
        updatedAt
        name
      }
      videosessions {
        id
        createdAt
        updatedAt
        duration
        name
        isActive
      }
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
    }
  }
`;
export const courseByEnrollmentId = /* GraphQL */ `
  query CourseByEnrollmentId($enrollmentId: String!, $plan: String) {
    courseByEnrollmentId(enrollmentId: $enrollmentId, plan: $plan) {
      id
      createdAt
      updatedAt
      name
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      plan
      lessons {
        id
        createdAt
        updatedAt
        name
      }
      videosessions {
        id
        createdAt
        updatedAt
        duration
        name
        isActive
      }
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
    }
  }
`;
export const courses = /* GraphQL */ `
  query Courses(
    $filter: CoursesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    courses(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        plan
      }
      nextToken
      scannedCount
    }
  }
`;
export const enrollment = /* GraphQL */ `
  query Enrollment($id: String!) {
    enrollment(id: $id) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      placementStatus
      paymentStatus
      startDate
      endDate
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      answer {
        id
        createdAt
        updatedAt
        choice
        audioUri
        text
        score
        analysis
        useEnglishAi
      }
      selfRating
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
    }
  }
`;
export const enrollments = /* GraphQL */ `
  query Enrollments(
    $filter: EnrollmentsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    enrollments(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        endDate
        selfRating
      }
      nextToken
      scannedCount
    }
  }
`;
export const group = /* GraphQL */ `
  query Group($id: String!) {
    group(id: $id) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      isGroupHasInstructor
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      nextSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      perviousSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      currentSessionNumber
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      isActive
    }
  }
`;
export const filteredMemberships = /* GraphQL */ `
  query FilteredMemberships(
    $programId: String
    $levelId: String
    $typeGroup: DivisionType
  ) {
    filteredMemberships(
      programId: $programId
      levelId: $levelId
      typeGroup: $typeGroup
    ) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      sessionPerWeek
      isFullFilled
      groups {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      discountcode {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
      }
      studentaisessions {
        id
        createdAt
        updatedAt
        status
        currentQuestionIndex
      }
    }
  }
`;
export const groups = /* GraphQL */ `
  query Groups($filter: GroupsFilter, $scannedCount: Int, $nextToken: String) {
    groups(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      nextToken
      scannedCount
    }
  }
`;
export const instructor = /* GraphQL */ `
  query Instructor($id: String!) {
    instructor(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      sessionsCount
      speakingLanguages
      availabilities {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
    }
  }
`;
export const instructors = /* GraphQL */ `
  query Instructors(
    $filter: InstructorsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    instructors(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      nextToken
      scannedCount
    }
  }
`;
export const preInstructor = /* GraphQL */ `
  query PreInstructor($id: String!) {
    preInstructor(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      rating
      sessionsCount
      speakingLanguages
      availabilities {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
    }
  }
`;
export const preInstructors = /* GraphQL */ `
  query PreInstructors(
    $filter: InstructorsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    preInstructors(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      nextToken
      scannedCount
    }
  }
`;
export const language = /* GraphQL */ `
  query Language($id: String!) {
    language(id: $id) {
      id
      createdAt
      updatedAt
      language
      country
    }
  }
`;
export const languages = /* GraphQL */ `
  query Languages(
    $filter: LanguagesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    languages(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        language
        country
      }
      nextToken
      scannedCount
    }
  }
`;
export const lesson = /* GraphQL */ `
  query Lesson($id: String!) {
    lesson(id: $id) {
      id
      createdAt
      updatedAt
      name
      summary {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lessonPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      instructionsPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      tests {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      course {
        id
        createdAt
        updatedAt
        name
        plan
      }
    }
  }
`;
export const lessonsByProgram = /* GraphQL */ `
  query LessonsByProgram($programId: String) {
    lessonsByProgram(programId: $programId) {
      id
      createdAt
      updatedAt
      name
      summary {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lessonPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      instructionsPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      tests {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      course {
        id
        createdAt
        updatedAt
        name
        plan
      }
    }
  }
`;
export const lessons = /* GraphQL */ `
  query Lessons(
    $filter: LessonsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    lessons(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
      }
      nextToken
      scannedCount
    }
  }
`;
export const level = /* GraphQL */ `
  query Level($id: String!) {
    level(id: $id) {
      id
      createdAt
      updatedAt
      name
      number
      score
    }
  }
`;
export const levels = /* GraphQL */ `
  query Levels($filter: LevelsFilter, $scannedCount: Int, $nextToken: String) {
    levels(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      nextToken
      scannedCount
    }
  }
`;
export const asset = /* GraphQL */ `
  query Asset($id: String!) {
    asset(id: $id) {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
    }
  }
`;
export const membership = /* GraphQL */ `
  query Membership($id: String!) {
    membership(id: $id) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      sessionPerWeek
      isFullFilled
      groups {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      discountcode {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
      }
      studentaisessions {
        id
        createdAt
        updatedAt
        status
        currentQuestionIndex
      }
    }
  }
`;
export const memberships = /* GraphQL */ `
  query Memberships(
    $filter: MembershipsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    memberships(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      nextToken
      scannedCount
    }
  }
`;
export const parent = /* GraphQL */ `
  query Parent($id: String!) {
    parent(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      name
      gender
    }
  }
`;
export const parents = /* GraphQL */ `
  query Parents(
    $filter: ParentsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    parents(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        gender
      }
      nextToken
      scannedCount
    }
  }
`;
export const packageee = /* GraphQL */ `
  query Package($id: String!) {
    package(id: $id) {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      price {
        id
        createdAt
        updatedAt
        name
        price
        currency
        country
      }
      description
      benefits
      benefits_i18n
      promoAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      coverAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      enrollmentPackage {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      promocodes {
        id
        createdAt
        updatedAt
        codeName
        isApplied
        sessionPerWeek
      }
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
    }
  }
`;
export const packages = /* GraphQL */ `
  query Packages(
    $filter: PackagesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    packages(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      nextToken
      scannedCount
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: String!) {
    getPlan(id: $id)
  }
`;
export const price = /* GraphQL */ `
  query Price($id: String!) {
    price(id: $id) {
      id
      createdAt
      updatedAt
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      name
      price
      currency
      country
    }
  }
`;
export const prices = /* GraphQL */ `
  query Prices($filter: PricesFilter, $scannedCount: Int, $nextToken: String) {
    prices(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        price
        currency
        country
      }
      nextToken
      scannedCount
    }
  }
`;
export const program = /* GraphQL */ `
  query Program($id: String!) {
    program(id: $id) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      packages {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      courses {
        id
        createdAt
        updatedAt
        name
        plan
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      flag
      supportAi
      isActive
      instructors {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
    }
  }
`;
export const programs = /* GraphQL */ `
  query Programs($filter: ProgramsFilter, $limit: Int, $nextToken: String) {
    programs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      nextToken
      scannedCount
    }
  }
`;
export const question = /* GraphQL */ `
  query Question($id: String!) {
    question(id: $id) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      correctAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      videoAISessionUri
      choices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
    }
  }
`;
export const questions = /* GraphQL */ `
  query Questions(
    $filter: QuestionsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    questions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        name_i18n
        videoAISessionUri
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
        type
      }
      nextToken
      scannedCount
    }
  }
`;
export const school = /* GraphQL */ `
  query School($id: String!) {
    school(id: $id) {
      id
      createdAt
      updatedAt
      nameArabic
      name
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      teachers {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      isActive
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
    }
  }
`;
export const schools = /* GraphQL */ `
  query Schools(
    $filter: SchoolsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    schools(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      nextToken
      scannedCount
    }
  }
`;
export const session = /* GraphQL */ `
  query Session($id: String!) {
    session(id: $id) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const sessions = /* GraphQL */ `
  query Sessions(
    $filter: SessionsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    sessions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      nextToken
      scannedCount
    }
  }
`;
export const slot = /* GraphQL */ `
  query Slot($id: String!) {
    slot(id: $id) {
      id
      createdAt
      updatedAt
      name
      slotDuration
      availability {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      startTime
      endTime
      status
      limit
      current
      day
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
    }
  }
`;
export const slots = /* GraphQL */ `
  query Slots($filter: SlotsFilter, $scannedCount: Int, $nextToken: String) {
    slots(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      nextToken
      scannedCount
    }
  }
`;
export const student = /* GraphQL */ `
  query Student($id: String!) {
    student(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      notes
      facebookProfileUrl
      enrollments {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        endDate
        selfRating
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
        fullReportLastGenerationDate
        phoneticsAsString
        syllablesAsString
      }
      studentaudioprofile {
        id
        createdAt
        updatedAt
        introAIVSUri
        sentenceIntro
        correctAIVSUri
        sentenceCorrect
        failAIVSUri
        sentenceFail
        finalAIVSUri
        sentenceFinal
        studentName
      }
      status
      assignedTo
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
      }
      studentaisessions {
        id
        createdAt
        updatedAt
        status
        currentQuestionIndex
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      classschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
    }
  }
`;
export const students = /* GraphQL */ `
  query Students(
    $filter: StudentsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    students(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      nextToken
      scannedCount
    }
  }
`;
export const statistic = /* GraphQL */ `
  query Statistic($id: String!) {
    statistic(id: $id) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      scores
      averageScore
      fullReportLastGenerationDate
      monthlyReports {
        id
        createdAt
        updatedAt
        month
        average
        mistakes
        attempts
        d1
        d2
        d3
        d4
        d5
        d6
        d7
        d8
        d9
        d10
        d11
        d12
        d13
        d14
        d15
        d16
        d17
        d18
        d19
        d20
        d21
        d22
        d23
        d24
        d25
        d26
        d27
        d28
        d29
        d30
        d31
      }
      classschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      phoneticsAsString
      syllablesAsString
    }
  }
`;
export const statistics = /* GraphQL */ `
  query Statistics(
    $filter: StatisticsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    statistics(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        scores
        averageScore
        fullReportLastGenerationDate
        phoneticsAsString
        syllablesAsString
      }
      nextToken
      scannedCount
    }
  }
`;
export const monthlyReport = /* GraphQL */ `
  query MonthlyReport($id: String!) {
    monthlyReport(id: $id) {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
        fullReportLastGenerationDate
        phoneticsAsString
        syllablesAsString
      }
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
    }
  }
`;
export const monthlyReports = /* GraphQL */ `
  query MonthlyReports(
    $filter: MonthlyReportsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    monthlyReports(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        month
        average
        mistakes
        attempts
        d1
        d2
        d3
        d4
        d5
        d6
        d7
        d8
        d9
        d10
        d11
        d12
        d13
        d14
        d15
        d16
        d17
        d18
        d19
        d20
        d21
        d22
        d23
        d24
        d25
        d26
        d27
        d28
        d29
        d30
        d31
      }
      nextToken
      scannedCount
    }
  }
`;
export const test = /* GraphQL */ `
  query Test($id: String!) {
    test(id: $id) {
      id
      createdAt
      updatedAt
      questions {
        id
        createdAt
        updatedAt
        name
        name_i18n
        videoAISessionUri
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
        type
      }
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const tests = /* GraphQL */ `
  query Tests($filter: TestsFilter, $scannedCount: Int, $nextToken: String) {
    tests(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      nextToken
      scannedCount
    }
  }
`;
export const getUserByCognitoId = /* GraphQL */ `
  query GetUserByCognitoId($id: String!) {
    getUserByCognitoId(id: $id) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      admin {
        id
        createdAt
        updatedAt
        lastSeenOnline
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      teacher {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      preInstructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const user = /* GraphQL */ `
  query User($id: String!) {
    user(id: $id) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      admin {
        id
        createdAt
        updatedAt
        lastSeenOnline
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      teacher {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      preInstructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const users = /* GraphQL */ `
  query Users($filter: UsersFilter, $scannedCount: Int, $nextToken: String) {
    users(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      nextToken
      scannedCount
    }
  }
`;
export const me = /* GraphQL */ `
  query Me($cognitoUserId: String) {
    me(cognitoUserId: $cognitoUserId) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      admin {
        id
        createdAt
        updatedAt
        lastSeenOnline
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        paymentMethod
        rating
        isActive
        status
        birthDate
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
      }
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
        totalRatingConcentration
        totalRatingCamera
        totalRatingInternet
        totalRatingSessionQuality
        totalRating
        totalReviews
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      teacher {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      preInstructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
