import {CloseCircleFilled} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Popconfirm,
  Row,
  Spin,
  TimePicker,
  Typography,
} from 'antd';
import type {RangePickerProps} from 'antd/es/date-picker';

import Title from 'antd/es/typography/Title';
//const dotenv = require('dotenv');
import moment from 'moment-timezone';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Availability, Slot, Status, User} from '../../graphql/API';
import {isLoadingRequest} from '../../redux/actions/user';
import {
  changeSlotStatus,
  createAvailabilities,
  deleteAvailability,
} from '../../redux/actions/availability';
import {reducersState} from '../../redux/reducers';

const {RangePicker} = DatePicker;
const {Text} = Typography;
const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const MyAvaliablabilities = () => {
  const [instructorAvaliablities, setInstructorAvaliablities] = useState<
    Array<Availability> | []
  >([]);
  const history = useHistory();
  const location = useLocation();

  const availabilitiesList: Availability[] = useSelector(
    (state: reducersState) => state.avaliablity.avalaibilitiesList,
  );

  const currentUser: User = useSelector(
    (state: reducersState) => state.user.currentUser,
  );
  const isLoading: boolean = useSelector(
    (state: reducersState) => state.user.isLoadingRequest,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (availabilitiesList) {
      setInstructorAvaliablities(availabilitiesList);
    }
  }, [availabilitiesList]);

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // eslint-disable-next-line arrow-body-style
  // const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  //   // Can not select days before today and today
  //   return current && current < moment().endOf('day');
  // };

  const disabledDateTime = () => ({
    // disabledHours: () => range(0, 24).splice(0, 20),
    disabledMinutes: () => range(0, 60),
    disabledSeconds: () => range(0, 60),
  });

  return (
    <div style={{height: '100%', backgroundColor: 'white'}}>
      <Row>
        {daysOfTheWeek.map((day, index) => (
          <Col span={12} key={index}>
            <div style={{margin: 20, flexDirection: 'row'}}>
              <Text style={{display: 'block'}} strong={true}>
                {day}
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                }}>
                <TimePicker.RangePicker
                  // status="warning"
                  style={{marginInlineEnd: 30}}
                  format="HH"
                  // disabledDate={disabledDate}
                  disabledDateTime={disabledDateTime}
                  disabled={instructorAvaliablities.some(
                    (e: any) => e?.day === index && e?.id,
                  )}
                  value={
                    instructorAvaliablities.some((e: any) => e?.day === index)
                      ? [
                          moment
                            .tz(
                              instructorAvaliablities.find(
                                (e: any) => e?.day === index,
                              )?.startTime,
                              'HH:mm',
                              'UTC',
                            )
                            .clone()
                            .tz(currentUser.timeZone ?? 'Africa/Cairo'),
                          moment
                            .tz(
                              instructorAvaliablities.find(
                                (e: any) => e?.day === index,
                              )?.endTime,
                              'HH:mm',
                              'UTC',
                            )
                            .clone()
                            .tz(currentUser.timeZone ?? 'Africa/Cairo'),
                        ]
                      : null
                  }
                  onChange={(date) => {
                    console.log('VDATEEEE', date);
                    let _tempAvaliablities: Array<any> = [
                      ...instructorAvaliablities,
                    ];
                    const findIndex = instructorAvaliablities?.findIndex(
                      (e: any) => e.day === index,
                    );

                    if (date) {
                      let startTime = moment(date[0]).format('HH:mm:ss');
                      let endTime = moment(date[1]).format('HH:mm:ss');

                      let startDateTime: string = moment(
                        new Date().toDateString() + ' ' + startTime,
                      )
                        .day(index)
                        .format('YYYY-MM-DD HH:mm:ss');

                      let endDateTime: string = moment(
                        new Date().toDateString() + ' ' + endTime,
                      )
                        .day(index)
                        .format('YYYY-MM-DD HH:mm:ss');

                      var convertedStartTimeSlotToUTC = moment
                        .tz(
                          startDateTime,
                          'YYYY-MM-DD HH:mm:ss',
                          currentUser.timeZone ?? 'Africa/Cairo',
                        )
                        .clone()
                        .tz('UTC')
                        .utc(true);

                      var convertedEndTimeSlotToUTC = moment
                        .tz(
                          endDateTime,
                          'YYYY-MM-DD HH:mm:ss',
                          currentUser.timeZone ?? 'Africa/Cairo',
                        )
                        .clone()
                        .tz('UTC')
                        .utc(true);

                      console.log('startTimeUTC', convertedStartTimeSlotToUTC);
                      console.log('endTimeUTC', convertedStartTimeSlotToUTC);

                      if (findIndex === -1) {
                        console.log('NEWWW', findIndex);

                        setInstructorAvaliablities([
                          ...instructorAvaliablities,
                          {
                            __typename: 'Availability',
                            id: '',
                            updatedAt: '',
                            createdAt: '',
                            instructor: null,
                            startTime:
                              convertedStartTimeSlotToUTC.format('HH:mm:ss'),
                            endTime:
                              convertedEndTimeSlotToUTC.format('HH:mm:ss'),
                            day: index,
                          },
                        ]);
                      } else {
                        console.log('EDITTTTTT', findIndex);
                        _tempAvaliablities[findIndex] = {
                          day: index,
                          startTime:
                            convertedStartTimeSlotToUTC.format('HH:mm:ss'),
                          endTime: convertedEndTimeSlotToUTC.format('HH:mm:ss'),
                        };
                        setInstructorAvaliablities([..._tempAvaliablities]);
                      }
                    } else if (findIndex !== -1) {
                      _tempAvaliablities.splice(findIndex, 1);
                      setInstructorAvaliablities([..._tempAvaliablities]);
                    }
                  }}
                />

                {/* <RangePicker
                  style={{marginInlineEnd: 30}}
                  // mode="time"
                  showToday
                  showTime
                  format="HH"
                  onOk={() => {}}
                  value={
                    instructorAvaliablities.some((e: any) => e?.day === index)
                      ? [
                          moment(
                            new Date(
                              '2020-02-01' +
                                'T' +
                                instructorAvaliablities.find(
                                  (e: any) => e?.day === index,
                                )?.startTime,
                            ),
                          ),
                          moment(
                            new Date(
                              '2020-02-01' +
                                'T' +
                                instructorAvaliablities.find(
                                  (e: any) => e?.day === index,
                                )?.endTime,
                            ),
                          ),
                        ]
                      : null
                  }
                  disabledDate={(current) => {
                    var a = moment(); //now
                    var b = moment(current, 'YYYY-MM-DD HH:mm:ss');
                    return a.diff(b, 'days') !== 0;
                  }}
                  separator={'TO'}
                  // placeholder="Select Start Time"
                /> */}
                {instructorAvaliablities.some(
                  (e: any) => e?.day === index && e?.id,
                ) && (
                  <Popconfirm
                    title="Are you sure to delete this avaliabiltity?"
                    onConfirm={async () => {
                      const isAvaliablilityHasSlotBooked =
                        instructorAvaliablities.find(
                          (ava: Availability) =>
                            ava.day === index &&
                            ava.slots.find(
                              (slot: Slot) => slot?.status === Status.DONE,
                            ),
                        );

                      console.log(
                        'isAvaliablilityHasSlotBooked',
                        isAvaliablilityHasSlotBooked,
                      );
                      console.log('isAvaliablilityHasSlotBooked_index', index);
                      if (isAvaliablilityHasSlotBooked) {
                        console.log(
                          'isAvaliablilityHasSlotBooked',
                          isAvaliablilityHasSlotBooked,
                        );
                        dispatch(
                          isLoadingRequest(false, {
                            message:
                              'Can not delete this avalaibility, since it is already has group booked',
                          }),
                        );
                        return;
                      }
                      const findAvaliablity: Availability =
                        instructorAvaliablities?.find(
                          (e: any) => e.day === index,
                        );

                      if (findAvaliablity) {
                        await dispatch(deleteAvailability(findAvaliablity.id));

                        let _tempAvaliablities: Array<any> = [
                          ...instructorAvaliablities,
                        ];

                        const findIndex = instructorAvaliablities?.findIndex(
                          (e: any) => e.day === index,
                        );

                        _tempAvaliablities.splice(findIndex, 1);
                        setInstructorAvaliablities([..._tempAvaliablities]);
                        history.go(0);
                      }
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No">
                    <CloseCircleFilled onClick={async () => {}} />
                  </Popconfirm>
                )}
              </div>
              <Row>
                {availabilitiesList.map(
                  (aval: Availability) =>
                    aval.day === index && (
                      <div style={{marginTop: 10}}>
                        {aval.slots.map((slot: Slot) => (
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 10,
                            }}>
                            <div
                              onClick={() => {
                                // onChange('slotId', slot.id);
                              }}
                              style={{
                                paddingInline: '15px',
                                // width: '70%',
                                height: 50,
                                backgroundColor: '#40B2F2',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 9,
                              }}>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  color: '#fff',
                                }}>
                                {moment
                                  .tz(slot.startTime, 'HH:mm', 'UTC')
                                  .clone()
                                  .tz(currentUser.timeZone)
                                  .utc(true)
                                  .format('hh:mm A')}{' '}
                                -{' '}
                              </Text>

                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  color: '#fff',
                                }}>
                                {' '}
                                {moment
                                  .tz(slot.endTime, 'HH:mm', 'UTC')
                                  .clone()
                                  .tz(currentUser.timeZone)
                                  .utc(true)
                                  .format('hh:mm A')}
                              </Text>
                            </div>

                            <div>
                              {slot.status === Status.TODO && (
                                <Popconfirm
                                  title="Are you sure to disable this slot?"
                                  onConfirm={async () => {
                                    dispatch(
                                      changeSlotStatus(
                                        slot.id,
                                        Status.SUSPENDED,
                                        slot.updatedAt,
                                      ),
                                    );
                                  }}
                                  okText="Yes"
                                  cancelText="No">
                                  {/* <CloseCircleFilled
                                  onClick={async () => {}}
                                  style={{marginInline: 15}}
                                /> */}
                                  <a>
                                    <Text
                                      style={{
                                        marginInline: 15,
                                        backgroundColor: '#F2406A',
                                        padding: 8,
                                        borderRadius: 5,
                                        color: '#fff',
                                        fontWeight: 'bold',
                                      }}>
                                      I'm Not Avaliable
                                    </Text>
                                  </a>
                                </Popconfirm>
                              )}
                              {slot.status === Status.SUSPENDED && (
                                <Popconfirm
                                  title="Are you sure to enable this slot?"
                                  onConfirm={async () => {
                                    dispatch(
                                      changeSlotStatus(
                                        slot.id,
                                        Status.TODO,
                                        slot.updatedAt,
                                      ),
                                    );
                                  }}
                                  okText="Yes"
                                  cancelText="No">
                                  <a>
                                    <Text
                                      style={{
                                        marginInline: 15,
                                        backgroundColor: '#40B2F2',
                                        padding: 8,
                                        borderRadius: 5,
                                        color: '#fff',
                                        fontWeight: 'bold',
                                      }}>
                                      I'm Avaliable
                                    </Text>
                                  </a>
                                </Popconfirm>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ),
                )}
              </Row>
            </div>
          </Col>
        ))}
      </Row>
      <div
        style={{
          display: 'flex',
          flex: 0.3,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Spin spinning={isLoading}>
          <Button
            onClick={async () => {
              if (
                currentUser?.instructor &&
                instructorAvaliablities.length > 0
              ) {
                const found = instructorAvaliablities.find(
                  (ava: Availability) => !ava.createdAt,
                );

                if (found) {
                  await dispatch(
                    createAvailabilities(
                      instructorAvaliablities,
                      currentUser.instructor.id,
                      currentUser.timeZone,
                    ),
                  );
                  history.go(0);
                }
              }
            }}
            style={{
              width: '250px',
              height: '52px',
              borderRadius: 10,
              backgroundColor: '#40B2F2',
            }}>
            <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold'}}>
              Save
            </Text>
          </Button>
        </Spin>
      </div>
    </div>
  );
};
