import {Button, Col, Row, Spin, Typography} from 'antd';
import Title from 'antd/es/typography/Title';
import TextArea from 'antd/lib/input/TextArea';
//const dotenv = require('dotenv');
import moment from 'moment-timezone';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {
  FeedbackQuestionInsert,
  ReviewQuestion,
  ReviewType,
  Session,
  Status,
} from '../../graphql/API';
import {fetchSession, rateStudent} from '../../redux/actions/review';
import {reducersState} from '../../redux/reducers';
import {QuestionRate} from './QuestionRate';

const {Text} = Typography;
export const ReviewStudent = () => {
  const dispatch = useDispatch();
  let params: {sessionId: string} = useParams();
  console.log('params', params);
  const history = useHistory();
  const [questionRate, setQuestionRate] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const isLoading: boolean = useSelector(
    (state: reducersState) => state.user.isLoadingRequest,
  );
  const reviewQuestions = useSelector(
    (state: reducersState) => state.review.reviewQuestionsList,
  );

  const sessionToReview: Session | null = useSelector(
    (state: reducersState) => state.review.perviousSession,
  );
  const [ratingQuestions, setRatingQuestions] = useState<
    FeedbackQuestionInsert[]
  >([]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
    setMessage(e.target.value);
  };
  useEffect(() => {
    dispatch(fetchSession(params.sessionId));
  }, []);

  console.log('dsfs', sessionToReview);
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flex: 1,
        paddingInlineStart: '20px',
        flexDirection: 'column',
      }}>
      <Spin spinning={isLoading}>
        {sessionToReview && (
          <div>
            <Row>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 19,
                  }}>
                  <Text>Tell us your feedback about the student/s.</Text>
                  <Text style={{fontWeight: 'bold'}}>
                    {sessionToReview?.memberships[0]?.student?.name}
                  </Text>
                  {/* <Text>12 Years</Text>
                <Text>Egypt</Text> */}
                </div>
                {reviewQuestions.map(
                  (ques: ReviewQuestion) =>
                    ques.reviewType === ReviewType.INSTRUCTOR_TO_STUDENT && (
                      <QuestionRate
                        question={ques}
                        onRate={(id: string, stars: number) => {
                          let _tempRatingQuestion: FeedbackQuestionInsert[] = [
                            ...ratingQuestions,
                          ];

                          const questionIndex: number =
                            _tempRatingQuestion.findIndex(
                              (rtQuestion: FeedbackQuestionInsert) =>
                                rtQuestion.reviewquestionId === id,
                            );

                          if (questionIndex === -1) {
                            setRatingQuestions([
                              ...ratingQuestions,
                              {
                                reviewquestionId: id,
                                stars,
                              },
                            ]);
                          } else {
                            _tempRatingQuestion[questionIndex] = {
                              reviewquestionId: id,
                              stars,
                            };
                            setRatingQuestions([..._tempRatingQuestion]);
                          }
                        }}
                      />
                    ),
                )}
                <div
                  style={{
                    minWidth: '340px',
                    height: '130px',
                    backgroundColor: '#F7F7F7',
                    marginBottom: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 20,
                    flexDirection: 'column',
                  }}>
                  <Text>Leave message</Text>
                  <TextArea
                    value={message}
                    showCount
                    maxLength={250}
                    style={{
                      height: '77px',
                      width: '300px',
                      backgroundColor: '#F7F7F7',
                      border: 'none',
                    }}
                    onChange={onChange}
                  />
                </div>
              </Col>
            </Row>

            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}>
              <a>
                <div
                  onClick={async () => {
                    console.log(
                      'reviewQuestions.length === ratingQuestions.length',
                      reviewQuestions.length,
                      '=== ratingQuestions.length',
                      ratingQuestions.length,
                    );
                    const questionToReview = reviewQuestions.filter(
                      (question: ReviewQuestion) =>
                        question.reviewType ===
                        ReviewType.INSTRUCTOR_TO_STUDENT,
                    );

                    if (questionToReview.length === ratingQuestions.length) {
                      await dispatch(
                        rateStudent(
                          sessionToReview.memberships[0].student.id,
                          sessionToReview?.id,
                          message,
                          ratingQuestions,
                        ),
                      );
                      history.push('/home');
                    } else {
                      alert('Please Complete the Survey');
                    }
                  }}
                  style={{
                    width: '370px',
                    height: '64px',
                    backgroundColor: '#40B2F2',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    borderRadius: 20,
                    fontWeight: 'bold',
                  }}>
                  Send Review
                </div>
              </a>
            </Row>
          </div>
        )}
      </Spin>
    </div>
  );
};
