import {useEffect, useState, useRef, createRef} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import {get, isEqual} from 'lodash';

import type {RangePickerProps} from 'antd/es/date-picker';
import Title from 'antd/es/typography/Title';

import {
  DivisionType,
  Group,
  GroupUpdate,
  Instructor,
  Level,
  Membership,
  Session,
  Slot,
  Status,
  User,
} from '../../graphql/API';

import {GroupService} from './GroupService';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import Text from 'antd/lib/typography/Text';

import moment, {Moment} from 'moment-timezone';
import {
  CheckCircleFilled,
  CheckOutlined,
  CheckSquareFilled,
  CloseOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
  ScheduleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {isLoadingRequest} from '../../redux/actions/user';
import session from 'redux-persist/lib/storage/session';
import AgoraVideoCall from './AgoraVideoCall';
import {reducersState} from '../../redux/reducers';
import {getFileUrlByKey} from '../../utils/Storage';

const groupService = new GroupService();
const serverJistiUrl = 'https://live.pikado.io/';

export const GroupDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let params: {groupId: string} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [group, setGroup] = useState<Group>();
  const [isError, setIsError] = useState<boolean>(false);
  const [videoCall, setVideoCall] = useState(false);
  const userInfo: User = useSelector((state: any) => state.user.currentUser);
  console.log('userInfo->', userInfo);
  const parentEndRef = createRef<any>();

  const [selectedSession, setSelectedSession] = useState<Session>();
  const plainOptions = [
    'Any Time This Week',
    'Cancel the session and make it at the same time next week',
  ];
  const [value1, setValue1] = useState(
    'Cancel the session and make it at the same time next week',
  );

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalLevelVisible, setIsModalLevelVisible] =
    useState<boolean>(false);
  const [newLevelId, setNewLevelId] = useState<string>();
  const [levelList, setLevelList] = useState<Level[]>([]);
  const currentUser: User = useSelector(
    (state: reducersState) => state.user.currentUser,
  );
  const [selectedNewDateTimeSession, setSelectedNewDateTimeSession] = useState<
    string | null
  >(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      if (value1 === 'Any Time This Week') {
        if (
          selectedNewDateTimeSession &&
          selectedSession &&
          parseInt(
            //@ts-ignore
            (new Date(selectedSession?.startDateTime) -
              //@ts-ignore
              new Date()) /
              (1000 * 60 * 60 * 24),
            10,
          ) > -1
        ) {
          setIsModalVisible(false);
          setVideoCall(false);
          setIsLoading(true);

          if (selectedSession.status === Status.DOING) {
            await groupService.endSession(
              selectedSession.id,
              selectedSession.updatedAt,
              Status.TODO,
            );
          }
          await groupService.rescheduleSession(
            selectedSession.id,
            selectedNewDateTimeSession,
          );
          setIsLoading(false);
          alert('Session Rescheduled');
          history.push('/home');
        }
      } else {
        if (selectedSession) {
          setIsModalVisible(false);
          setIsLoading(true);
          setVideoCall(false);

          await groupService.endSession(
            selectedSession.id,
            selectedSession.updatedAt,
            Status.SUSPENDED,
          );
          setIsLoading(false);
          alert('Session Canceled');
          history.push('/home');
        }
      }
    } catch (err: any) {
      setVideoCall(false);
      dispatch(isLoadingRequest(false, err));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setValue1('Cancel the session and make it at the same time next week');
    setSelectedNewDateTimeSession(null);
  };

  const handleCancelLevel = () => {
    setIsModalLevelVisible(false);
  };

  useEffect(() => {
    groupService
      .getGroupById(params.groupId)
      .then((grop: any) => {
        setGroup(grop);
        console.log('setGroup', grop);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    groupService
      .getLevels()
      .then((lvls: any) => {
        setLevelList(lvls);
        console.log('setLevelList', lvls);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, [params.groupId]);

  const handleJitsiLink = (jitsiUrl) => {
    window.open(serverJistiUrl + jitsiUrl);
  };

  const handleOpenLink = (link) => {
    window.open(link);
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return (
      current &&
      (current.isBefore(moment().endOf('D').subtract(1, 'd')) ||
        current.isAfter(
          moment(selectedSession.startDateTime).endOf('D').add(6, 'days'),
        ))
    );
  };

  const disabledDateTime = () => ({
    // disabledHours: () => range(0, 24).splice(0, 20),
    disabledMinutes: () => range(0, 60),
    disabledSeconds: () => range(0, 60),
  });

  const handleOkLevel = async () => {
    setIsModalLevelVisible(false);
    setIsLoading(true);
    try {
      await groupService.levelUp(group.id, newLevelId);
      setIsLoading(false);
      window.location.reload();
    } catch (err: any) {
      dispatch(isLoadingRequest(false, err));
    }
  };

  function getStatusReschedule(
    session: Session,
    groupIsActive: boolean,
    perviousSession: Session,
  ): boolean {
    let isAllowedToReschedule: boolean = true;
    if (
      (session?.status === Status.DOING || session?.status === Status.TODO) &&
      groupIsActive &&
      new Date(new Date(session?.startDateTime).getTime() + 86400000) >=
        new Date() &&
      parseInt(
        //@ts-ignore
        (new Date(session?.startDateTime) -
          //@ts-ignore
          new Date()) /
          (1000 * 60 * 60 * 24),
        10,
      ) <= 6 &&
      parseInt(
        //@ts-ignore
        (new Date(session?.startDateTime) -
          //@ts-ignore
          new Date()) /
          (1000 * 60 * 60 * 24),
        10,
      ) > -2
    ) {
      if (perviousSession) {
        if (
          perviousSession.status === Status.DONE ||
          perviousSession.status === Status.SUSPENDED
        ) {
          return isAllowedToReschedule;
        }
      } else {
        return isAllowedToReschedule;
      }
    }

    return false;
  }
  useEffect(() => {
    if (group?.sessions.length > 0 && !isLoading) {
      const isDoingSession = group?.sessions.find(
        (sess: Session) => sess.status === Status.DOING,
      );

      let nowISO = moment.utc();

      if (isDoingSession) {
        const startedSession = isDoingSession;
        var now = moment
          .tz(nowISO.toISOString(), 'YYYY-MM-DD hh:mm:ss', 'UTC')
          .utc(true)
          .clone()
          .tz(userInfo?.timeZone ?? 'Africa/Cairo')
          .format('YYYY-MM-DD HH:mm:ss');

        const startDateTime = moment(startedSession?.startDateTime);

        const startTimeSession = moment
          .tz(
            startDateTime.format('YYYY-MM-DD') + startedSession?.startTime ??
              '',
            'YYYY-MM-DD HH:mm:ss',
            'UTC',
          )
          .clone()
          .tz(userInfo?.timeZone ?? 'Africa/Cairo')
          .subtract(5, 'm')
          .format('YYYY-MM-DD HH:mm:ss');

        const endTimeSession = moment
          .tz(
            startDateTime.format('YYYY-MM-DD') + startedSession?.endTime ?? '',
            'YYYY-MM-DD HH:mm:ss',
            'UTC',
          )
          .clone()
          .tz(userInfo?.timeZone ?? 'Africa/Cairo')
          .format('YYYY-MM-DD HH:mm:ss');

        console.log('NOWWW', now);

        console.log('startTimeSession', startTimeSession);

        console.log('endTimeSession', endTimeSession);
        var isNowBetweenSessionTime = moment(now).isBetween(
          startTimeSession,
          endTimeSession,
        );
        console.log('isNowBetweenSessionTime', isNowBetweenSessionTime);
        console.log('isDoingSession', isDoingSession);
        console.log('!isModalVisible', !isModalVisible);

        if (isNowBetweenSessionTime && !isModalVisible && isDoingSession) {
          console.log('SUSCCCESS_TRUE');
          setVideoCall(true);

          setSelectedSession(isDoingSession);
          setTimeout(() => {
            console.log('SCRRORLLLLLL');
            parentEndRef?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }, 1500);
        }
      }
    }
  }, [
    group?.sessions,
    parentEndRef,
    userInfo?.timeZone,
    isLoading,
    isModalVisible,
  ]);
  console.log('rerenderrr');

  const isNextSession = (session: Session): boolean => {
    if (session) {
      if (
        session?.status === Status.TODO &&
        group.isActive &&
        parseInt(
          //@ts-ignore
          (new Date(session.startDateTime) -
            //@ts-ignore
            new Date()) /
            (1000 * 60 * 60 * 24),
          10,
        ) === 0
      ) {
        let isNextSession: boolean = true;
        return isNextSession;
      }
    }
    return false;
  };

  const onChange1 = ({target: {value}}: RadioChangeEvent) => {
    console.log('radio1 checked', value);
    setValue1(value);
  };

  return (
    <div
      ref={parentEndRef}
      style={{backgroundColor: '#fff', minHeight: '100vh'}}>
      <div
        style={{
          padding: 10,
          backgroundColor: '#fff',
          height: '100%',
          paddingInlineStart: 20,
        }}>
        {!isLoading && (
          <>
            {group && (
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '5%',
                  marginInlineEnd: 20,
                }}>
                <Title
                  style={{
                    textAlign: 'center',
                    fontSize: 25,
                    fontWeight: 'bold',
                  }}>
                  Group: {group?.name}
                </Title>
                {group?.isGroupHasInstructor &&
                  group.sessions.find(
                    (session: Session) => session.status === Status.TODO,
                  ) && (
                    <a>
                      <Text
                        onClick={async () => {
                          try {
                            const updateGroup: GroupUpdate = {
                              name: group.name,
                              instructorId: group.instructor.id,
                              slotId: group.slot.id,
                              isGroupHasInstructor: false,
                              membershipIds: group?.memberships?.map(
                                (membership: Membership) => membership.id,
                              ),
                            };

                            setIsLoading(true);
                            await groupService.updateGroupHasInstructor(
                              group.id,
                              group.updatedAt,
                              updateGroup,
                            );

                            history.push('/home');
                          } catch (error: any) {
                            setIsLoading(false);
                            dispatch(isLoadingRequest(false, error));
                          }
                        }}
                        style={{
                          textDecorationLine: 'underline',
                          color: '#F2406A',
                          fontWeight: 'bold',
                        }}>
                        Can't Teach This Group
                      </Text>
                    </a>
                  )}
              </Row>
            )}
            {group && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '3%',
                }}>
                <div
                  style={{display: 'flex', flex: 0.5, flexDirection: 'column'}}>
                  <Row>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#A6A6A6',
                        paddingBottom: 10,
                      }}>
                      Language:{' '}
                    </Text>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#000',
                        paddingBottom: 10,
                      }}>
                      {JSON.parse(group?.program.name_i18n)['en']}
                    </Text>
                  </Row>
                  <Row>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#A6A6A6',
                        paddingBottom: 10,
                      }}>
                      Level:{' '}
                    </Text>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#000',
                        paddingBottom: 10,
                      }}>
                      {group.level.name}
                    </Text>
                    {group.division === DivisionType.ONE_TO_ONE &&
                      group.sessions.find(
                        (session: Session) => session.status === Status.TODO,
                      ) && (
                        <a>
                          <Text
                            onClick={() => {
                              setIsModalLevelVisible(true);
                            }}
                            style={{
                              fontWeight: 'bold',
                              color: '#FF5C63',
                              paddingBottom: 10,
                              marginInlineStart: 10,
                            }}>
                            Edit
                          </Text>
                        </a>
                      )}
                  </Row>
                  <Row>
                    <Text style={{fontWeight: 'bold', color: '#A6A6A6'}}>
                      Instructor:{' '}
                    </Text>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#000',
                        paddingBottom: 10,
                      }}>
                      {group.instructor.name}
                    </Text>
                  </Row>
                  {group.sessions.some((ses) => ses?.status === 'TODO') && (
                    <Row style={{alignItems: 'baseline'}}>
                      <Text
                        style={{
                          fontWeight: 'bold',
                          color: '#A6A6A6',
                          paddingBottom: 10,
                        }}>
                        Next Session: &nbsp;
                      </Text>
                      <Text
                        style={{
                          fontWeight: 'bold',
                          color: '#FF5C63',
                          paddingBottom: 10,
                        }}>
                        {moment
                          .tz(
                            group.sessions.find((ses) => ses?.status === 'TODO')
                              ?.startDateTime,
                            '',
                            'UTC',
                          )
                          .clone()
                          .tz(currentUser?.timeZone ?? 'Africa/Cairo')
                          .format('dddd')}
                        {'  '} - {'  '}
                        {'  ' +
                          moment
                            .tz(
                              group.sessions.find(
                                (ses) => ses?.status === 'TODO',
                              ).startDateTime,
                              'YYYY-MM-DD HH:mm:ss',
                              'UTC',
                            )
                            .clone()
                            .tz(currentUser?.timeZone ?? 'Africa/Cairo')
                            .format('yyyy-MM-DD')}
                      </Text>
                    </Row>
                  )}
                  <Row style={{alignItems: 'baseline'}}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#A6A6A6',
                        paddingBottom: 10,
                      }}>
                      Session Instructor Day :&nbsp;
                    </Text>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#000',
                        paddingBottom: 10,
                      }}>
                      {moment
                        .tz(group.slot.startTime, 'HH:mm:ss', 'UTC')
                        .day(group?.slot?.day)
                        .clone()
                        .tz(currentUser?.timeZone ?? 'Africa/Cairo')
                        .format('dddd')}
                    </Text>
                  </Row>
                  <Row style={{alignItems: 'baseline'}}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#A6A6A6',
                        paddingBottom: 10,
                      }}>
                      Session Time :&nbsp;
                    </Text>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        color: '#000',
                        paddingBottom: 10,
                      }}>
                      {moment
                        .tz(group.slot.startTime, 'HH:mm:ss', 'UTC')
                        .day(group?.slot?.day)
                        .clone()
                        .tz(currentUser?.timeZone ?? 'Africa/Cairo')
                        .format('hh:mm A')}
                    </Text>
                  </Row>

                  <div
                    style={{
                      width: '80%',
                      height: 1,
                      backgroundColor: '#ECECEC',
                      marginBlock: 22,
                    }}
                  />
                  <Text style={{color: '#999999', fontWeight: 'bold'}}>
                    Group Students
                  </Text>
                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          marginTop: 20,
                        }}>
                        {group?.memberships?.map((membership: Membership) => (
                          <div
                            style={{
                              paddingInline: 15,
                              width: '100%',
                              height: 50,
                              backgroundColor: '#ECECEC',
                              marginBottom: 10,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              alignContent: 'center',
                              display: 'flex',
                              borderRadius: 8,
                            }}>
                            <Text
                              style={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}>
                              {membership.student.name}
                            </Text>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div style={{display: 'flex', flex: 0.5}}>
                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          marginTop: 20,
                        }}>
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#999999',
                            marginBottom: 10,
                          }}>
                          Group Sessions
                        </Text>
                        {group?.sessions?.map(
                          (session: Session, index: number) => (
                            <Row
                              onClick={() => {}}
                              style={{
                                minWidth: '500px',
                                minHeight: '130px',
                                // backgroundColor: 'red',
                              }}>
                              <Col
                                style={{
                                  width: 360,
                                  backgroundColor: '#ECECEC',
                                  marginBottom: 10,
                                  borderRadius: 8,
                                  paddingBottom: 20,
                                  marginInlineEnd: 5,
                                }}>
                                <div
                                  style={{
                                    width: '100%',
                                    minHeight: '80px',
                                    backgroundColor:
                                      session?.status === Status.DONE
                                        ? '#C3E56E'
                                        : session?.status === Status.SUSPENDED
                                        ? '#FF5C63'
                                        : session?.status === Status.DOING
                                        ? '#FFD252'
                                        : '#C4C4C4',
                                    paddingInline: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    // alignItems: 'center',
                                  }}>
                                  <Row>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: 5,
                                      }}>
                                      {session?.name}
                                    </Text>
                                  </Row>
                                  <Row>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1,
                                        justifyContent: 'space-between',
                                      }}>
                                      {isNextSession(session) ? (
                                        <a>
                                          <Text
                                            style={{
                                              backgroundColor: '#7EC347',
                                              // width: '120px',
                                              // height: '30px',
                                              padding: 7,
                                              color: '#fff',
                                              justifyContent: 'center',
                                              fontWeight: 'bold',
                                              flex: 0.4,
                                              display: 'flex',
                                              borderRadius: 4,
                                            }}
                                            onClick={async () => {
                                              console.log('SDASD');
                                              setIsLoading(true);
                                              const respSession =
                                                await groupService.startSession(
                                                  session.id,
                                                );

                                              setIsLoading(false);
                                              const findStartedSessionIndex =
                                                group.sessions.findIndex(
                                                  (sess: Session) =>
                                                    sess.id ===
                                                    respSession.data
                                                      .startSession.id,
                                                );
                                              console.log(
                                                'respSession.data.startSession',
                                                respSession.data.startSession,
                                              );

                                              if (
                                                findStartedSessionIndex !== -1
                                              ) {
                                                console.log(
                                                  'respSession.data.startSession_1',
                                                  respSession.data.startSession,
                                                );
                                                let _tempSessions: Session[] = [
                                                  ...group.sessions,
                                                ];

                                                _tempSessions[
                                                  findStartedSessionIndex
                                                ] =
                                                  respSession.data.startSession;

                                                setGroup({
                                                  ...group,
                                                  sessions: [..._tempSessions],
                                                });

                                                setSelectedSession(
                                                  respSession.data.startSession,
                                                );
                                              }
                                              alert('Session Started');

                                              // history.go(0);
                                            }}>
                                            Start Session
                                          </Text>
                                        </a>
                                      ) : session?.status === Status.DOING &&
                                        group.isActive ? (
                                        <a>
                                          <Text
                                            style={{
                                              backgroundColor: '#42870C',
                                              // width: '120px',
                                              // height: '30px',
                                              padding: 7,
                                              color: '#fff',
                                              justifyContent: 'center',
                                              fontWeight: 'bold',
                                              flex: 0.4,
                                              display: 'flex',
                                              borderRadius: 4,
                                            }}
                                            onClick={async () => {
                                              console.log('SDASD');
                                              setIsLoading(true);
                                              setVideoCall(false);
                                              await groupService.endSession(
                                                session.id,
                                                session.updatedAt,
                                                Status.DONE,
                                              );
                                              setIsLoading(false);

                                              alert('Session Ended');
                                              // history.go(0);
                                              history.push(
                                                '/session-review-students/' +
                                                  session.id,
                                                {
                                                  session: session,
                                                },
                                              );
                                            }}>
                                            End Successfully
                                          </Text>
                                        </a>
                                      ) : (
                                        <div></div>
                                      )}

                                      {/* {session?.status !== Status.DONE &&
                                      group.sessions.find(
                                        (ses) => ses?.status === 'TODO',
                                      )?.id === session?.id &&
                                      group.isActive && (
                                        <ScheduleOutlined
                                          style={{
                                            marginInline: 10,
                                          }}
                                          onClick={async () => {
                                            console.log('SDASD');
                                            // setIsLoading(true);
                                            setIsModalVisible(true);
                                            setSelectedSessionId(session.id);
                                            // await groupService.rescheduleSession(
                                            //   session.id,
                                            //   session.updatedAt,
                                            //   Status.SUSPENDED,
                                            // );

                                            // setIsLoading(false);

                                            // alert('Session Time Updated');
                                            // window.location.reload();
                                          }}
                                        />
                                      )} */}

                                      {getStatusReschedule(
                                        session,
                                        group.isActive,
                                        index > 0
                                          ? group?.sessions[index - 1]
                                          : null,
                                      ) && (
                                        <a>
                                          <Text
                                            style={{
                                              backgroundColor: 'transparent',
                                              flex: 0.4,
                                              display: 'flex',
                                            }}
                                            onClick={async () => {
                                              console.log('SDASD');
                                              // setIsLoading(true);
                                              setIsModalVisible(true);
                                              setSelectedSession(session);
                                              // await groupService.rescheduleSession(
                                              //   session.id,
                                              //   session.updatedAt,
                                              //   Status.SUSPENDED,
                                              // );

                                              // setIsLoading(false);

                                              // alert('Session Time Updated');
                                              // window.location.reload();
                                            }}

                                            // onClick={async () => {
                                            //   console.log('SDASD');
                                            //   // setIsLoading(true);
                                            //   // await groupService.endSession(
                                            //   //   session.id,
                                            //   //   session.updatedAt,
                                            //   //   Status.SUSPENDED,
                                            //   // );
                                            //   // setIsLoading(false);
                                            //   // alert('Session Failed');
                                            //   // history.go(0);
                                            // }}
                                          >
                                            Reschedule Session
                                          </Text>
                                        </a>
                                      )}
                                    </div>
                                  </Row>
                                </div>
                                <div style={{padding: 10}}>
                                  <Text
                                    onClick={() => {}}
                                    style={{
                                      color: '#A6A6A6',
                                      fontWeight: 'bold',
                                    }}>
                                    Lesson:
                                  </Text>

                                  <Text
                                    onClick={() => {}}
                                    style={{
                                      color: '#000',
                                      fontWeight: 'bold',
                                    }}>
                                    {' '}
                                    {session?.lesson?.name}
                                  </Text>
                                </div>
                                <div style={{paddingInline: 10}}>
                                  <Text
                                    onClick={() => {}}
                                    style={{
                                      color: '#A6A6A6',
                                      fontWeight: 'bold',
                                    }}>
                                    Instructor:
                                  </Text>

                                  <Text
                                    onClick={() => {}}
                                    style={{
                                      color: '#000',
                                      fontWeight: 'bold',
                                    }}>
                                    {' '}
                                    {session?.instructor?.name}
                                  </Text>
                                </div>
                                {session?.status === 'DOING' &&
                                group?.isActive ? (
                                  <div
                                    style={{
                                      paddingInline: 10,
                                      marginTop: 10,
                                      textAlign: 'center',
                                    }}>
                                    {/* <Text
                                      style={{
                                        color: '#A6A6A6',
                                        fontWeight: 'bold',
                                      }}>
                                      :
                                    </Text> */}

                                    <Button
                                      onClick={() => {
                                        parentEndRef?.current?.scrollIntoView({
                                          behavior: 'smooth',
                                          block: 'end',
                                          inline: 'nearest',
                                        });
                                      }}
                                      style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        // padding: 0,
                                        margin: 'none',
                                        display: 'flex',
                                        height: '100%',
                                        whiteSpace: 'normal',
                                      }}>
                                      Go To The Session
                                    </Button>
                                  </div>
                                ) : null}
                              </Col>

                              <Col>
                                {group.isActive && (
                                  <Col>
                                    <Text
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#999999',
                                        fontSize: 14,
                                      }}>
                                      {moment
                                        .tz(
                                          session?.startDateTime,
                                          'YYYY-MM-DD HH:mm:ss',
                                          'UTC',
                                        )
                                        .clone()
                                        .tz(
                                          currentUser?.timeZone ??
                                            'Africa/Cairo',
                                        )
                                        .format('yyyy-MM-DD')}{' '}
                                    </Text>
                                  </Col>
                                )}
                                {group.isActive && (
                                  <Col>
                                    <Text
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#999999',
                                        fontSize: 14,
                                      }}>
                                      {moment
                                        .tz(
                                          session?.startDateTime,
                                          'YYYY-MM-DD HH:mm:ss',
                                          'UTC',
                                        )
                                        .clone()
                                        .tz(
                                          currentUser?.timeZone ??
                                            'Africa/Cairo',
                                        )
                                        .format('hh:mm:ss A')}{' '}
                                    </Text>
                                  </Col>
                                )}
                                {group.isActive && (
                                  <a>
                                    <Row
                                      onClick={async () => {
                                        if (
                                          session?.lesson?.lessonPDF?.fileUri
                                        ) {
                                          const pathContent =
                                            session?.lesson?.lessonPDF?.fileUri?.split(
                                              '/',
                                            );
                                          const bucketName = pathContent
                                            ? pathContent[3]
                                            : '';

                                          console.log(
                                            'bucketNameMina',
                                            bucketName,
                                          );
                                          console.log(
                                            'proPicPath',
                                            pathContent,
                                          );

                                          const pathVideo =
                                            session?.lesson?.lessonPDF?.fileUri?.split(
                                              '.pikado.io/',
                                            );
                                          const videoProfileUrl =
                                            await getFileUrlByKey(
                                              pathVideo[1].replaceAll('+', ' '),
                                              bucketName,
                                            );
                                          console.log(
                                            'videoProfileUrl.url.toJSON()',
                                            videoProfileUrl.url.toJSON(),
                                          );
                                          history.push('/view', {
                                            pdfUrl:
                                              videoProfileUrl.url.toJSON(),
                                          });
                                        }
                                      }}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '8px',
                                      }}>
                                      <PaperClipOutlined
                                        style={{marginInlineEnd: 5}}
                                      />
                                      <Text
                                        style={{
                                          color: '#F2406A',
                                          fontWeight: 'bold',
                                          fontSize: '12px',
                                          textDecorationLine: 'underline',
                                        }}>
                                        Presentation
                                      </Text>
                                    </Row>
                                  </a>
                                )}
                                {group.isActive && (
                                  <a>
                                    <Row
                                      onClick={async () => {
                                        if (
                                          session?.lesson?.instructionsPDF
                                            ?.fileUri
                                        ) {
                                          const pathContent =
                                            session?.lesson?.instructionsPDF?.fileUri?.split(
                                              '/',
                                            );
                                          const bucketName = pathContent
                                            ? pathContent[3]
                                            : '';

                                          console.log(
                                            'bucketNameMina',
                                            bucketName,
                                          );
                                          console.log(
                                            'proPicPath',
                                            pathContent,
                                          );

                                          const pathVideo =
                                            session?.lesson?.instructionsPDF?.fileUri?.split(
                                              '.pikado.io/',
                                            );
                                          const videoProfileUrl =
                                            await getFileUrlByKey(
                                              pathVideo[1].replaceAll('+', ' '),
                                              bucketName,
                                            );

                                          history.push('/view', {
                                            pdfUrl:
                                              videoProfileUrl.url.toJSON(),
                                          });
                                        }
                                      }}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '8px',
                                      }}>
                                      <PaperClipOutlined
                                        style={{marginInlineEnd: 5}}
                                      />
                                      <Text
                                        style={{
                                          color: '#F2406A',
                                          fontWeight: 'bold',
                                          fontSize: '12px',
                                          textDecorationLine: 'underline',
                                        }}>
                                        Instructions
                                      </Text>
                                    </Row>
                                  </a>
                                )}
                                {group.isActive && (
                                  <a>
                                    <Row
                                      onClick={async () => {
                                        if (session?.lesson?.summary?.fileUri) {
                                          const pathContent =
                                            session?.lesson?.summary?.fileUri?.split(
                                              '/',
                                            );
                                          const bucketName = pathContent
                                            ? pathContent[3]
                                            : '';

                                          console.log(
                                            'bucketNameMina',
                                            bucketName,
                                          );
                                          console.log(
                                            'proPicPath',
                                            pathContent,
                                          );

                                          const pathVideo =
                                            session?.lesson?.summary?.fileUri?.split(
                                              '.pikado.io/',
                                            );
                                          const videoProfileUrl =
                                            await getFileUrlByKey(
                                              pathVideo[1].replaceAll('+', ' '),
                                              bucketName,
                                            );
                                          history.push('/view', {
                                            videoUrl:
                                              videoProfileUrl.url.toJSON(),
                                          });
                                        }
                                      }}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '8px',
                                      }}>
                                      <PaperClipOutlined
                                        style={{marginInlineEnd: 5}}
                                      />
                                      <Text
                                        style={{
                                          color: '#F2406A',
                                          fontSize: '12px',

                                          fontWeight: 'bold',
                                          textDecorationLine: 'underline',
                                        }}>
                                        Video
                                      </Text>
                                    </Row>
                                  </a>
                                )}
                              </Col>
                            </Row>
                          ),
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </>
        )}
        {isLoading && (
          <Spin
            size="large"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
            }}
          />
        )}

        {isError && <Title>Somthing went wrong ⚠️, refresh the page</Title>}

        <Modal
          title="Change Session Time/Date"
          visible={isModalVisible}
          okText="Save"
          onOk={handleOk}
          destroyOnClose
          onCancel={handleCancel}>
          <div>
            <Row>
              <Text
                style={{fontSize: 13, fontWeight: 'bold', marginBottom: 30}}>
                Please note you are selecting time on 24 hour time system and
                Your Time Zone
              </Text>
            </Row>
            <Row
              style={{
                alignItems: 'center',
                display: 'flex',
              }}>
              <Row>
                <Col>
                  <Radio.Group onChange={onChange1} value={value1}>
                    <Radio value={plainOptions[1]}>{plainOptions[1]}</Radio>
                    {parseInt(
                      //@ts-ignore
                      (new Date(selectedSession?.startDateTime) -
                        //@ts-ignore
                        new Date()) /
                        (1000 * 60 * 60 * 24),
                      10,
                    ) > -1 && (
                      <Row>
                        <Radio value={plainOptions[0]}>{plainOptions[0]}</Radio>
                        {value1 === 'Any Time This Week' && (
                          <DatePicker
                            onChange={(value: Moment) => {
                              setSelectedNewDateTimeSession(
                                moment(value.format()).utc(true).format(),
                              );
                            }}
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={disabledDate}
                            disabledTime={disabledDateTime}
                            showTime={{
                              defaultValue: moment('00:00:00', 'HH:mm:ss'),
                            }}
                          />
                        )}
                      </Row>
                    )}
                  </Radio.Group>
                </Col>
              </Row>
            </Row>
          </div>
        </Modal>
        <Modal
          title="Change Group Level"
          visible={isModalLevelVisible}
          okText="Change"
          onOk={handleOkLevel}
          onCancel={handleCancelLevel}>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text style={{display: 'block'}}>Select New Level</Text>
            <Select
              value={newLevelId}
              showSearch
              style={{width: 200}}
              placeholder="select level"
              optionFilterProp="children"
              onChange={(levelId: string) => {
                setNewLevelId(levelId);
              }}>
              {levelList.map((lvl: Level) => (
                <Select.Option value={lvl.id}>{lvl.name}</Select.Option>
              ))}
            </Select>
          </div>
        </Modal>
      </div>
      {videoCall && (
        <div
          style={{
            width: '100%',
            height: '100%',
            // display: 'flex',
            justifyContent: 'center',
            // backgroundColor: 'red',
          }}>
          {videoCall &&
            selectedSession?.rtcInstructorToken &&
            selectedSession?.rtcShareScreenToken && (
              <AgoraVideoCall session={selectedSession} />
            )}
        </div>
      )}
    </div>
  );
};

export default GroupDetails;
