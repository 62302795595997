import {Button, Col, Row, Typography} from 'antd';
import Title from 'antd/es/typography/Title';
//const dotenv = require('dotenv');
import moment from 'moment-timezone';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {ReviewQuestion} from '../../graphql/API';
import {reducersState} from '../../redux/reducers';

type questionRateType = {
  question: ReviewQuestion;
  onRate: (id: string, questionRate: number) => void;
};

const {Text} = Typography;
export const QuestionRate = ({question, onRate}: questionRateType) => {
  const [questionRate, setQuestionRate] = useState<number>(0);

  return (
    <Row
      style={{
        backgroundColor: '#F7F7F7',
        height: '88px',
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        minWidth: '340px',
      }}>
      <div>
        <Text>{JSON.parse(question.question_i18n)['en']}</Text>
        <Row style={{marginTop: 10}}>
          <Text>BAD</Text>
          {[...new Array(5)].map((_, index) => (
            <div
              key={index}
              style={{
                width: 19,
                height: 19,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: '#E1E1E1',
                padding: 3,
                backgroundColor: '#ECECEC',
                justifyContent: 'center',
                alignItems: 'center',
                marginInlineStart: 20,
                display: 'flex',
              }}
              onClick={() => {
                setQuestionRate(index + 1);
                onRate(question.id, index + 1);
              }}>
              {index < questionRate && questionRate !== 0 && (
                <div
                  style={{
                    width: 13,
                    height: 13,
                    borderRadius: 10,
                    backgroundColor: '#F1546B',
                  }}
                />
              )}
            </div>
          ))}
          <Text style={{marginInlineStart: 10}}>GOOD</Text>
        </Row>
      </div>
    </Row>
  );
};
